import fetch from "data/fetch";

export async function mutateCongregation({
  congregationId,
  name,
  country,
  address,
  regionalId,
  pastorId,
  latitude,
  longitude,
}) {
  const url = congregationId
    ? `/v1/congregacao/${congregationId}`
    : "/v1/congregacao";
  const method = congregationId ? "PUT" : "POST";

  const body = JSON.stringify({
    name,
    country,
    address,
    regionalId,
    pastorId,
    latitude,
    longitude,
  });

  try {
    await fetch(url, {
      method,
      headers: {
        "Content-Type": "application/json",
      },
      body,
    });
    return true;
  } catch {
    return false;
  }
}
