import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useActionData } from "react-router-dom";
import { Container, Card, Stack, Box, Button, Divider } from "@mui/material";
import ErrorAlert from "shared/components/ErrorState/ErrorAlert.react";
import PersonAvatar from "shared/components/Avatar/Avatar.react";
import moment from "moment";
import DynamicField from "./DynamicField.react";
import { formatCurrency } from "utils/NumberUtils";

export default function EventRegistrationForm({
  data,
  error,
  onSubmit,
  onDelete,
  onPay,
  breadcrumbs,
  showInvisibleInputs = false,
}) {
  const { t } = useTranslation();
  const actionData = useActionData();

  const fields = data?.values?.reduce((acc, item) => {
    acc[item.name] = item.value;
    return acc;
  }, {});

  const { control, handleSubmit } = useForm({
    defaultValues: {
      ...fields,
    },
  });

  const onFormSubmit = async (formData) => {
    try {
      await onSubmit?.(formData);
    } catch (err) {
      console.error(err);
    }
  };

  const onFormDelete = async () => {
    try {
      await onDelete?.();
    } catch (err) {
      console.error(err);
    }
  };

  const onFormPay = async () => {
    try {
      await onPay?.();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Container component="form" onSubmit={handleSubmit(onFormSubmit)}>
      {breadcrumbs}
      <Stack spacing={3} sx={{ p: 3 }}>
        <Card sx={{ p: 3 }}>
          {/* Photo */}
          <Box display="flex" justifyContent="center" mb={3}>
            <PersonAvatar
              name={data?.pessoa?.name}
              sx={{ width: 100, height: 100 }}
              src={
                data?.pessoa?.photoId
                  ? `/api/v1/file/${data?.pessoa?.photoId}?width=100&height=100`
                  : undefined
              }
            />
          </Box>

          {/* Regional */}
          <Box mb={2}>
            <strong>{t("Regional")}:</strong>{" "}
            {data?.pessoa?.congregacao?.regional?.name || t("Not provided")}
          </Box>

          {/* Congregation */}
          <Box mb={2}>
            <strong>{t("Congregation")}:</strong>{" "}
            {data?.pessoa?.congregacao?.name || t("Not provided")}
          </Box>

          {/* Name */}
          <Box mb={2}>
            <strong>{t("Name")}:</strong>{" "}
            {data?.pessoa?.name || t("Not provided")}
          </Box>

          {/* Email */}
          <Box mb={2}>
            <strong>{t("Email")}:</strong>{" "}
            {data?.pessoa?.email || t("Not provided")}
          </Box>

          {/* Phone */}
          <Box mb={2}>
            <strong>{t("Phone")}:</strong>{" "}
            {data?.pessoa?.phone || t("Not provided")}
          </Box>

          {/* Birth Date */}
          <Box mb={2}>
            <strong>{t("Birth Date")}:</strong>{" "}
            {data?.pessoa?.birthDate
              ? `${moment(data.pessoa.birthDate).format("LL")} (${moment().diff(data.pessoa.birthDate, "years")} ${t("year(s)")})`
              : t("Not provided")}
          </Box>

          {/* Amount paid */}
          {(Number(data?.event?.ticketPrice) || 0) > 0 && (
            <Box
              mb={2}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <strong>{t("Amount paid")}:</strong>
              <Box
                sx={{
                  fontWeight: "bold",
                  color:
                    (Number(data?.totalPayments) || 0) >=
                    (Number(data?.event?.ticketPrice) || 0)
                      ? "green"
                      : "red",
                }}
              >
                {formatCurrency(data?.totalPayments)} /{" "}
                {formatCurrency(data?.event?.ticketPrice)}
              </Box>
            </Box>
          )}

          <Divider variant="middle" />

          {/* Fields */}
          {data?.event?.fields &&
            data?.event?.fields.map((v) => (
              <Box key={v.name} mb={2}>
                <DynamicField
                  field={v}
                  control={control}
                  showInvisible={showInvisibleInputs}
                />
              </Box>
            ))}

          <ErrorAlert error={error} />

          <ErrorAlert error={actionData?.error} />

          {/* Submit and Delete Buttons */}
          <Box display="flex" justifyContent="flex-end" gap={2} mt={3}>
            {onDelete && (
              <Button
                variant="contained"
                color="error"
                size="large"
                onClick={onFormDelete}
              >
                {t("Delete")}
              </Button>
            )}

            {onPay && data?.event?.paymentEnabled && (
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={onFormPay}
              >
                {t("Pay")}
              </Button>
            )}

            <Button
              variant="contained"
              color="success"
              size="large"
              type="submit"
            >
              {t("Submit")}
            </Button>
          </Box>
        </Card>
      </Stack>
    </Container>
  );
}
