import queryClient from "data/queryClient";
import { useQuery, useMutation } from "@tanstack/react-query";
import fetch from "data/fetch";

// Fetch system roles
function fetchSystemRoles() {
  return fetch("/v1/system-role");
}

const getSystemRolesKey = () => ["system-roles"];

export function useSystemRolesQuery(options = {}) {
  return useQuery({
    queryKey: getSystemRolesKey(),
    queryFn: fetchSystemRoles,
    ...options,
  });
}

export function ensureSystemRolesData() {
  return queryClient.ensureQueryData({
    queryKey: getSystemRolesKey(),
    queryFn: fetchSystemRoles,
  });
}

// Fetch permissions for a specific role
function fetchRolePermissions(roleName) {
  return fetch(`/v1/system-role/${roleName}/permission`);
}

const getRolePermissionsKey = (roleName) => ["role-permissions", roleName];

export function useRolePermissionsQuery(roleName, options = {}) {
  return useQuery({
    queryKey: getRolePermissionsKey(roleName),
    queryFn: () => fetchRolePermissions(roleName),
    ...options,
    enabled: !!roleName, // only fetch if roleName is provided
  });
}

export function ensureRolePermissionsData(roleName) {
  return queryClient.ensureQueryData({
    queryKey: getRolePermissionsKey(roleName),
    queryFn: () => fetchRolePermissions(roleName),
  });
}

// Update permissions for a specific role
function updateRolePermissions({ roleName, permissions }) {
  return fetch(`/v1/system-role/${roleName}/permission`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ permissions }),
  });
}

export function useUpdateRolePermissionsMutation(options = {}) {
  return useMutation(updateRolePermissions, options);
}

export function invalidateRolePermissionsQuery(roleName) {
  return queryClient.invalidateQueries(["role-permissions", roleName]);
}
