import GuestSignupForm from "./components/GuestSignupForm.react";
import { useNavigate } from "react-router-dom";
import { invalidateLoggedUserQuery } from "data/queries/queryLoggedUser";

export default function GuestEditPage({ data }) {
  const navigate = useNavigate();
  return (
    <GuestSignupForm
      data={data}
      isPublic={false}
      onSuccess={async () => {
        await invalidateLoggedUserQuery();
        // TODO: migrate guest complete form to route
        const urlParams = new URLSearchParams(window.location.search);
        const returnTo = urlParams.get("returnTo");
        if (returnTo) {
          navigate(returnTo);
        } else {
          navigate("/");
        }

        window.location.reload();
      }}
    />
  );
}
