import { ensureCongregationQueryData } from "data/queries/queryCongregations";
import { invalidateFamiliaCristaQuery } from "data/queries/queryFamiliaCristaAll";
import { useLoaderData } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Breadcrumbs from "shared/components/Breadcrumbs/Breadcrumbs.react";
import FamiliaCristaForm from "./components/FamiliaCristaForm.react";
import fetch from "data/fetch";

export async function loader({ params: { id } }) {
  await ensureCongregationQueryData();

  // TODO: find a better way to express this within a single API request because
  // We need leader, congregation, members, and relations in order to execute
  // UPDATES.
  const { data: familiaCrista } = await fetch(`/v1/familia-crista/${id}`);
  const { data: members } = await fetch(`/v1/familia-crista/${id}/membros`);
  const { data: allRelations } = await fetch(`/v1/familia-crista-relation`);

  // TODO: this is hacky... loading all relations and finding things on the
  // client-side
  const relationsForThisFamiliaCrista = allRelations.filter(
    (x) => x.familiaCrista.id === id,
  );

  const findMemberRelation = (memberId) =>
    relationsForThisFamiliaCrista.find((x) => x.pessoa.id === memberId);

  return {
    familiaCristaId: id,
    congregationId: familiaCrista.congregacao.id,
    congregationName: familiaCrista.congregacao.name,
    departamento: familiaCrista.departamento,
    leaderId: familiaCrista.lider.id,
    leaderName: familiaCrista.lider.name,
    relations: members.map((m, i) => ({
      relationId: findMemberRelation(m.id).id,
      memberId: m.id,
      memberName: m.name,
    })),
  };
}

export async function action() {
  // TODO move form submit to action
}

export default function FamiliaCristaEditPage() {
  const data = useLoaderData();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [error, setError] = useState(null);

  // TODO move to mutations
  const onSubmit = async ({ selectedMembers }) => {
    const promises = [];
    const familiaCristaId = data.familiaCristaId;

    setError(null);

    for (const member of selectedMembers) {
      // deleted an existing relation
      if (member.deleted && member.relationId) {
        promises.push(
          fetch(`/v1/familia-crista-relation/${member.relationId}`, {
            method: "DELETE",
          }),
        );

        // or added a relation that doesn't currently exist
      } else if (member.relationId == null) {
        promises.push(
          fetch(`/v1/familia-crista-relation`, {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              familiaCristaId,
              pessoaId: member.memberId,
            }),
          }),
        );
      }
    }

    if (promises.length) {
      try {
        await Promise.all(promises);
      } catch (e) {
        setError(e);
        return;
      }
    }

    invalidateFamiliaCristaQuery();
    navigate("/familia-crista/list");
  };

  const onDelete = async ({ selectedMembers }) => {
    const familiaCristaId = data.familiaCristaId;

    setError(null);

    try {
      // TODO: deleting caring group should take care of this
      // delete all relations
      await Promise.all(
        selectedMembers.map((member) =>
          fetch(`/v1/familia-crista-relation/${member.relationId}`, {
            method: "DELETE",
          }),
        ),
      );

      // delete caring group
      await fetch(`/v1/familia-crista/${familiaCristaId}`, {
        method: "DELETE",
      });
    } catch (e) {
      setError(e);
      return;
    }

    invalidateFamiliaCristaQuery();
    navigate("/familia-crista/list");
  };

  return (
    <FamiliaCristaForm
      data={data}
      onSubmit={onSubmit}
      onDelete={onDelete}
      error={error}
      breadcrumbs={
        <Breadcrumbs
          heading={t("Edit Christian Family")}
          links={[
            {
              name: t("Christian Family"),
              to: "/familia-crista/list",
            },
            { name: t("Edit") },
          ]}
          sx={{ mb: { xs: 3, md: 5 } }}
        />
      }
    />
  );
}
