import queryClient from "data/queryClient";
import { useQuery } from "@tanstack/react-query";
import fetch from "data/fetch";

// Fetch Donations with pagination and filters
function fetchDonations(page = 0, filter = "", sort = "-data") {
  return fetch(
    `/v1/doacoes?size=100&index=${page}&q=${encodeURIComponent(filter)}&s=${sort}`,
  );
}

// Generate Query Key for Donations
const getQueryKey = (page = 0, filter = "", sort = "-data") => [
  "donations",
  page,
  filter,
  sort,
];

// Custom Hook for Donations Query with Filter Support
export function useDonationsQuery({
  page = 0,
  filter = "",
  sort = "-data",
  ...other
}) {
  return useQuery({
    ...other,
    queryKey: getQueryKey(page, filter, sort),
    queryFn: () => fetchDonations(page, filter, sort),
  });
}

// Ensure Query Data for Donations
export function ensureDonationsQueryData({
  page = 0,
  filter = "",
  sort = "-data",
} = {}) {
  return queryClient.ensureQueryData({
    queryKey: getQueryKey(page, filter, sort),
    queryFn: () => fetchDonations(page, filter, sort),
  });
}

// Invalidate Donations Query
export function invalidateDonationsQuery() {
  return queryClient.invalidateQueries(["donations"]);
}
