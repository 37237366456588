import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useQuery } from "@tanstack/react-query";
import fetch from "data/fetch";

const fetchRegionals = async () => {
  const response = await fetch("/v1/regional");
  return response.data;
};

export default function RegionalSelect({
  required,
  label: labelProp,
  ...props
}) {
  const { t } = useTranslation();
  const { data: regionals = [] } = useQuery(["regionals"], fetchRegionals);

  const data = useMemo(
    () =>
      regionals
        .map((v) => [v.id, v.name])
        .sort((tupleA, tupleB) => tupleA[1].localeCompare(tupleB[1])),
    [regionals],
  );

  const label = (labelProp ?? t("Regional")) + (required ? " *" : "");

  return (
    <FormControl>
      <InputLabel>{label}</InputLabel>
      <Select {...props} label={label} required={required}>
        {data.map(([id, label]) => (
          <MenuItem key={id} value={id}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
