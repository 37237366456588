import { ensureCongregationQueryData } from "data/queries/queryCongregations";
import { invalidateCaringGroupsQuery } from "data/queries/queryCaringGroups";
import { useLoaderData } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Breadcrumbs from "shared/components/Breadcrumbs/Breadcrumbs.react";
import CaringGroupsForm from "./components/CaringGroupsForm.react";
import fetch from "data/fetch";

export async function loader({ params: { id } }) {
  await ensureCongregationQueryData();

  // TODO: find a better way to express this within a single API request because
  // We need leader, congregation, members, and relations in order to execute
  // UPDATES.
  const { data: caringGroup } = await fetch(`/v1/caring-groups/${id}`);
  const { data: members } = await fetch(`/v1/caring-groups/${id}/membros`);
  const { data: allRelations } = await fetch(`/v1/caring-groups-relation`);

  // TODO: this is hacky... loading all relations and finding things on the
  // client-side
  const relationsForThisCaringGroup = allRelations.filter(
    (x) => x.caringGroup.id === id,
  );

  const findMemberRelation = (memberId) =>
    relationsForThisCaringGroup.find((x) => x.pessoa.id === memberId);

  return {
    caringGroupId: id,
    congregationId: caringGroup.congregacao.id,
    congregationName: caringGroup.congregacao.name,
    departamento: caringGroup.departamento,
    leaderId: caringGroup.lider.id,
    leaderName: caringGroup.lider.name,
    relations: members.map((m, i) => ({
      relationId: findMemberRelation(m.id).id,
      memberId: m.id,
      memberName: m.name,
    })),
  };
}

export async function action() {
  // TODO move form submit to action
}

export default function CaringGroupsEditPage() {
  const data = useLoaderData();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [error, setError] = useState(null);

  // TODO move to mutations
  const onSubmit = async ({ selectedMembers }) => {
    const promises = [];
    const caringGroupId = data.caringGroupId;

    for (const member of selectedMembers) {
      // deleted an existing relation
      if (member.deleted && member.relationId) {
        promises.push(
          fetch(`/v1/caring-groups-relation/${member.relationId}`, {
            method: "DELETE",
          }),
        );

        // or added a relation that doesn't currently exist
      } else if (member.relationId == null) {
        promises.push(
          fetch(`/v1/caring-groups-relation`, {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              caringGroupId,
              pessoaId: member.memberId,
            }),
          }),
        );
      }
    }

    if (promises.length) {
      try {
        setError(null);
        await Promise.all(promises);
      } catch (e) {
        setError(e);
        return;
      }
    }

    invalidateCaringGroupsQuery();
    navigate("/caring_groups");
  };

  const onDelete = async ({ selectedMembers }) => {
    const caringGroupId = data.caringGroupId;

    try {
      // TODO: deleting caring group should take care of this
      // delete all relations
      setError(null);
      await Promise.all(
        selectedMembers.map((member) =>
          fetch(`/v1/caring-groups-relation/${member.relationId}`, {
            method: "DELETE",
          }),
        ),
      );

      // delete caring group
      await fetch(`/v1/caring-groups/${caringGroupId}`, { method: "DELETE" });
    } catch (e) {
      setError(e);
      return;
    }

    invalidateCaringGroupsQuery();
    navigate("/caring_groups");
  };

  return (
    <CaringGroupsForm
      data={data}
      onSubmit={onSubmit}
      onDelete={onDelete}
      error={error}
      breadcrumbs={
        <Breadcrumbs
          heading={t("Edit caring group")}
          links={[
            {
              name: "Caring Groups",
              to: "/caring_groups",
            },
            { name: t("Edit") },
          ]}
          sx={{ mb: { xs: 3, md: 5 } }}
        />
      }
    />
  );
}
