import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import fetch from "data/fetch";
import FamiliaCristaFileForm from "./components/FamiliaCristaFileForm.react"; // import your form component
import Breadcrumbs from "shared/components/Breadcrumbs/Breadcrumbs.react";

export default function FamiliaCristaFileNewPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [error, setError] = useState(null);

  const onSubmit = async ({ file, type, description, estudoId }) => {
    setError(null);

    if (!file || !type || !description || !estudoId) {
      setError("Please fill all fields.");
      return;
    }

    if (window.confirm(t("Are you sure you want to upload this file?"))) {
      try {
        // Process for uploading the file
        const formData = new FormData();
        formData.append("file", file);
        formData.append("name", file.name);
        formData.append("mime", file.type);

        const fileUploadResponse = await fetch("/v1/file", {
          method: "POST",
          body: formData,
        });

        if (!fileUploadResponse.id) {
          throw new Error(t("File upload failed"));
        }

        // Process for sending the additional details
        await fetch("/v1/fc-file", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            fileId: fileUploadResponse.id,
            type,
            description,
            estudoId: estudoId,
          }),
        });

        navigate("/familia-crista/files"); // Redirect to the files list page
      } catch (error) {
        console.error("Upload error:", error);
        setError(
          error.message ?? t("Sorry, an unexpected error has occurred."),
        );
      }
    }
  };

  return (
    <FamiliaCristaFileForm
      onSubmit={onSubmit}
      error={error}
      breadcrumbs={
        <Breadcrumbs
          heading={t("Upload New File")}
          links={[
            {
              name: t("Familia Crista Files"),
              to: "/familia-crista/files",
            },
            { name: t("New") },
          ]}
          sx={{ mb: { xs: 3, md: 5 } }}
        />
      }
    />
  );
}
