export const formatCents = (input) => {
  const inputCleaned = input ? input.replace(/\D/g, "") : "0";
  const num = parseInt(inputCleaned, 10) || 0;
  return (num / 100).toFixed(2);
};

export const formatCurrency = (input) => {
  const currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return currencyFormatter.format(input || 0);
};
