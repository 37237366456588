import { Outlet, Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  useCaringGroupsReportsQuery,
  ensureCaringGroupsReportsQueryData,
} from "data/queries/queryCaringGroupsReports";
import IndexPageHeader from "shared/layouts/index/IndexPageHeader.react";
import IndexPageLayout from "shared/layouts/index/IndexPageLayout.react";
import IndexPageTable from "shared/layouts/index/IndexPageTable.react";
import Link from "@mui/material/Link";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

export async function loader() {
  return await ensureCaringGroupsReportsQueryData();
}

export default function CaringGroupsReportsIndexPage() {
  const { t } = useTranslation();
  const { data: reportData } = useCaringGroupsReportsQuery({ page: 0 });

  return (
    <>
      <Outlet />
      <IndexPageLayout
        useQuery={useCaringGroupsReportsQuery}
        hasSearch={false}
        defaultSorting="-dataFrequencia"
        header={
          <IndexPageHeader
            heading={t("Caring Groups Reports")}
            routeIndex="/caring_groups_reports"
            routeNew="/caring_groups_reports/new"
          />
        }
        table={
          <IndexPageTable
            headers={[
              {
                columnId: "caringGroup.congregacao.name",
                label: t("Congregation"),
              },
              { columnId: "caringGroup.lider.name", label: t("Leader") },
              { columnId: "caringGroup.departamento", label: t("Department") },
              { columnId: "dataFrequencia", label: t("Date") },
              { label: t("Member") },
              { label: t("Status") },
              { label: t("Comment") },
            ]}
            renderRow={({
              id,
              dataFrequencia,
              caringGroup,
              relatorioRelations,
            }) => (
              <>
                {relatorioRelations.map((relation) => (
                  <TableRow hover key={relation.id} tabIndex={-1}>
                    <TableCell>
                      <Typography variant="subtitle2" noWrap>
                        {caringGroup.congregacao.name}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle2">
                        {caringGroup.lider.name}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle2">
                        {caringGroup.departamento}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Link
                        component={RouterLink}
                        to={`/caring_groups_reports/${id}`}
                        noWrap
                      >
                        {dataFrequencia}
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle2">
                        {relation.caringGroupRelation.pessoa.name}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle2">
                        {relation.situacao}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle2">
                        {relation.comentario}
                      </Typography>
                    </TableCell>
                    <TableCell key="actions"></TableCell>
                  </TableRow>
                ))}
              </>
            )}
            rows={reportData?.data ?? []}
          />
        }
      />
    </>
  );
}
