import { ensureCongregationQueryData } from "data/queries/queryCongregations";
import GuestSignupForm from "./components/GuestSignupForm.react";
import { invalidateLoggedUserQuery } from "data/queries/queryLoggedUser";
import { useNavigate } from "react-router-dom";

export async function loader() {
  // preload congregations
  await ensureCongregationQueryData();
  return null;
}

export default function GuestNewPublicPage() {
  const navigate = useNavigate();
  return (
    <GuestSignupForm
      isPublic={true}
      onSuccess={async () => {
        await invalidateLoggedUserQuery();
        const urlParams = new URLSearchParams(window.location.search);
        const returnTo = urlParams.get("returnTo");
        if (returnTo) {
          navigate(returnTo);
          window.location.reload();
        }
      }}
    />
  );
}
