import fetch from "data/fetch";

export async function mutateEvent({
  eventId,
  congregacaoId,
  name,
  ticketPrice,
  stripeUrl,
  shortDescription,
  description,
  startAt,
  endAt,
  promoteStartAt,
  promoteEndAt,
  enabled,
  registrationEnabled,
  notificationEmails,
  fields,
}) {
  const url = eventId ? `/v1/event/${eventId}` : "/v1/event";
  const method = eventId ? "PUT" : "POST";

  const body = JSON.stringify({
    congregacaoId,
    name,
    ticketPrice,
    stripeUrl,
    shortDescription,
    description,
    startAt,
    endAt,
    promoteStartAt,
    promoteEndAt,
    enabled,
    registrationEnabled,
    notificationEmails,
    fields,
  });

  try {
    await fetch(url, {
      method,
      headers: {
        "Content-Type": "application/json",
      },
      body,
    });
    return true;
  } catch {
    return false;
  }
}
