import queryClient from "data/queryClient";
import { useQuery } from "@tanstack/react-query";
import fetch from "data/fetch";

function fetchCaringGroupsReports(
  page = 0,
  filter = "",
  sort = "-dataFrequencia",
) {
  return fetch(
    `/v1/caring-groups-relatorio?size=100&index=${page}&name=${encodeURIComponent(
      filter,
    )}&s=${sort}`,
  );
}
const getQueryKey = (index = 0, name = "", sort = "-dataFrequencia") => [
  "caring-groups-relatorio",
  index,
  name,
  sort,
];

export function useCaringGroupsReportsQuery({
  page = 0,
  filter = "",
  sort = "-dataFrequencia",
  ...other
}) {
  return useQuery({
    ...other,
    queryKey: getQueryKey(page, filter, sort),
    queryFn: () => fetchCaringGroupsReports(page, filter, sort),
  });
}

export function ensureCaringGroupsReportsQueryData({
  page = 0,
  filter = "",
  sort = "-dataFrequencia",
} = {}) {
  return queryClient.ensureQueryData({
    queryKey: getQueryKey(page, filter, sort),
    queryFn: () => fetchCaringGroupsReports(page, filter, sort),
  });
}

export function invalidateCaringGroupsReportsQuery() {
  return queryClient.invalidateQueries(["caring-groups-relatorio"]);
}
