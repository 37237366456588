import fetch from "data/fetch";

export async function mutateFileUpload(file) {
  const url = `/v1/file`;
  const method = "POST";

  try {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("name", file.name);
    formData.append("mime", file.type);

    const res = await fetch(url, {
      method: method,
      body: formData,
    });

    return { res: true, id: res?.id };
  } catch {
    return { res: false };
  }
}
