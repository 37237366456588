import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  useEBDClasseQuery,
  ensureEBDClasseQueryData,
} from "data/queries/queryEBDClasseAll";
import IndexPageHeader from "shared/layouts/index/IndexPageHeader.react";
import IndexPageLayout from "shared/layouts/index/IndexPageLayout.react";
import IndexPageTable from "shared/layouts/index/IndexPageTable.react";
import Link from "@mui/material/Link";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

export async function loader() {
  return await ensureEBDClasseQueryData();
}

export default function EBDIndexPage() {
  const { t } = useTranslation();

  return (
    <IndexPageLayout
      useQuery={useEBDClasseQuery}
      hasSearch={false}
      defaultSorting="+professor.name"
      header={
        <IndexPageHeader
          heading={t("Sunday School")}
          routeIndex="/ebd"
          routeNew="/ebd/new"
        />
      }
      table={
        <IndexPageTable
          headers={[
            { columnId: "congregacao.name", label: t("Congregation") },
            { columnId: "professor.name", label: t("Teacher") },
            { columnId: "classe", label: t("Class") },
          ]}
          renderRow={({ id, congregacao, professor, classe }) => (
            <TableRow hover key={id} tabIndex={-1}>
              <TableCell>
                <Typography variant="subtitle2" noWrap>
                  {congregacao.name}
                </Typography>
              </TableCell>
              <TableCell>
                <Link component={RouterLink} to={`/ebd/${id}/view`}>
                  {professor.name}
                </Link>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle2" noWrap>
                  {t(classe)}
                </Typography>
              </TableCell>
              <TableCell key="actions"></TableCell>
            </TableRow>
          )}
        />
      }
    />
  );
}
