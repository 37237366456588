import { useTranslation } from "react-i18next";
import {
  useGuestsQuery,
  ensureGuestsQueryData,
} from "data/queries/queryGuests";
import { useGuestsDeleteMutation } from "data/mutations/mutateGuests";
import IndexPageDeleteAction from "shared/layouts/index/IndexPageDeleteAction.react";
import IndexPageHeader from "shared/layouts/index/IndexPageHeader.react";
import IndexPageLayout from "shared/layouts/index/IndexPageLayout.react";
import IndexPageTable from "shared/layouts/index/IndexPageTable.react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import moment from "moment";

export async function loader() {
  return await ensureGuestsQueryData();
}

export default function GuestsIndexPage() {
  const { t } = useTranslation();

  return (
    <IndexPageLayout
      useQuery={useGuestsQuery}
      defaultSorting="-dataVisita"
      header={
        <IndexPageHeader
          heading={t("Guests")}
          routeIndex="/guests/list"
          routeNew="/guests/new"
        />
      }
      table={
        <IndexPageTable
          headers={[
            { columnId: "pessoa.name", label: t("Name") },
            { columnId: "congregacao.name", label: t("Congregation") },
            { columnId: "dataVisita", label: t("Visitation Date") },
            { columnId: "pessoa.email", label: t("Email") },
            { columnId: "pessoa.phone", label: t("Phone") },
            {
              columnId: "pessoa.prefContact",
              label: t("Preferred contact method"),
            },
            { label: t("Actions") },
          ]}
          renderRow={({ id, pessoa, congregacao, dataVisita, prefContact }) => (
            <TableRow hover key={id} tabIndex={-1}>
              <TableCell>{pessoa.name}</TableCell>
              <TableCell>{congregacao.name}</TableCell>
              <TableCell>{moment(dataVisita).format("MMM/DD/YYYY")}</TableCell>
              <TableCell>{pessoa.email}</TableCell>
              <TableCell>{pessoa.phone}</TableCell>
              <TableCell>{prefContact ?? pessoa.prefContact}</TableCell>
              <TableCell>
                <IndexPageDeleteAction
                  id={id}
                  useMutation={useGuestsDeleteMutation}
                />
              </TableCell>
            </TableRow>
          )}
        />
      }
    />
  );
}
