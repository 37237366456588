import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormCard from "shared/components/FormCard/FormCard.react";
import FormControlLabel from "@mui/material/FormControlLabel";
import MenuItem from "@mui/material/MenuItem";
import moment from "moment";
import React, { forwardRef, Fragment, useImperativeHandle } from "react";
import RemoveIcon from "@mui/icons-material/Remove";
import TextField from "@mui/material/TextField";
import isStringNullOrEmpty from "shared/utils/isStringNullOrEmpty";
import SelectField from "shared/components/SelectField/SelectField";

export const MaritalStatus = {
  SINGLE: "SINGLE",
  MARRIED: "MARRIED",
  DIVORCED: "DIVORCED",
  WIDOWED: "WIDOWED",
};

const MemberFormFamilyInfoCard = forwardRef(function MemberFormFamilyInfoCard(
  {
    childrenInfo,
    formErrors,
    maritalStatus,
    marriageAt,
    memberType,
    onAddChildClick,
    onChildChange,
    onMaritalStatusChange,
    onMarriageAtChange,
    onRemoveChildClick,
    onSpouseBirthDateChange,
    onSpouseIsMemberChange,
    onSpouseNameChange,
    spouseBirthDate,
    spouseIsMember,
    spouseName,
  },
  ref,
) {
  const { t } = useTranslation();
  const isMarried = maritalStatus === MaritalStatus.MARRIED;
  const requireMoreData =
    !isStringNullOrEmpty(memberType) &&
    !["CONVIDADO", "CONGREGADO"].includes(memberType);
  const isSpouseBirthdayRequired =
    (requireMoreData && isMarried) || !isStringNullOrEmpty(spouseName);

  useImperativeHandle(
    ref,
    () => ({
      validate(formErrors) {
        if (requireMoreData && isStringNullOrEmpty(maritalStatus)) {
          formErrors.maritalStatus = t("Marital Status is required");
        }

        if (requireMoreData && isMarried && isStringNullOrEmpty(spouseName)) {
          formErrors.spouseName = t("Spouse name is required");
        }

        if (isSpouseBirthdayRequired && isStringNullOrEmpty(spouseBirthDate)) {
          formErrors.spouseBirthDate = t("Spouse birthdate is required");
        }

        childrenInfo?.forEach((child, i) => {
          const hasName = !isStringNullOrEmpty(child.name);
          const hasBirthdate = !isStringNullOrEmpty(child.birthDate);

          if (!hasName) {
            formErrors.childrenInfo = formErrors.childrenInfo ?? {};
            formErrors.childrenInfo[`${i}_name`] = t(
              "Child's name is required",
            );
          }

          if (!hasBirthdate) {
            formErrors.childrenInfo = formErrors.childrenInfo ?? {};
            formErrors.childrenInfo[`${i}_birthDate`] = t(
              "Child's birthdate is required",
            );
          }
        });
      },
    }),
    [
      childrenInfo,
      isMarried,
      isSpouseBirthdayRequired,
      maritalStatus,
      requireMoreData,
      spouseBirthDate,
      spouseName,
      t,
    ],
  );

  return (
    <FormCard title={t("Family Information")}>
      <SelectField
        required={requireMoreData}
        label={t("Marital Status")}
        value={maritalStatus}
        onChange={onMaritalStatusChange}
        error={!!formErrors?.maritalStatus}
        helperText={formErrors?.maritalStatus}
      >
        <MenuItem value={MaritalStatus.SINGLE}>{t("Single")}</MenuItem>
        <MenuItem value={MaritalStatus.MARRIED}>{t("Married")}</MenuItem>
        <MenuItem value={MaritalStatus.DIVORCED}>{t("Divorced")}</MenuItem>
        <MenuItem value={MaritalStatus.WIDOWED}>{t("Widowed")}</MenuItem>
      </SelectField>
      {isMarried && (
        <>
          <TextField
            error={!!formErrors.spouseName}
            helperText={formErrors.spouseName}
            required={requireMoreData}
            label={t("Spouse's Name")}
            value={spouseName}
            onChange={changeHandler(onSpouseNameChange)}
          />
          <DatePicker
            label={t("Spouse's Date of Birth")}
            required={isSpouseBirthdayRequired}
            value={spouseBirthDate}
            onChange={onSpouseBirthDateChange}
            disableFuture={true}
            slotProps={{
              textField: {
                InputLabelProps: {
                  required: isSpouseBirthdayRequired,
                },
                error: !!formErrors.spouseBirthDate,
                helperText: formErrors.spouseBirthDate,
              },
            }}
          />
          <DatePicker
            label={t("Marriage Date")}
            value={marriageAt}
            onChange={onMarriageAtChange}
            disableFuture={true}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={spouseIsMember}
                onChange={(e) => onSpouseIsMemberChange(e.target.checked)}
              />
            }
            label={t("Spouse is also a member?")}
          />
        </>
      )}
      {childrenInfo?.map((child, index) => (
        <Fragment key={index}>
          <TextField
            error={!!formErrors.childrenInfo?.[`${index}_name`]}
            helperText={formErrors.childrenInfo?.[`${index}_name`]}
            label={t("Child's Name")}
            value={child.name}
            required={true}
            onChange={(e) => {
              const name = (e.currentTarget ?? e.target).value;
              onChildChange({ ...child, name }, index);
            }}
          />
          <DatePicker
            disableFuture={true}
            label={t("Child's Date of Birth")}
            value={child.birthDate ? moment(child.birthDate) : null}
            onChange={(value) =>
              onChildChange({ ...child, birthDate: value }, index)
            }
            required={true}
            slotProps={{
              textField: {
                InputLabelProps: { required: true },
                error: !!formErrors.childrenInfo?.[`${index}_birthDate`],
                helperText: formErrors.childrenInfo?.[`${index}_birthDate`],
              },
            }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={child.isMember}
                onChange={(e) =>
                  onChildChange({ ...child, isMember: e.target.checked }, index)
                }
              />
            }
            label={t("Child is also a member?")}
          />
          <Button
            startIcon={<RemoveIcon />}
            variant="contained"
            size="medium"
            onClick={() => onRemoveChildClick(index)}
          >
            {t("Remove Child")}
          </Button>
        </Fragment>
      ))}
      <Button
        onClick={onAddChildClick}
        startIcon={<AddIcon />}
        variant="outlined"
      >
        {t("Add Child")}
      </Button>
    </FormCard>
  );
});

function changeHandler(setValue) {
  return (e) => {
    setValue((e.currentTarget ?? e.target).value);
  };
}

export default MemberFormFamilyInfoCard;
