import queryClient from "data/queryClient";
import { useQuery } from "@tanstack/react-query";
import fetch from "data/fetch";

function fetchNextEvents(page = 0, filter = "") {
  return fetch(
    `/v1/next-event?size=100&index=${page}&q=${encodeURIComponent(filter)}`,
  );
}
export function fetchNextEvent(id) {
  return fetch(`/v1/next-event/${id}`);
}
export function fetchNextEventRegistration(id) {
  return fetch(`/v1/next-event/${id}/registration`);
}
const getQueryKey = (index = 0, name = "") => ["next-event", index, name];

export function useNextEventsQuery({ page = 0, filter = "", ...other }) {
  return useQuery({
    ...other,
    queryKey: getQueryKey(page, filter),
    queryFn: () => fetchNextEvents(page, filter),
  });
}

export function ensureNextEventsQueryData({ page = 0, filter = "" } = {}) {
  return queryClient.ensureQueryData({
    queryKey: getQueryKey(page, filter),
    queryFn: () => fetchNextEvents(page, filter),
  });
}

export function invalidateNextEventsQuery() {
  return queryClient.invalidateQueries(["next-event"]);
}
