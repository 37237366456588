import { IndexPageTableContext } from "shared/layouts/index/IndexPageTable.react";
import { useCallback, useMemo, useState } from "react";
import Card from "@mui/material/Card";
import IndexPageToolbar from "shared/layouts/index/IndexPageToolbar.react";
import TableContainer from "@mui/material/TableContainer";
import isStringNullOrEmpty from "shared/utils/isStringNullOrEmpty";

export default function IndexPageCard({
  additionalFilters,
  defaultSorting,
  hasSearch = true,
  queryParams = {},
  table,
  useQuery,
}) {
  const [page, setPage] = useState(0);
  const [filter, setFilter] = useState("");
  const [additionalFilterValues, setAdditionalFilterValues] = useState({});
  const [sort, setSort] = useState(normalizeSortProp(defaultSorting));

  const { data: queryData, ...queryState } = useQuery({
    ...queryParams,
    page,
    filter,
    sort,
    ...additionalFilterValues,
    keepPreviousData: true,
  });

  const onPageChange = useCallback((_e, page) => {
    setPage(page);
  }, []);

  const onSearch = useCallback((newFilter) => {
    setPage(0);
    setFilter(newFilter);
  }, []);

  const onSortChange = useCallback(
    (columnId) => {
      if (`+${columnId}` === sort) {
        setSort(`-${columnId}`);
      } else {
        setSort(`+${columnId}`);
      }
    },
    [sort],
  );

  const onAdditionalFilterChange = useCallback((key, value) => {
    setPage(0);
    setAdditionalFilterValues((prev) => ({
      ...prev,
      [key]: value,
    }));
  }, []);

  const tableContextValue = useMemo(
    () => ({
      filter,
      onPageChange,
      onSortChange,
      page,
      queryData,
      queryState,
      sort,
    }),
    [filter, onPageChange, onSortChange, page, queryData, queryState, sort],
  );

  return (
    <Card>
      <IndexPageToolbar
        onSearch={hasSearch ? onSearch : null}
        additionalFilters={additionalFilters}
        onAdditionalFilterChange={onAdditionalFilterChange}
      />
      <TableContainer sx={{ minWidth: { lg: 800 } }}>
        <IndexPageTableContext.Provider value={tableContextValue}>
          {table}
        </IndexPageTableContext.Provider>
      </TableContainer>
    </Card>
  );
}

function normalizeSortProp(sort) {
  if (isStringNullOrEmpty(sort)) {
    return null;
  }

  if (sort.indexOf("+") === 0 || sort.indexOf("-") === 0) {
    return sort;
  }

  return `+${sort}`;
}
