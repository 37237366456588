import { useTranslation } from "react-i18next";
import { Link as RouterLink, useLoaderData, useParams } from "react-router-dom";
import IndexPageHeader from "shared/layouts/index/IndexPageHeader.react";
import IndexPageLayout from "shared/layouts/index/IndexPageLayout.react";
import IndexPageTable from "shared/layouts/index/IndexPageTable.react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import {
  ensureEventRegistrationPaymentsQueryData,
  useEventRegistrationPaymentsQuery,
} from "data/queries/events/queryEventRegistrationPayments";
import { formatCurrency } from "utils/NumberUtils";
import moment from "moment";

export async function loader({ params: { id, registrationId } }) {
  return await ensureEventRegistrationPaymentsQueryData({
    eventId: id,
    registrationId: registrationId,
  });
}

export default function EventManagementRegistrationsPaymentIndexPage() {
  const { t } = useTranslation();
  const { id: eventId, registrationId } = useParams();
  const { data } = useLoaderData();

  return (
    <IndexPageLayout
      useQuery={useEventRegistrationPaymentsQuery}
      queryParams={{ eventId: eventId, registrationId: registrationId }}
      header={
        <>
          <IndexPageHeader
            heading={t("Event Registration Payments")}
            routeIndex={`/event-management/${eventId}/registrations/${registrationId}/payments`}
            routeNew={`/event-management/${eventId}/registrations/${registrationId}/payments/new`}
          />
          <Typography variant="h4">
            {data[0]?.registration?.pessoa?.name}
          </Typography>
        </>
      }
      table={
        <IndexPageTable
          headers={[t("Note"), t("Payment Date"), t("Source"), t("Amount")]}
          renderRow={({ id, amount, note, paymentDate, source }) => (
            <TableRow hover key={id} tabIndex={-1}>
              <TableCell>
                <Typography variant="subtitle2" noWrap>
                  {note || "-"}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle2" noWrap>
                  {moment(paymentDate).format("DD/MM/YYYY HH:mm")}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle2" noWrap>
                  {t(source)}
                </Typography>
              </TableCell>
              <TableCell>
                <Link
                  component={RouterLink}
                  to={`/event-management/${eventId}/registrations/${registrationId}/payments/${id}`}
                >
                  <Typography variant="subtitle2" noWrap>
                    {formatCurrency(amount)}
                  </Typography>
                </Link>
              </TableCell>
            </TableRow>
          )}
          renderFooter={(queryData) => (
            <TableRow>
              <TableCell />
              <TableCell />
              <TableCell>
                <Typography align="left" variant="subtitle2">
                  {t("Total")}
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography align="left" variant="subtitle2">
                  {formatCurrency(
                    queryData?.data[0]?.registration?.totalPayments,
                  )}
                </Typography>
              </TableCell>
            </TableRow>
          )}
        />
      }
    />
  );
}
