import {
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
  Checkbox,
  FormHelperText,
  FormGroup,
} from "@mui/material";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import FileInput from "shared/components/FileInput/FileInput.react";

const DynamicField = ({ field, control, showInvisible }) => {
  const { t } = useTranslation();

  if (!showInvisible && field.visible === false) {
    return null;
  }

  switch (field.type) {
    case "select":
      return (
        <Controller
          key={field.name}
          name={field.name}
          control={control}
          defaultValue={field.defaultValue || ""}
          rules={{
            required: {
              value: field.required,
              message: t(field.description) || t("This field is required."),
            },
          }}
          render={({
            field: controllerField,
            fieldState: { error, invalid },
          }) => (
            <FormControl fullWidth margin="normal" error={invalid}>
              <InputLabel required={field.required}>
                {t(field.label)}
              </InputLabel>
              <Select label={t(field.label)} {...controllerField}>
                {field.options.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
              {(field.description || error?.message) && (
                <FormHelperText>
                  {error?.message || t(field.description)}
                </FormHelperText>
              )}
            </FormControl>
          )}
        />
      );
    case "text":
      return (
        <Controller
          key={field.name}
          name={field.name}
          control={control}
          defaultValue={field.defaultValue || ""}
          rules={{
            required: {
              value: field.required,
              message: t(field.description) || t("This field is required."),
            },
          }}
          render={({
            field: controllerField,
            fieldState: { error, invalid },
          }) => (
            <TextField
              {...controllerField}
              fullWidth
              margin="normal"
              label={t(field.label)}
              helperText={error ? error.message : t(field.description)}
              error={invalid}
              slotProps={{
                inputLabel: { required: field.required },
              }}
            />
          )}
        />
      );
    case "textarea":
      return (
        <Controller
          key={field.name}
          name={field.name}
          control={control}
          defaultValue={field.defaultValue || ""}
          rules={{
            required: {
              value: field.required,
              message: t(field.description) || t("This field is required."),
            },
          }}
          render={({
            field: controllerField,
            fieldState: { error, invalid },
          }) => (
            <TextField
              {...controllerField}
              fullWidth
              margin="normal"
              label={t(field.label)}
              helperText={error ? error.message : t(field.description)}
              error={invalid}
              slotProps={{
                inputLabel: { required: field.required },
              }}
              multiline
              rows={4}
            />
          )}
        />
      );
    case "radio":
      return (
        <Controller
          key={field.name}
          name={field.name}
          control={control}
          rules={{
            required: {
              value: field.required,
              message: t(field.description) || t("This field is required."),
            },
          }}
          render={({ field: controllerField }) => (
            <FormControl component="fieldset" margin="normal">
              <FormLabel component="legend">{t(field.label)}</FormLabel>
              <RadioGroup {...controllerField}>
                {field.options && field.options.length > 0 ? (
                  field.options.map((option) => (
                    <FormControlLabel
                      key={option}
                      value={option}
                      control={<Radio />}
                      label={option}
                    />
                  ))
                ) : (
                  <FormControlLabel
                    value={field.defaultValue || ""}
                    control={<Radio />}
                    label={t(field.label)}
                  />
                )}
              </RadioGroup>
              {field.description && (
                <Typography variant="caption">
                  {t(field.description)}
                </Typography>
              )}
            </FormControl>
          )}
        />
      );
    case "checkbox":
      return (
        <Controller
          key={field.name}
          name={field.name}
          control={control}
          defaultValue={
            field.defaultValue === true || field.defaultValue === "true"
          }
          rules={{
            required: {
              value: field.required,
              message:
                t(field.description) ||
                t("Please check this box if you want to proceed."),
            },
          }}
          render={({
            field: controllerField,
            fieldState: { error, invalid },
          }) => (
            <FormControl
              required={field.required}
              error={invalid}
              fullWidth
              margin="normal"
              component="fieldset"
              sx={{ m: 3 }}
              variant="standard"
            >
              <FormGroup>
                <FormLabel required={field.required} component="legend">
                  {t(field.label)}
                </FormLabel>
                <FormControlLabel
                  key={field.name}
                  control={
                    <Checkbox
                      {...controllerField}
                      checked={
                        controllerField.value === true ||
                        controllerField.value === "true"
                      }
                      onChange={(e) =>
                        controllerField.onChange(
                          e.target.checked === true ||
                            e.target.checked === "true",
                        )
                      }
                    />
                  }
                  label={t(field.description) || t(field.label)}
                />
              </FormGroup>
              {(field.description || error?.message) && (
                <FormHelperText>
                  {t(error?.message) || t(field.description)}
                </FormHelperText>
              )}
            </FormControl>
          )}
        />
      );
    case "date":
      return (
        <Controller
          key={field.name}
          name={field.name}
          control={control}
          defaultValue={field.defaultValue || ""}
          rules={{
            required: {
              value: field.required,
              message: t(field.description) || t("This field is required."),
            },
          }}
          render={({ field: controllerField, fieldState: { error } }) => (
            <TextField
              {...controllerField}
              fullWidth
              margin="normal"
              label={t(field.label)}
              type="date"
              slotProps={{
                inputLabel: { shrink: true },
                textField: {
                  helperText: error ? error.message : t(field.description),
                },
              }}
              helperText={field.description}
              required={field.required}
            />
          )}
        />
      );
    case "time":
      return (
        <Controller
          key={field.name}
          name={field.name}
          control={control}
          defaultValue={field.defaultValue || ""}
          rules={{
            required: {
              value: field.required,
              message: t(field.description) || t("This field is required."),
            },
          }}
          render={({ field: controllerField, fieldState: { error } }) => (
            <TextField
              {...controllerField}
              fullWidth
              margin="normal"
              label={t(field.label)}
              type="time"
              slotProps={{
                inputLabel: { shrink: true },
                textField: {
                  helperText: error ? error.message : t(field.description),
                },
              }}
              helperText={field.description}
              required={field.required}
            />
          )}
        />
      );
    case "datetime":
      return (
        <Controller
          key={field.name}
          name={field.name}
          control={control}
          defaultValue={field.defaultValue || ""}
          rules={{
            required: {
              value: field.required,
              message: t(field.description) || t("This field is required."),
            },
          }}
          render={({ field: controllerField, fieldState: { error } }) => (
            <TextField
              {...controllerField}
              fullWidth
              margin="normal"
              label={t(field.label)}
              type="datetime-local"
              slotProps={{
                inputLabel: { shrink: true },
                textField: {
                  helperText: error ? error.message : t(field.description),
                },
              }}
              helperText={field.description}
              required={field.required}
            />
          )}
        />
      );
    case "file":
      return <FileInput field={field} control={control} />;
    default:
      return null;
  }
};

export default DynamicField;
