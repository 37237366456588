import queryClient from "data/queryClient";
import { useQuery } from "@tanstack/react-query";
import fetch from "data/fetch";

function fetchEventRegistrationPayments(
  eventId = null,
  registrationId = null,
  page = 0,
  filter = "",
) {
  return fetch(
    `/v1/event-registration/${registrationId}/payment?size=100&index=${page}&q=${encodeURIComponent(filter)}`,
  );
}
export function fetchEventRegistrationPayment(id) {
  return fetch(`/v1/event-registration-payment/${id}`);
}
const getQueryKey = (
  eventId = null,
  registrationId = null,
  index = 0,
  name = "",
) => ["event-registration-payment", eventId, registrationId, index, name];

export function useEventRegistrationPaymentsQuery({
  eventId = null,
  registrationId = null,
  page = 0,
  filter = "",
  ...other
}) {
  return useQuery({
    ...other,
    queryKey: getQueryKey(eventId, registrationId, page, filter),
    queryFn: () =>
      fetchEventRegistrationPayments(eventId, registrationId, page, filter),
  });
}

export function ensureEventRegistrationPaymentsQueryData({
  eventId = null,
  registrationId = null,
  page = 0,
  filter = "",
} = {}) {
  return queryClient.ensureQueryData({
    queryKey: getQueryKey(eventId, registrationId, page, filter),
    queryFn: () =>
      fetchEventRegistrationPayments(eventId, registrationId, page, filter),
  });
}

export function invalidateEventRegistrationPaymentsQuery() {
  return queryClient.invalidateQueries(["event-registration-payment"]);
}
