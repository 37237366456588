import queryClient from "data/queryClient";
import { useQuery } from "@tanstack/react-query";
import fetch from "data/fetch";

function fetchEBDClasseReports(page = 0, filter = "", sort = "-data") {
  return fetch(
    `/v1/ebd-classe-relatorio?size=100&index=${page}&name=${encodeURIComponent(
      filter,
    )}&s=${sort}`,
  );
}

const getQueryKey = (index = 0, name = "", sort = "-data") => [
  "ebd-classe-relatorio",
  index,
  name,
  sort,
];

export function useEBDClasseReportsQuery({
  page = 0,
  filter = "",
  sort = "-data",
  ...other
}) {
  return useQuery({
    ...other,
    queryKey: getQueryKey(page, filter, sort),
    queryFn: () => fetchEBDClasseReports(page, filter, sort),
  });
}

export function ensureEBDClasseReportsQueryData({
  page = 0,
  filter = "",
  sort = "-data",
} = {}) {
  return queryClient.ensureQueryData({
    queryKey: getQueryKey(page, filter, sort),
    queryFn: () => fetchEBDClasseReports(page, filter, sort),
  });
}

export function invalidateEBDClasseReportsQuery() {
  return queryClient.invalidateQueries(["ebd-classe-relatorio"]);
}
