import { alpha, useTheme } from "@mui/material/styles";
import { useMatch, NavLink as RouterNavLink } from "react-router-dom";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Stack } from "@mui/material";
import ViewportSideNavContext from "./ViewportSideNavContext.react";
import { useId, useCallback, useContext, useState, createContext } from "react";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Menu from "@mui/material/Menu";

export const PopoverContext = createContext(false);

export default function ViewportSideNavItem({
  "data-testid": dataTestID,
  label,
  to,
  icon,
  children,
}) {
  const isViewportNavOpen = useContext(ViewportSideNavContext);
  const theme = useTheme();
  const isActive = useMatch(to);
  const [isItemOpen, setIsItemOpen] = useState(true);
  const buttonId = useId();
  const popoverId = useId();
  const hasChildren = children != null;
  const [anchorEl, setAnchorEl] = useState(null);
  const isPopoverOpen = Boolean(anchorEl);

  const handleClick = useCallback(
    (event) => {
      setIsItemOpen((x) => !x);

      if (hasChildren && !isViewportNavOpen) {
        setAnchorEl(event.currentTarget);
      } else {
        setAnchorEl(null);
      }
    },
    [hasChildren, isViewportNavOpen],
  );

  const handleClose = () => {
    setAnchorEl(null);
  };

  const typeCollapsed = !isViewportNavOpen && {
    fontSize: 10,
    lineHeight: "16px",
    textAlign: "center",
  };

  return (
    <>
      <ListItemButton
        data-testid={dataTestID}
        component={hasChildren ? "div" : RouterNavLink}
        to={hasChildren ? null : to}
        onClick={hasChildren ? handleClick : null}
        disableGutters
        sx={[styles.root(theme), !isViewportNavOpen && styles.collapsed]}
        id={buttonId}
        aria-controls={isPopoverOpen ? popoverId : undefined}
        aria-haspopup={hasChildren && !isViewportNavOpen ? "true" : undefined}
        aria-expanded={isPopoverOpen ? "true" : undefined}
      >
        <ListItemIcon
          sx={[styles.icon, !isViewportNavOpen && styles.iconCollapsed]}
        >
          {icon}
        </ListItemIcon>
        <ListItemText
          sx={[!isViewportNavOpen && styles.textCollapsed]}
          primary={label}
          primaryTypographyProps={{
            noWrap: false,
            typography: "body2",
            textTransform: "capitalize",
            fontWeight: isActive ? "fontWeightSemiBold" : "fontWeightMedium",
            ...typeCollapsed,
          }}
        />
        <SubMenuIcon
          hasChildren={hasChildren}
          isViewportNavOpen={isViewportNavOpen}
          isItemOpen={isItemOpen}
        />
      </ListItemButton>
      <SubMenu
        anchorEl={anchorEl}
        buttonId={buttonId}
        popoverId={popoverId}
        isViewportNavOpen={isViewportNavOpen}
        isItemOpen={isItemOpen}
        isPopoverOpen={isPopoverOpen}
        handleClose={handleClose}
      >
        {children}
      </SubMenu>
    </>
  );
}

function SubMenuIcon({ hasChildren, isViewportNavOpen, isItemOpen }) {
  if (!hasChildren) {
    return null;
  }

  if (isViewportNavOpen) {
    return isItemOpen ? <ExpandLess /> : <ExpandMore />;
  }

  return <ChevronRightIcon sx={styles.rightIcon} />;
}

function SubMenu({
  anchorEl,
  isPopoverOpen,
  handleClose,
  buttonId,
  popoverId,
  children,
  isViewportNavOpen,
  isItemOpen,
}) {
  if (children == null) {
    return null;
  }

  if (isViewportNavOpen && isItemOpen) {
    return <Stack>{children}</Stack>;
  }

  return (
    <PopoverContext.Provider value={true}>
      <Menu
        id={popoverId}
        anchorEl={anchorEl}
        open={isPopoverOpen}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": buttonId,
        }}
        anchorOrigin={{
          horizontal: "right",
        }}
      >
        {children}
      </Menu>
    </PopoverContext.Provider>
  );
}

const styles = {
  root: (theme) => ({
    borderRadius: "8px",
    color: theme.palette.text.secondary,
    marginBottom: "4px",
    minHeight: "44px",
    padding: "4px 8px 4px 12px",
    position: "relative",
    "&.active": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.primary.main
          : theme.palette.primary.light,
      backgroundColor: alpha(theme.palette.primary.main, 0.08),
      "&:hover": {
        backgroundColor: alpha(theme.palette.primary.main, 0.16),
      },
    },
  }),
  collapsed: {
    borderRadius: "6px",
    minHeight: "56px",
    padding: "6px 0 0 0",
    flexDirection: "column",
    margin: 0.5,
  },
  icon: {
    width: 24,
    height: 24,
    alignItems: "center",
    justifyContent: "center",
    minWidth: "auto",
    mr: 2,
  },
  iconCollapsed: {
    width: 22,
    height: 22,
    mr: 0,
  },
  rightIcon: {
    fontSize: 18,
    position: "absolute",
    top: 10,
    right: 4,
  },
  textCollapsed: {
    maxWidth: "100%",
  },
  subItem: {
    paddingLeft: "40px", // Submenu indentation
    fontSize: "0.875rem", // Smaller font size for submenu items
    // Add more styles as needed
  },
};
