import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import CongregationSelect from "shared/components/CongregationSelect/CongregationSelect.react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import ErrorAlert from "shared/components/ErrorState/ErrorAlert.react";

export default function CultoSecretariaForm({
  breadcrumbs,
  onSubmit: onSubmitProp,
  error: errorProp,
  initialValues = {},
}) {
  const { t } = useTranslation();

  // Form state
  const [congregationId, setCongregationId] = useState(
    initialValues.congregacao?.id ?? null,
  );
  const [date, setDate] = useState(
    initialValues.data ? moment(initialValues.data) : null,
  );
  const [convidadosNaoCrentes, setConvidadosNaoCrentes] = useState(
    initialValues.convidadosNaoCrentes ?? 0,
  );
  const [aceitaram, setAceitaram] = useState(initialValues.aceitaram ?? 0);
  const [retornos, setRetornos] = useState(initialValues.retornos ?? 0);
  const [adultos, setAdultos] = useState(initialValues.adultos ?? 0);
  const [criancas, setCriancas] = useState(initialValues.criancas ?? 0);
  const [convidadosCrentes, setConvidadosCrentes] = useState(
    initialValues.convidadosCrentes ?? 0,
  );
  const [batismo, setBatismo] = useState(initialValues.batismo ?? 0);
  const [batismoEs, setBatismoEs] = useState(initialValues.batismoEs ?? 0);
  const [transferencias, setTransferencias] = useState(
    initialValues.transferencias ?? 0,
  );
  const [recebidos, setRecebidos] = useState(initialValues.recebidos ?? 0);
  const [tipoCulto, setTipoCulto] = useState(initialValues.tipoCulto ?? "");
  const [obs, setObs] = useState(initialValues.obs ?? "");
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Options for service type
  const cultoOptions = [
    "ENSINO",
    "SANTA_CEIA",
    "MISSOES_E_FRIENDS_4EVER",
    "CELEBRACAO",
    "FRIENDS_4EVER",
    "JOVENS",
    "OUTRO",
  ];

  // Submission handler
  const onSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    onSubmitProp({
      congregacaoId: congregationId,
      data: date,
      convidadosNaoCrentes,
      aceitaram,
      retornos,
      adultos,
      criancas,
      convidadosCrentes,
      batismo,
      batismoEs,
      transferencias,
      recebidos,
      tipoCulto,
      obs,
    }).finally(() => {
      setIsSubmitting(false);
    });
  };

  return (
    <Container component="form" method="post" onSubmit={onSubmit}>
      {breadcrumbs}
      <Card>
        <Stack spacing={3} sx={{ p: 3 }}>
          {/* Congregation Select */}
          <CongregationSelect
            required
            initialValue={initialValues.congregacao}
            onChange={(value) => setCongregationId(value?.id ?? null)}
          />
          {/* Date Picker */}
          <DatePicker
            label={t("Date")}
            value={date}
            onChange={setDate}
            renderInput={(params) => <TextField {...params} required />}
          />
          {/* Reusable Numeric Inputs */}

          <NumericInput
            label={t("Adults")}
            value={adultos}
            onChange={setAdultos}
          />
          <NumericInput
            label={t("Children")}
            value={criancas}
            onChange={setCriancas}
          />
          <NumericInput
            label={t("Guests (Believers)")}
            value={convidadosCrentes}
            onChange={setConvidadosCrentes}
          />
          <NumericInput
            label={t("Guests (Non-Believers)")}
            value={convidadosNaoCrentes}
            onChange={setConvidadosNaoCrentes}
          />
          <NumericInput
            label={t("Accepted")}
            value={aceitaram}
            onChange={setAceitaram}
          />
          <NumericInput
            label={t("Returned")}
            value={retornos}
            onChange={setRetornos}
          />
          <NumericInput
            label={t("Baptisms")}
            value={batismo}
            onChange={setBatismo}
          />
          <NumericInput
            label={t("Holy Spirit Baptisms")}
            value={batismoEs}
            onChange={setBatismoEs}
          />
          <NumericInput
            label={t("Transfers")}
            value={transferencias}
            onChange={setTransferencias}
          />
          <NumericInput
            label={t("Received Members")}
            value={recebidos}
            onChange={setRecebidos}
          />
          {/* Service Type */}
          <FormControl required>
            <InputLabel>{t("Service Type")}</InputLabel>
            <Select
              value={tipoCulto}
              onChange={(e) => setTipoCulto(e.target.value)}
            >
              {cultoOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {t(option)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {/* Observations */}
          <TextField
            label={t("Observations")}
            multiline
            rows={4}
            value={obs}
            onChange={(e) => {
              const inputValue = e.target.value;
              if (inputValue.length <= 500) {
                setObs(inputValue);
              }
            }}
            slotProps={{
              input: {
                maxLength: 500,
              },
            }}
            helperText={`${obs.length}/500`}
          />
          {/* Error Handling */}
          <ErrorAlert error={errorProp} />
          {/* Submit Button */}
          <Stack direction="row" justifyContent="flex-end" spacing={1}>
            <Button
              startIcon={<AddIcon />}
              variant="contained"
              color="success"
              size="large"
              disabled={isSubmitting}
              type="submit"
            >
              {isSubmitting ? t("Submitting...") : t("Submit")}
            </Button>
          </Stack>
        </Stack>
      </Card>
    </Container>
  );
}

function NumericInput({ label, value, onChange }) {
  return (
    <TextField
      label={label}
      type="text"
      value={value}
      onChange={(e) => {
        const inputValue = e.target.value;
        if (/^\d*$/.test(inputValue)) {
          onChange(inputValue);
        }
      }}
      onFocus={(e) => e.target.select()} // Select all text on focus
      onKeyDown={(e) => {
        if (e.key === "ArrowUp" || e.key === "ArrowDown") {
          e.preventDefault();
        }
      }}
      onWheel={(e) => e.target.blur()} // Prevent scroll changes
      required
    />
  );
}
