import React from "react";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useActionData } from "react-router-dom";
import {
  Container,
  Card,
  Stack,
  TextField,
  Box,
  Button,
  InputAdornment,
} from "@mui/material";

import ErrorAlert from "shared/components/ErrorState/ErrorAlert.react";
import { formatCents } from "utils/NumberUtils";

export default function EventManagementRegistrationPaymentForm({
  data,
  onSubmit,
  onDelete,
  breadcrumbs,
}) {
  const { t } = useTranslation();
  const actionData = useActionData();

  const {
    control,
    handleSubmit,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      eventId: data?.eventId || null,
      registrationId: data?.registrationId || null,
      amount: data?.amount || "",
      note: data?.note || "",
    },
  });

  const onFormSubmit = async (formData) => {
    try {
      await onSubmit(formData);
    } catch (err) {
      console.error(err);
    }
  };

  const onFormDelete = async (formData) => {
    try {
      await onDelete(formData);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Container component="form" onSubmit={handleSubmit(onFormSubmit)}>
      {breadcrumbs}
      <Stack spacing={3} sx={{ p: 3 }}>
        <Card sx={{ p: 3 }}>
          {/* Amount Input */}
          <Controller
            name="amount"
            control={control}
            rules={{
              required: {
                value: true,
                message: t("Enter an amount."),
              },
              validate: (value) =>
                value > 0 || t("Amount must be a positive value."),
            }}
            render={({ field, fieldState: { error, invalid } }) => (
              <TextField
                {...field}
                slotProps={{
                  inputLabel: { required: true },
                  input: {
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  },
                }}
                label={t("Amount")}
                type="number"
                fullWidth
                onChange={(e) => {
                  field.onChange(formatCents(e.target.value));
                }}
                margin="normal"
                helperText={error ? error.message : t("Enter an amount.")}
                error={invalid}
              />
            )}
          />

          {/* Note Input */}
          <Controller
            name="note"
            control={control}
            render={({ field, fieldState: { error, invalid } }) => (
              <TextField
                {...field}
                label={t("Note")}
                fullWidth
                margin="normal"
                helperText={error ? error.message : t("Enter a note.")}
                error={invalid}
              />
            )}
          />

          <ErrorAlert error={actionData?.error} />

          {/* Submit Button */}
          <Box display="flex" justifyContent="flex-end" gap={2} mt={3}>
            {data?.paymentId && (
              <Button
                variant="contained"
                color="error"
                size="large"
                onClick={onFormDelete}
              >
                {t("Delete")}
              </Button>
            )}
            <Button
              variant="contained"
              color="success"
              size="large"
              type="submit"
              disabled={!isDirty}
            >
              {t("Submit")}
            </Button>
          </Box>
        </Card>
      </Stack>
    </Container>
  );
}
