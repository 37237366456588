import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import IndexPageHeader from "shared/layouts/index/IndexPageHeader.react";
import IndexPageLayout from "shared/layouts/index/IndexPageLayout.react";
import IndexPageTable from "shared/layouts/index/IndexPageTable.react";
import Link from "@mui/material/Link";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import {
  useRegionalsQuery,
  ensureRegionalsQueryData,
} from "data/queries/regional/queryRegionals";

export async function loader() {
  return await ensureRegionalsQueryData();
}

export default function RegionalListPage() {
  const { t } = useTranslation();

  return (
    <IndexPageLayout
      useQuery={useRegionalsQuery}
      defaultSorting="+name"
      hasSearch={true}
      header={
        <IndexPageHeader
          heading={t("Regionals")}
          routeIndex="/regional/list"
          routeNew="/regional/new"
        />
      }
      table={
        <IndexPageTable
          headers={[
            { columnId: "name", label: t("Name") },
            { columnId: "pastor.name", label: t("Pastor") },
            { columnId: "sede.name", label: t("Sede") },
          ]}
          renderRow={({ id, name, pastor, sede }) => (
            <TableRow hover key={id} tabIndex={-1}>
              <TableCell>
                <Typography variant="subtitle2" noWrap>
                  <Link component={RouterLink} to={`/regional/${id}`}>
                    {name}
                  </Link>
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle2" noWrap>
                  {pastor ? pastor.name : t("No Pastor")}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle2" noWrap>
                  {sede ? sede.name : t("No Sede")}
                </Typography>
              </TableCell>
              <TableCell key="actions"></TableCell>
            </TableRow>
          )}
        />
      }
    />
  );
}
