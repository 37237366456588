import { useEBDClasseAvailableMembers } from "data/queries/queryEBDClasseAvailableMembers";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import Autocomplete from "@mui/material/Autocomplete";
import CongregationSelect from "shared/components/CongregationSelect/CongregationSelect.react";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ErrorAlert from "shared/components/ErrorState/ErrorAlert.react";

export default function EBDClasseForm({
  data,
  onSubmit,
  onDelete,
  breadcrumbs,
}) {
  const { t } = useTranslation();
  const [congregation, setCongregation] = useState(data?.congregation ?? null);
  const [teacher, setTeacher] = useState(data?.teacher ?? null);
  const [teacherQuery, setTeacherQuery] = useState(data?.teacherName ?? "");
  const [classe, setClasse] = useState(data?.classe);
  const [selectedMembers, setSelectedMembers] = useState(data?.members || []);
  const [memberInputValue, setMemberInputValue] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError(null); // Clear previous errors

    try {
      await onSubmit({
        congregation,
        teacher,
        classe,
        selectedMembers,
      });
    } catch (err) {
      setError({
        message: err.message || t("An error occurred while saving the class"),
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Container maxWidth="md">
      {breadcrumbs}
      <Card component="form" onSubmit={handleSubmit}>
        <Stack spacing={3} sx={{ p: 3 }}>
          <CongregationSelect
            required={true}
            initialValue={congregation}
            value={congregation}
            onChange={(newCongregation) => {
              setCongregation(newCongregation);
            }}
          />

          <AddMemberSelect
            congregationId={congregation?.id}
            required={true}
            label={t("Teacher")}
            value={teacher} // Pass full object
            onChange={(newTeacher) => {
              setTeacher(newTeacher);
            }}
            onInputChange={setTeacherQuery}
            inputValue={teacherQuery}
          />

          <FormControl required>
            <InputLabel>{t("Class")}</InputLabel>
            <Select value={classe} onChange={(e) => setClasse(e.target.value)}>
              <MenuItem value="CORDEIRINHOS_DE_JESUS">
                {t("CORDEIRINHOS_DE_JESUS")}
              </MenuItem>
              <MenuItem value="JOIAS_DE_CRISTO">
                {t("JOIAS_DE_CRISTO")}
              </MenuItem>
              <MenuItem value="HEROIS_DE_CRISTO">
                {t("HEROIS_DE_CRISTO")}
              </MenuItem>
              <MenuItem value="JUNIORES">{t("JUNIORES")}</MenuItem>
              <MenuItem value="ADOLESCENTES">{t("ADOLESCENTES")}</MenuItem>
              <MenuItem value="JOVENS">{t("JOVENS")}</MenuItem>
              <MenuItem value="ABRAAO">{t("ABRAAO")}</MenuItem>
              <MenuItem value="ESTER">{t("ESTER")}</MenuItem>
              <MenuItem value="NOVOS_CONVERTIDOS">
                {t("NOVOS_CONVERTIDOS")}
              </MenuItem>
            </Select>
          </FormControl>

          <Box>
            <Typography variant="subtitle1" gutterBottom>
              {t("Students")}
            </Typography>
            <AddMemberSelect
              congregationId={congregation?.id}
              value={null}
              inputValue={memberInputValue}
              onChange={(newValue) => {
                if (newValue) {
                  setSelectedMembers([...selectedMembers, newValue]);
                }
                setMemberInputValue("");
              }}
              onInputChange={(newValue) => setMemberInputValue(newValue)}
            />
            <List>
              {selectedMembers.map((member) => (
                <ListItem
                  key={member.id}
                  secondaryAction={
                    <IconButton
                      edge="end"
                      onClick={() =>
                        setSelectedMembers(
                          selectedMembers.filter((m) => m.id !== member.id),
                        )
                      }
                    >
                      <DeleteIcon />
                    </IconButton>
                  }
                >
                  <ListItemAvatar>
                    <Avatar>{member.name[0]}</Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={member.name} />
                </ListItem>
              ))}
            </List>
          </Box>

          <Stack spacing={2}>
            <Stack direction="row" spacing={2}>
              <Button
                startIcon={<AddIcon />}
                variant="contained"
                color="primary"
                size="large"
                disabled={isSubmitting}
                type="submit"
              >
                {isSubmitting ? t("Saving...") : t("Save")}
              </Button>

              {onDelete && (
                <Button
                  variant="outlined"
                  color="error"
                  size="large"
                  onClick={onDelete}
                >
                  {t("Delete")}
                </Button>
              )}
            </Stack>

            {error && <ErrorAlert error={error?.message} />}
          </Stack>
        </Stack>
      </Card>
    </Container>
  );
}

function AddMemberSelect({
  congregationId,
  onChange,
  onInputChange,
  required,
  label,
  value = null,
  inputValue = "",
}) {
  const { t } = useTranslation();
  const { data = [] } = useEBDClasseAvailableMembers(congregationId);

  return (
    <Autocomplete
      required={required}
      value={value}
      inputValue={inputValue}
      options={data}
      autoHighlight={true}
      getOptionLabel={(option) => option.name}
      onChange={(_event, newValue) => {
        onChange(newValue);
      }}
      onInputChange={(_event, newInputValue) => {
        onInputChange?.(newInputValue);
      }}
      renderInput={(params) => (
        <TextField {...params} label={label ?? t("Add a member")} />
      )}
    />
  );
}
