import { useTranslation } from "react-i18next";
import { Link as RouterLink, useParams } from "react-router-dom";
import {
  useEventRegistrationsQuery,
  ensureEventRegistrationsQueryData,
} from "data/queries/events/queryEventRegistrations";
import IndexPageHeader from "shared/layouts/index/IndexPageHeader.react";
import IndexPageLayout from "shared/layouts/index/IndexPageLayout.react";
import IndexPageTable from "shared/layouts/index/IndexPageTable.react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { IconButton, Stack } from "@mui/material";
import PersonAvatar from "shared/components/Avatar/Avatar.react";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { formatCurrency } from "utils/NumberUtils";
import moment from "moment";

export async function loader({ params: { id } }) {
  return await ensureEventRegistrationsQueryData({ eventId: id });
}

export default function EventManagementRegistrationsIndexPage() {
  const { t } = useTranslation();
  const { id: eventId } = useParams();

  return (
    <IndexPageLayout
      useQuery={useEventRegistrationsQuery}
      queryParams={{ eventId: eventId }}
      hasSearch={true}
      defaultSorting="+pessoa.name"
      header={
        <>
          <IndexPageHeader
            heading={t("Event Registrations")}
            routeIndex={`/event-management/${eventId}/registrations`}
          />
        </>
      }
      table={
        <IndexPageTable
          headers={[
            { columId: "pessoa.name", label: t("Name") },
            { columId: "pessoa.congregacao.name", label: t("Congregation") },
            {
              columId: "pessoa.congregacao.regional.name",
              label: t("Regional"),
            },
            { columId: "pessoa.email", label: t("Email") },
            { columId: "pessoa.phone", label: t("Phone") },
            { columId: "pessoa.birthDate", label: t("Birth Date") },
            { columId: "pessoa.totalPayments", label: t("Total Payments") },
            { label: t("Actions") },
          ]}
          renderRow={({ id, totalPayments, pessoa }) => (
            <TableRow hover key={id} tabIndex={-1}>
              <TableCell>
                <Link
                  component={RouterLink}
                  to={`/event-management/${eventId}/registrations/${id}`}
                >
                  <Stack spacing={2} direction="row" alignItems="center">
                    <PersonAvatar
                      name={pessoa?.name}
                      src={
                        pessoa?.photoId
                          ? `/api/v1/file/${pessoa?.photoId}?width=56&height=56`
                          : undefined
                      }
                    />
                    <Typography variant="subtitle2" noWrap>
                      {pessoa?.name}
                    </Typography>
                  </Stack>
                </Link>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle2" noWrap>
                  {pessoa?.congregacao?.name}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle2" noWrap>
                  {pessoa?.congregacao?.regional?.name}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle2" noWrap>
                  {pessoa?.email}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle2" noWrap>
                  {pessoa?.phone}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle2" noWrap>
                  {pessoa?.birthDate
                    ? moment(pessoa.birthDate).format("LL")
                    : t("Not provided")}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle2" noWrap>
                  {formatCurrency(totalPayments)}
                </Typography>
              </TableCell>
              <TableCell key="actions">
                <Typography variant="subtitle2" noWrap>
                  <Link
                    component={RouterLink}
                    to={`/event-management/${eventId}/registrations/${id}/payments`}
                  >
                    <IconButton>
                      <AttachMoneyIcon style={{ color: "blue" }} />
                    </IconButton>
                  </Link>
                </Typography>
              </TableCell>
            </TableRow>
          )}
          renderFooter={(queryData) => (
            <TableRow>
              <TableCell colSpan={5} />
              <TableCell>
                <Typography align="left" variant="subtitle2">
                  {t("Total")}
                </Typography>
              </TableCell>
              <TableCell align="left" colSpan={2}>
                <Typography align="left" variant="subtitle2">
                  {formatCurrency(
                    queryData?.data?.reduce(
                      (acc, { totalPayments }) => acc + totalPayments,
                      0,
                    ),
                  )}
                </Typography>
              </TableCell>
            </TableRow>
          )}
        />
      }
    />
  );
}
