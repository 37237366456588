import { DONATION_TYPES } from "shared/components/DonationTypeSelect/donationTypes";
import { useActionData } from "react-router-dom";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import Container from "@mui/material/Container";
import DeleteIcon from "@mui/icons-material/Delete";
import DonationTypeSelect from "shared/components/DonationTypeSelect/DonationTypeSelect.react";
import ErrorAlert from "shared/components/ErrorState/ErrorAlert.react";
import FormCard from "shared/components/FormCard/FormCard.react";
import formFocusOnError from "shared/utils/formFocusOnError";
import IconButton from "@mui/material/IconButton";
import isStringNullOrEmpty from "shared/utils/isStringNullOrEmpty";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import useTheme from "@mui/material/styles/useTheme";
import { formatCents, formatCurrency } from "utils/NumberUtils";
import { InputAdornment } from "@mui/material";

export default function PreDonationForm({
  breadcrumbs,
  onSubmit: onSubmitProp,
  error: errorProp,
}) {
  const { t } = useTranslation();
  const [rows, setRows] = useState([
    { id: Date.now(), tipo: "", value: "", note: "" },
  ]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const actionData = useActionData();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));
  const validRows = rows.filter(
    (row) => !isStringNullOrEmpty(row.tipo) && parseFloat(row.value) > 0,
  );

  const updateRow = (index, field, value) => {
    setRows((prev) => {
      const updatedRows = prev.map((row, i) =>
        i === index
          ? {
              ...row,
              [field]: field === "value" ? formatCents(value) : value,
            }
          : row,
      );

      return updatedRows;
    });
  };

  const deleteRow = (index) => {
    setRows(rows.filter((_, i) => i !== index));
  };

  const handleAddRow = () => {
    setRows((rows) => {
      return [...rows, { id: Date.now(), tipo: "", value: "", note: "" }];
    });
  };

  const total = rows.reduce(
    (acc, row) => acc + (parseFloat(row.value) || 0),
    0,
  );

  const onSubmit = (e) => {
    e.preventDefault();
    if (total <= 0) {
      return;
    }

    if (validRows.length === 0) {
      return;
    }

    for (const row of validRows) {
      if (row.tipo === DONATION_TYPES.OUTROS && isStringNullOrEmpty(row.note)) {
        formFocusOnError();
        return;
      }
    }

    setIsSubmitting(true);
    onSubmitProp({ rows: validRows }).finally(() => setIsSubmitting(false));
  };

  return (
    <>
      <Container>{breadcrumbs}</Container>
      <Container
        component="form"
        maxWidth="md"
        method="post"
        noValidate={true}
        onSubmit={onSubmit}
      >
        <Stack spacing={4}>
          <FormCard title={t("New Donation")}>
            {rows.map((row, index) => {
              const noteRequired = row.tipo === DONATION_TYPES.OUTROS;
              const noteError =
                noteRequired && isStringNullOrEmpty(row.note)
                  ? t("Note is required")
                  : null;

              return (
                <RowLayout
                  key={row.id}
                  isLargeScreen={isLargeScreen}
                  button={
                    index > 0 &&
                    (isLargeScreen ? (
                      <IconButton
                        onClick={() => deleteRow(index)}
                        color="error"
                      >
                        <DeleteIcon />
                      </IconButton>
                    ) : (
                      <Button
                        sx={{ flexGrow: 1, flexShrink: 1, width: "100%" }}
                        variant="contained"
                        startIcon={<DeleteIcon />}
                        color="error"
                        onClick={() => deleteRow(index)}
                      >
                        {t("Remove")}
                      </Button>
                    ))
                  }
                  slot1={
                    <DonationTypeSelect
                      fullWidth={true}
                      value={row.tipo}
                      onChange={(e) => updateRow(index, "tipo", e.target.value)}
                      sx={{ mb: [2, 1] }}
                    />
                  }
                  slot2={
                    <TextField
                      label={t("Amount")}
                      type="number"
                      fullWidth={true}
                      value={row.value}
                      onChange={(e) =>
                        updateRow(index, "value", e.target.value)
                      }
                      sx={{ mb: [2, 1] }}
                      slotProps={{
                        input: {
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        },
                      }}
                    />
                  }
                  slot3={
                    <TextField
                      label={t("Note")}
                      fullWidth={true}
                      value={row.note}
                      onChange={(e) => updateRow(index, "note", e.target.value)}
                      sx={{ mb: [2, 1] }}
                      required={noteRequired}
                      error={noteError != null}
                      helperText={noteError}
                    />
                  }
                />
              );
            })}
            <Button startIcon={<AddIcon />} onClick={handleAddRow}>
              {t("New row")}
            </Button>
          </FormCard>
          <ErrorAlert error={errorProp} />
          <ErrorAlert error={actionData?.error} />
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={1}
          >
            <Typography variant="button" sx={{ mr: 2 }}>
              {t("Total")}: {formatCurrency(total)}
            </Typography>
            <Button
              color="success"
              disabled={isSubmitting || validRows.length <= 0}
              size="large"
              startIcon={<CheckIcon />}
              type="submit"
              variant="contained"
            >
              {isSubmitting ? t("Submitting...") : t("Submit")}
            </Button>
          </Stack>
        </Stack>
      </Container>
    </>
  );
}

function RowLayout({ sx, isLargeScreen, slot1, slot2, slot3, button }) {
  return (
    <Stack
      spacing={{ xs: 0, md: 2 }}
      direction={isLargeScreen ? "row" : "column"}
      useFlexGap
      sx={{ flexGrow: 1, flexShrink: 1, justifyContent: "center", ...sx }}
    >
      {[slot1, slot2, slot3].map((child, i) => (
        <Box
          sx={{
            flexGrow: 1,
            flexShrink: 1,
            width: "100%",
          }}
        >
          {child}
        </Box>
      ))}

      <Box
        sx={{
          width: !isLargeScreen ? "100%" : "40px",
          flexGrow: 0,
          flexShrink: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {button}
      </Box>
    </Stack>
  );
}
