/**
 * This function presents an alert when an error happens. Useful in scenarios
 * where you can't handle the error UI properly, like receiving an error
 * in a request linked to a shared component you don't control the layout.
 */

import { useEffect, useRef } from "react";
import useKnownErrorMessage from "./useKnownErrorMessage";
import useSentryCaptureException from "./useSentryCaptureException";
import { useTranslation } from "react-i18next";

const FIVE_SECS = 5 * 1000;

export default function useErrorAlertEffect(error) {
  const { t } = useTranslation();
  const knownErrorMessage = useKnownErrorMessage(error);
  const alertCooldown = useRef(null);

  useSentryCaptureException(error);

  useEffect(() => {
    if (alertCooldown.current != null || error == null) {
      return;
    }

    let message;
    if (knownErrorMessage != null && knownErrorMessage[0] != null) {
      message =
        knownErrorMessage[0] +
        (knownErrorMessage[1] != null ? ". " + knownErrorMessage[1] : "");
    } else {
      message = t("Sorry, an unexpected error has occurred.");
    }

    alert(message);

    alertCooldown.current = window.setTimeout(() => {
      alertCooldown.current = null;
    }, FIVE_SECS);

    return () => {
      window.clearTimeout(alertCooldown.current);
      alertCooldown.current = null;
    };
  }, [error, knownErrorMessage, t]);
}
