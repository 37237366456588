import { Outlet, ScrollRestoration } from "react-router-dom";
import { useNavigate, useNavigation } from "react-router-dom";
import { useEffect } from "react";
import { useIsDesktop } from "shared/hooks/useResponsive";
import { useLocation } from "react-router-dom";
import { useState, useRef } from "react";
import {
  ensureLoggedUserQueryData,
  useLoggedUserData,
} from "data/queries/queryLoggedUser";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import GuestEditPage from "./guest/GuestEditPage.react";
import ViewportAppBar from "shared/layouts/viewport/ViewportAppBar.react";
import useTheme from "@mui/material/styles/useTheme";
import ViewportSideNav, {
  DRAWER_WIDTH,
} from "shared/layouts/viewport/ViewportSideNav.react";

const LS_COLLAPSED_KEY = "viewport-sidenav-collapsed";

export async function loader() {
  const user = await ensureLoggedUserQueryData();
  return { user };
}

export default function RootPage() {
  const loggedUser = useLoggedUserData();
  const hasToFinishRegistration = loggedUser?.requiredActions?.some(
    (x) => x === "FINISH_REGISTRATION",
  );

  return hasToFinishRegistration ? (
    <GuestEditPage data={loggedUser} />
  ) : (
    <Viewport />
  );
}

function Viewport() {
  const [isOpen, toggleDrawer] = useDrawerState();
  const isDesktop = useIsDesktop();
  const navigate = useNavigate();
  const { state: navigationState } = useNavigation();
  const theme = useTheme();
  const [showLoader, setShowLoader] = useState(navigationState === "loading");
  const loaderTimeoutRef = useRef();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const returnTo = urlParams.get("returnTo");
    if (returnTo) {
      navigate(returnTo);
    }
  }, [navigate]);

  // only show global loader indicator after 800 ms of inactivity.
  // in faster transitions, we don't need to bother the user
  useEffect(() => {
    const stop = () => {
      clearTimeout(loaderTimeoutRef.current);
      loaderTimeoutRef.current = null;
      setShowLoader(false);
    };

    if (navigationState === "loading") {
      loaderTimeoutRef.current =
        loaderTimeoutRef.current ??
        setTimeout(() => {
          setShowLoader(true);
          loaderTimeoutRef.current = null;
        }, 800);
    } else {
      stop();
    }

    return () => {
      stop();
    };
  }, [navigationState]);

  return (
    <Box sx={styles.root}>
      <ScrollRestoration />
      <ViewportAppBar drawerWidth={DRAWER_WIDTH} toggleDrawer={toggleDrawer} />
      <Box sx={styles.columns}>
        <ViewportSideNav
          drawerWidth={DRAWER_WIDTH}
          isOpen={isOpen}
          toggleDrawer={toggleDrawer}
        />
        <Box
          sx={[styles.main, isDesktop && isOpen && styles.mainDesktopOpen]}
          component="main"
        >
          <Outlet />
        </Box>
      </Box>
      {showLoader && (
        <LinearProgress
          sx={[styles.globalProgress, { zIndex: theme.zIndex.snackbar }]}
        />
      )}
    </Box>
  );
}

function useDrawerState() {
  const isDekstop = useIsDesktop();
  const location = useLocation();
  const diskState = localStorage.getItem(LS_COLLAPSED_KEY) == null;
  const [isDesktopOpen, setDesktopOpen] = useState(diskState);
  const [isMobileOpen, setMobileOpen] = useState(false);

  const toggleDesktopDrawer = () => {
    if (isDesktopOpen) {
      localStorage.setItem(LS_COLLAPSED_KEY, "1");
    } else {
      localStorage.removeItem(LS_COLLAPSED_KEY);
    }

    setDesktopOpen(!isDesktopOpen);
  };

  const toggleMobileDrawer = () => {
    setMobileOpen(!isMobileOpen);
  };

  // route changes should collapse sidenav for mobile
  useEffect(() => {
    setMobileOpen(false);
  }, [location]);

  return isDekstop
    ? [isDesktopOpen, toggleDesktopDrawer]
    : [isMobileOpen, toggleMobileDrawer];
}

const styles = {
  root: {
    height: "100%",
  },
  columns: {
    minHeight: "100%",
    display: "flex",
    flexDirection: ["column", null, "row"],
  },
  main: {
    display: "flex",
    flexGrow: 0,
    minHeight: "100%",
    flexDirection: "column",
    padding: "88px 0 16px 0",
    width: "100%",
  },
  mainDesktopOpen: {
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
  },
  globalProgress: {
    height: 5,
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
  },
};
