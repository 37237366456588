import queryClient from "data/queryClient";
import { useQuery } from "@tanstack/react-query";
import fetch from "data/fetch";

// Fetch function with filter support
function fetchCultoSecretarias(page = 0, filter = "", sort = "-data") {
  return fetch(
    `/v1/relatorio-culto-secretaria?size=100&index=${page}&q=${encodeURIComponent(filter)}&s=${sort}`,
  );
}

// Query Key Generator
const getQueryKey = (page = 0, filter = "", sort = "-data") => [
  "culto-secretarias",
  page,
  filter,
  sort,
];

// Custom hook for fetching Culto Secretarias with filter
export function useCultoSecretariasQuery({
  page = 0,
  filter = "",
  sort = "-data",
  ...other
}) {
  return useQuery({
    ...other,
    queryKey: getQueryKey(page, filter, sort),
    queryFn: () => fetchCultoSecretarias(page, filter, sort),
  });
}

// Ensure Query Data for Culto Secretarias
export function ensureCultoSecretariasQueryData({
  page = 0,
  filter = "",
  sort = "-data",
} = {}) {
  return queryClient.ensureQueryData({
    queryKey: getQueryKey(page, filter, sort),
    queryFn: () => fetchCultoSecretarias(page, filter, sort),
  });
}

// Invalidate Queries for Culto Secretarias
export function invalidateCultoSecretariasQuery() {
  return queryClient.invalidateQueries(["culto-secretarias"]);
}
