import fetch from "data/fetch";

export async function mutateRegional({ regionalId, name, pastorId, sedeId }) {
  const url = regionalId ? `/v1/regional/${regionalId}` : "/v1/regional";
  const method = regionalId ? "PUT" : "POST";

  const body = JSON.stringify({
    name,
    pastorId,
    sedeId,
  });

  try {
    await fetch(url, {
      method,
      headers: {
        "Content-Type": "application/json",
      },
      body,
    });
    return true;
  } catch {
    return false;
  }
}
