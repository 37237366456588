import queryClient from "data/queryClient";
import { useQuery } from "@tanstack/react-query";
import fetch from "data/fetch";

function fetchEvents(page = 0, filter = "", sort = "-startAt") {
  return fetch(
    `/v1/event?size=100&index=${page}&q=${encodeURIComponent(filter)}&s=${sort}`,
  );
}
export function fetchEvent(id) {
  return fetch(`/v1/event/${id}`);
}
const getQueryKey = (index = 0, name = "", sort = "-startAt") => [
  "event",
  index,
  name,
  sort,
];

export function useEventsQuery({
  page = 0,
  filter = "",
  sort = "-startAt",
  ...other
}) {
  return useQuery({
    ...other,
    queryKey: getQueryKey(page, filter, sort),
    queryFn: () => fetchEvents(page, filter, sort),
  });
}

export function ensureEventsQueryData({
  page = 0,
  filter = "",
  sort = "-startAt",
} = {}) {
  return queryClient.ensureQueryData({
    queryKey: getQueryKey(page, filter, sort),
    queryFn: () => fetchEvents(page, filter, sort),
  });
}

export function invalidateEventsQuery() {
  return queryClient.invalidateQueries(["event"]);
}
