import {
  ensureCaringGroupMembersQueryData,
  useCaringGroupMembersQuery,
} from "data/queries/queryCaringGroupMembers";
import {
  ensureCaringGroupQueryData,
  useCaringGroupQuery,
} from "data/queries/queryCaringGroup";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { ensureCaringGroupsQueryData } from "data/queries/queryCaringGroups";
import { ensureLoggedUserQueryData } from "data/queries/queryLoggedUser";
import { invalidateCaringGroupReportsQuery } from "data/queries/queryCaringGroupReports";
import { invalidateCaringGroupsReportsQuery } from "data/queries/queryCaringGroupsReports";
import { useCallback, useEffect, useId, useState } from "react";
import { useParams, useNavigate, useLoaderData } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import Breadcrumbs from "shared/components/Breadcrumbs/Breadcrumbs.react";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CaringGroupsSelect from "pages/caring_groups/components/CaringGroupsSelect.react";
import Container from "@mui/material/Container";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import ErrorAlert from "shared/components/ErrorState/ErrorAlert.react";
import fetch from "data/fetch";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import isStringNullOrEmpty from "shared/utils/isStringNullOrEmpty";
import MenuItem from "@mui/material/MenuItem";
import moment from "moment";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import useTheme from "@mui/material/styles/useTheme";

const Status = {
  PRESENTE: "PRESENTE",
  AUSENTE: "AUSENTE",
  TRABALHO: "TRABALHO",
  ENFERMIDADE: "ENFERMIDADE",
  VIAGEM: "VIAGEM",
};

// query if the current user is the leader for a caring group
async function findLoggedUserCaringGroup() {
  const user = await ensureLoggedUserQueryData();

  if (!user) {
    return;
  }

  const caringGroups =
    (await ensureCaringGroupsQueryData({ filter: user.name })) ?? {};
  const userCaringGroup = caringGroups?.data?.find(
    (cg) => cg.lider.id === user.id,
  );

  return userCaringGroup?.id;
}

export async function loader({ params: { id: routeCaringGroupId } }) {
  let caringGroupId = routeCaringGroupId;

  if (isStringNullOrEmpty(caringGroupId)) {
    caringGroupId = await findLoggedUserCaringGroup();

    if (caringGroupId == null) {
      return null;
    }
  }

  const data = await Promise.all([
    ensureCaringGroupQueryData(caringGroupId),
    ensureCaringGroupMembersQueryData(caringGroupId),
  ]);

  return data[0]?.data;
}

export async function action() {
  // TODO move form submit to action
}

export default function CaringGroupsReportsNewPage({ layout = "full-page" }) {
  const navigate = useNavigate();
  const { id: routeCaringGroupId } = useParams();
  const loaderCaringGroup = useLoaderData();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [reportDate, setReportDate] = useState(moment());
  const [memberData, setMemberData] = useState({});
  const [selectedCaringGroup, setSelectedCaringGroup] =
    useState(loaderCaringGroup);

  const { data: { data: caringGroup } = {} } = useCaringGroupQuery({
    id: selectedCaringGroup?.id,
  });
  const { data: { data: members } = {} } = useCaringGroupMembersQuery({
    id: caringGroup?.id,
  });

  const redirectURI = routeCaringGroupId
    ? `/caring_groups/${routeCaringGroupId}`
    : "/caring_groups_reports";

  const onMemberDataChange = (newMemberData) => {
    setMemberData({
      ...memberData,
      [newMemberData.relationId]: newMemberData,
    });
  };

  useEffect(() => {
    // we load a caring group that is different than the selected, sync them
    if (caringGroup != null && caringGroup.id !== selectedCaringGroup?.id) {
      setSelectedCaringGroup(caringGroup);
    }
  }, [caringGroup, selectedCaringGroup]);

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      setError(null);
      setIsSubmitting(true);

      try {
        await fetch(`/v1/caring-groups-relatorio`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            caringGroupId: caringGroup.id,
            dataFrequencia: moment(reportDate).format("YYYY-MM-DD"),
            relatorioRelations: Object.entries(memberData).map(
              ([relationId, row]) => ({
                caringGroupRelationId: relationId,
                situacao: row.status,
                comentario: row.comment,
              }),
            ),
          }),
        });

        invalidateCaringGroupReportsQuery(routeCaringGroupId);
        invalidateCaringGroupsReportsQuery();
        navigate(redirectURI);
      } catch (e) {
        setError(e);
        setIsSubmitting(false);
      }
    },
    [
      caringGroup,
      memberData,
      navigate,
      reportDate,
      redirectURI,
      routeCaringGroupId,
    ],
  );

  const form = (
    <Stack spacing={3} sx={{ mt: 1 }}>
      {routeCaringGroupId == null && (
        <CaringGroupsSelect
          value={selectedCaringGroup}
          onChange={setSelectedCaringGroup}
        />
      )}
      {caringGroup && (
        <>
          <ReportDateField value={reportDate} onChange={setReportDate} />
          {members?.map((m) => (
            <MemberRow
              key={m.id}
              name={m.name}
              memberData={
                memberData[m.relationId] ?? {
                  relationId: m.relationId,
                }
              }
              onMemberDataChange={onMemberDataChange}
            />
          ))}
        </>
      )}
      <ErrorAlert error={error} />
    </Stack>
  );

  return layout === "full-page" ? (
    <FullPageLayout
      caringGroup={caringGroup}
      isSubmitting={isSubmitting}
      leaderName={caringGroup?.lider?.name}
      onSubmit={onSubmit}
      routeCaringGroupId={routeCaringGroupId}
    >
      {form}
    </FullPageLayout>
  ) : (
    <DialogLayout
      caringGroup={caringGroup}
      isSubmitting={isSubmitting}
      onSubmit={onSubmit}
      redirectURI={redirectURI}
    >
      {form}
    </DialogLayout>
  );
}

function FullPageLayout({
  leaderName,
  routeCaringGroupId,
  children,
  isSubmitting,
  onSubmit,
}) {
  const { t } = useTranslation();

  return (
    <Container maxWidth="sm" component="form" method="post" onSubmit={onSubmit}>
      <Breadcrumbs
        heading={t("Caring Groups")}
        links={[
          {
            name: t("Caring Groups"),
            to: "/caring_groups",
          },
          {
            name: leaderName ?? "...",
            to: `/caring_groups/${routeCaringGroupId}`,
          },
          {
            name: t("New Report"),
          },
        ]}
        sx={{ mb: { xs: 3, md: 5 } }}
      />
      <Stack spacing={4}>
        <Card>
          <Stack spacing={3} sx={{ p: 3 }}>
            {children}
          </Stack>
        </Card>
        <Stack direction="row" justifyContent="flex-end" spacing={1}>
          <Button
            startIcon={<AddIcon />}
            variant="contained"
            color="success"
            size="large"
            disabled={isSubmitting}
            type="submit"
          >
            {isSubmitting ? t("Submitting...") : t("Submit")}
          </Button>
        </Stack>
      </Stack>
    </Container>
  );
}

function DialogLayout({ children, isSubmitting, onSubmit, redirectURI }) {
  const titleId = useId();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));

  const onClose = useCallback(() => {
    navigate(redirectURI);
  }, [navigate, redirectURI]);

  return (
    <Dialog
      aria-labelledby={titleId}
      component="form"
      fullScreen={!isLargeScreen}
      fullWidth={true}
      maxWidth={"sm"}
      onClose={onClose}
      onSubmit={onSubmit}
      open={true}
    >
      <DialogTitle id={titleId}>
        {t("Create a new caring group report")}
      </DialogTitle>
      <DialogContent sx={styles.dialogRoot}>{children}</DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t("Cancel")}</Button>
        <Button
          startIcon={<AddIcon />}
          variant="contained"
          color="success"
          disabled={isSubmitting}
          type="submit"
        >
          {isSubmitting ? t("Submitting...") : t("Submit")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const styles = {
  dialogRoot: {
    minWidth: {
      md: 500,
    },
  },
};

function ReportDateField({ value, onChange }) {
  const { t } = useTranslation();

  return (
    <DatePicker
      required={true}
      label={t("Report date") + " *"}
      value={value}
      onChange={onChange}
      disableFuture={true}
    />
  );
}

function MemberRow({ name, memberData, onMemberDataChange }) {
  const onStatusChange = (status) => {
    onMemberDataChange({
      ...memberData,
      status,
    });
  };

  const onCommentChange = (comment) => {
    onMemberDataChange({
      ...memberData,
      comment,
    });
  };

  return (
    <Stack spacing={2}>
      <Typography noWrap>{name}</Typography>
      <StatusField value={memberData.status} onChange={onStatusChange} />
      <CommentField value={memberData.comment} onChange={onCommentChange} />
    </Stack>
  );
}

function StatusField({ value, onChange }) {
  const { t } = useTranslation();

  return (
    <FormControl required={true}>
      <InputLabel>{t("Status")}</InputLabel>
      <Select value={value} onChange={changeHandler(onChange)}>
        <MenuItem value={Status.PRESENTE}>{t("Present")}</MenuItem>
        <MenuItem value={Status.AUSENTE}>{t("Absent")}</MenuItem>
        <MenuItem value={Status.TRABALHO}>{t("Work")}</MenuItem>
        <MenuItem value={Status.ENFERMIDADE}>{t("Sick")}</MenuItem>
        <MenuItem value={Status.VIAGEM}>{t("Travel")}</MenuItem>
      </Select>
    </FormControl>
  );
}

function CommentField({ value, onChange }) {
  const { t } = useTranslation();

  return (
    <TextField
      label={t("Comment")}
      value={value}
      onChange={changeHandler(onChange)}
    />
  );
}

function changeHandler(setValue) {
  return (e) => {
    setValue((e.currentTarget ?? e.target).value);
  };
}
