import { useState } from "react";
import {
  ensureEventsQueryData,
  invalidateEventsQuery,
} from "data/queries/events/queryEvents";
import Breadcrumbs from "shared/components/Breadcrumbs/Breadcrumbs.react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { mutateEvent } from "data/mutations/mutateEvent";
import EventManagementForm from "./component/EventManagementForm.react";

export async function loader() {
  await ensureEventsQueryData();
  return null;
}

export async function action() {
  // TODO move form submit to action
}

export default function EventManagementNewPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [error, setError] = useState(null);

  const onSubmit = async (formData) => {
    try {
      if (!formData?.congregacao?.id) {
        throw new Error(t("Congregation is required"));
      }

      const res = await mutateEvent({
        congregacaoId: formData.congregacao.id,
        ...formData,
      });

      if (!res) {
        throw new Error("Error submitting the form");
      }

      invalidateEventsQuery();
      navigate("/event-management");
    } catch (e) {
      setError(e.message ?? "Sorry, an unexpected error has occurred.");
      return;
    }
  };

  return (
    <EventManagementForm
      onSubmit={onSubmit}
      error={error}
      breadcrumbs={
        <Breadcrumbs
          heading={t("Create a new Event")}
          links={[
            {
              name: t("Events"),
              to: "/event-management",
            },
            { name: t("New") },
          ]}
          sx={{ mb: { xs: 3, md: 5 } }}
        />
      }
    />
  );
}
