import { useTranslation } from "react-i18next";
import FormCard from "shared/components/FormCard/FormCard.react";
import MenuItem from "@mui/material/MenuItem";
import isStringNullOrEmpty from "shared/utils/isStringNullOrEmpty";
import { forwardRef, useImperativeHandle } from "react";
import SelectField from "shared/components/SelectField/SelectField";

export const MemberStatus = {
  ACTIVE: "ATIVO",
  INACTIVE: "INATIVO",
  DECEASED: "FALECIDO",
};

const MemberFormStatusInfoCard = forwardRef(function MemberFormStatusInfoCard(
  { formErrors, memberStatus, memberType, onMemberTypeChange, onStatusChange },
  ref,
) {
  const { t } = useTranslation();

  useImperativeHandle(
    ref,
    () => ({
      validate(formErrors) {
        if (isStringNullOrEmpty(memberType)) {
          formErrors.memberType = t("Member type is required");
        }

        if (isStringNullOrEmpty(memberStatus)) {
          formErrors.memberStatus = t("Status is required");
        }
      },
    }),
    [memberType, memberStatus, t],
  );

  return (
    <FormCard title={t("Member Status")}>
      <SelectField
        error={!!formErrors.memberType}
        helperText={formErrors.memberType}
        label={t("Type")}
        onChange={onMemberTypeChange}
        required={true}
        value={memberType}
      >
        <MenuItem value="CONVIDADO">{t("CONVIDADO")}</MenuItem>
        <MenuItem value="CONGREGADO">{t("CONGREGADO")}</MenuItem>
        <MenuItem value="MEMBRO">{t("MEMBRO")}</MenuItem>
        <MenuItem value="COOPERADOR">{t("COOPERADOR")}</MenuItem>
        <MenuItem value="LIDER">{t("LIDER")}</MenuItem>
        <MenuItem value="DIACONO">{t("DIACONO")}</MenuItem>
        <MenuItem value="PRESBITERO">{t("PRESBITERO")}</MenuItem>
        <MenuItem value="EVANGELISTA">{t("EVANGELISTA")}</MenuItem>
        <MenuItem value="PASTOR">{t("PASTOR")}</MenuItem>
      </SelectField>

      <SelectField
        error={!!formErrors.memberStatus}
        helperText={formErrors.memberStatus}
        label={t("Status")}
        onChange={onStatusChange}
        required={true}
        value={memberStatus}
      >
        <MenuItem value={MemberStatus.ACTIVE}>{t("Active")}</MenuItem>
        <MenuItem value={MemberStatus.INACTIVE}>{t("Inactive")}</MenuItem>
        <MenuItem value={MemberStatus.DECEASED}>{t("Deceased")}</MenuItem>
      </SelectField>
    </FormCard>
  );
});

export default MemberFormStatusInfoCard;
