import React from "react";
import { Container, Card, Stack, Typography, Box, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import DOMPurify from "dompurify";
import { useNavigate } from "react-router-dom";

export default function EventView({ onPay, data, breadcrumbs }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const RegisterButton = () =>
    data.registrationEnabled && (
      <Box display="flex" justifyContent="flex-end" gap={2} mt={3}>
        <Button
          variant="contained"
          color="success"
          size="large"
          type="button"
          onClick={() =>
            data.alreadyRegistered
              ? navigate(`/next-event/${data.eventId}/registration/view`)
              : navigate(`/next-event/${data.eventId}/registration`)
          }
        >
          {data.alreadyRegistered ? t("View Registration") : t("Register")}
        </Button>
        {data.alreadyRegistered && data.paymentEnabled && onPay && (
          <Button
            variant="contained"
            color="primary"
            size="large"
            type="button"
            onClick={onPay}
          >
            {t("Pay")}
          </Button>
        )}
      </Box>
    );

  return (
    <Container>
      {breadcrumbs}
      <Stack spacing={3} sx={{ p: 3 }}>
        <Card sx={{ p: 3 }}>
          <Typography variant="h1" gutterBottom>
            {data.name}
          </Typography>
          <Typography variant="h4" gutterBottom>
            {dayjs(data.startAt).format("DD MMM YYYY HH:mm")} -{" "}
            {dayjs(data.endAt).format("DD MMM YYYY HH:mm")}
          </Typography>
          <Typography variant="h4" gutterBottom>
            {data.congregacao.name} - {data.congregacao.regional.name}
          </Typography>

          <div
            className="prose"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(data.description),
            }}
          />
          <RegisterButton />
        </Card>
      </Stack>
    </Container>
  );
}
