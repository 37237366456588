import fetch from "data/fetch";
import queryClient from "data/queryClient";
import { useQuery } from "@tanstack/react-query";

function fetchCaringGroupMembers(id) {
  return Promise.all([
    fetch(`/v1/caring-groups/${id}/membros`),
    fetch(`/v1/caring-groups-relation`),
  ]).then(([{ data: members }, { data: allRelations }]) => {
    // TODO: this is hacky... loading all relations and finding things on the
    // client-side
    const relationsForThisCaringGroup = allRelations.filter(
      (x) => x.caringGroup.id === id,
    );

    const findMemberRelation = (memberId) =>
      relationsForThisCaringGroup.find((x) => x.pessoa.id === memberId);

    return {
      data: members.map((m, i) => ({
        ...m,
        relationId: findMemberRelation(m.id).id,
      })),
    };
  });
}

const getQueryKey = (id) => ["caring-groups", id, "members"];

export function useCaringGroupMembersQuery({ id, ...other }) {
  return useQuery({
    ...other,
    queryKey: getQueryKey(id),
    queryFn: () => fetchCaringGroupMembers(id),
    enabled: id != null,
  });
}

export function ensureCaringGroupMembersQueryData(id) {
  return id == null
    ? null
    : queryClient.ensureQueryData({
        queryKey: getQueryKey(id),
        queryFn: () => fetchCaringGroupMembers(id),
      });
}

export function invalidateCaringGroupMembersQuery(id) {
  return queryClient.invalidateQueries(["caring-groups", id, "members"]);
}
