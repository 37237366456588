import { useState } from "react";
import PreDonationForm from "./PreDonationForm.react";
import { mutatePreDonation } from "data/mutations/mutatePreDonation";

export async function loader() {
  // No data to load
  return {};
}

export default function PreDonationNewPage() {
  const [error, setError] = useState(null);

  const onSubmit = async (formData) => {
    try {
      const res = await mutatePreDonation({
        values: formData.rows,
      });

      if (!res.success) {
        throw new Error("Error submitting the form");
      }

      window.location.href = res.data.paymentLink;
    } catch (e) {
      setError(e);
      return;
    }
  };

  return <PreDonationForm data={{}} onSubmit={onSubmit} error={error} />;
}
