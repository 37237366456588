import Container from "@mui/material/Container";
import IndexPageCard from "./IndexPageCard.react";

export default function IndexPageLayout({
  additionalFilters,
  defaultSorting,
  hasSearch,
  header,
  queryParams = {},
  table,
  useQuery,
}) {
  return (
    <Container>
      {header}
      <IndexPageCard
        additionalFilters={additionalFilters}
        defaultSorting={defaultSorting}
        hasSearch={hasSearch}
        header={header}
        queryParams={queryParams}
        table={table}
        useQuery={useQuery}
      />
    </Container>
  );
}
