import React, { useCallback, useRef, useState } from "react";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import MemberCredentialCard, {
  validate,
} from "./components/MemberCredentialCard.react";
import { ensureCongregationQueryData } from "data/queries/queryCongregations";
import CongregationSelect from "shared/components/CongregationSelect/CongregationSelect.react";
import { useCongregationPeopleQuery } from "data/queries/queryCongregationPeople";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import Skeleton from "@mui/material/Skeleton";
import ListItemText from "@mui/material/ListItemText";
import { useTranslation } from "react-i18next";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Stack from "@mui/material/Stack";

export async function loader({ params: { id } }) {
  await ensureCongregationQueryData();
  return null;
}

export default function MembersCredentialsPage() {
  const { t } = useTranslation();
  const [shouldGenerate, setShouldGenerate] = useState(false);
  const [congregation, setCongregation] = useState(null);
  const memberRefs = useRef({});

  // TODO: trocar essa API por uma nova que retorna mais dados dos membros
  const { data, isLoading } = useCongregationPeopleQuery(
    congregation?.id,
    true,
  );

  const onGenerateCredentials = useCallback(() => {
    if (congregation == null || data == null || data.length === 0) {
      return;
    }

    setShouldGenerate(true);
  }, [congregation, data]);

  const onCongregationChange = useCallback((value) => {
    setShouldGenerate(false);
    setCongregation(value);
  }, []);

  const onDownloadAsPDFClick = useCallback(() => {
    if (memberRefs.current != null) {
      for (const k in memberRefs.current) {
        const canvasRef = memberRefs.current[k];
        canvasRef.current?.downloadAsPDF();
      }
    }
  }, []);

  return (
    <Container sx={{ display: "flex", gap: 2 }}>
      <Card sx={{ maxWidth: 350, flex: 1 }}>
        <CardContent>
          <CongregationSelect onChange={onCongregationChange} />
          <List>
            {isLoading
              ? [...Array(3)].map((_, index) => (
                  <ListItem key={index}>
                    <Skeleton variant="text" sx={{ flex: 1 }} height={24} />
                  </ListItem>
                ))
              : data?.map((member, i) => (
                  <React.Fragment key={i}>
                    <ListItem sx={{ gap: 1 }}>
                      <ListItemText primary={member.name} />
                    </ListItem>
                    {i !== data.length - 1 && <Divider component="li" />}
                  </React.Fragment>
                ))}
          </List>
        </CardContent>
      </Card>

      <Card sx={{ flex: 1 }}>
        <CardContent>
          {shouldGenerate === false && (
            <Button
              variant="contained"
              color="primary"
              onClick={onGenerateCredentials}
              disabled={
                congregation == null || data == null || data.length === 0
              }
            >
              {t("Generate Credentials")}
            </Button>
          )}

          <Stack direction="column" usFlexGap={true} spacing={1}>
            {shouldGenerate === true && (
              <>
                <div>
                  <Button variant="contained" onClick={onDownloadAsPDFClick}>
                    Download PDF
                  </Button>
                </div>
                {data?.map((member) => {
                  const issues = validate(member, t);

                  if (!memberRefs.current[member.id]) {
                    memberRefs.current[member.id] = React.createRef();
                  }

                  return (
                    <React.Fragment key={member.id}>
                      <Typography variant="h6" gutterBottom>
                        {member.name}
                      </Typography>

                      {issues.length > 0 ? (
                        <Alert severity="error">
                          <AlertTitle>
                            {t("This record is incomplete")}
                          </AlertTitle>
                          <Stack
                            direction="column"
                            usFlexGap={true}
                            spacing={1}
                            component="ul"
                          >
                            {issues.map((issue, i) => (
                              <li key={i}>{issue}</li>
                            ))}
                          </Stack>
                        </Alert>
                      ) : (
                        <MemberCredentialCard
                          key={member.id}
                          member={member}
                          ref={memberRefs.current[member.id]}
                        />
                      )}
                    </React.Fragment>
                  );
                })}
              </>
            )}
          </Stack>
        </CardContent>
      </Card>
    </Container>
  );
}
