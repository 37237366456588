import { useState } from "react";
import Breadcrumbs from "shared/components/Breadcrumbs/Breadcrumbs.react";
import { useTranslation } from "react-i18next";
import { useLoaderData, useNavigate, useParams } from "react-router-dom";
import {
  fetchEventRegistrationPayment,
  invalidateEventRegistrationPaymentsQuery,
} from "data/queries/events/queryEventRegistrationPayments";
import EventManagementRegistrationPaymentForm from "./component/EventManagementRegistrationPaymentForm.react";
import {
  deleteEventRegistrationPayment,
  updateEventRegistrationPayment,
} from "data/mutations/mutateEventRegistrationPayment";
import { invalidateEventRegistrationsQuery } from "data/queries/events/queryEventRegistrations";

export async function loader({ params: { id, registrationId, paymentId } }) {
  const { data: payment } = await fetchEventRegistrationPayment(paymentId);
  return {
    eventId: id,
    registrationId,
    paymentId: payment?.id,
    ...payment,
  };
}

export async function action() {
  // TODO move form submit to action
}

export default function EventManagementRegistrationPaymentEditPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const { id: eventId, registrationId, paymentId } = useParams();
  const data = useLoaderData();

  const onSubmit = async (formData) => {
    try {
      const res = await updateEventRegistrationPayment({
        paymentId: paymentId,
        ...formData,
      });

      if (!res) {
        throw new Error("Error submitting the form");
      }

      invalidateEventRegistrationPaymentsQuery();
      invalidateEventRegistrationsQuery();

      navigate(
        `/event-management/${eventId}/registrations/${registrationId}/payments`,
      );
    } catch (e) {
      setError(e.message ?? "Sorry, an unexpected error has occurred.");
      return;
    }
  };

  const onDelete = async () => {
    try {
      const res = await deleteEventRegistrationPayment(paymentId);

      if (!res) {
        throw new Error("Error deleting the payment");
      }

      invalidateEventRegistrationPaymentsQuery();
      invalidateEventRegistrationsQuery();
      navigate(
        `/event-management/${eventId}/registrations/${registrationId}/payments`,
      );
    } catch (e) {
      setError(e.message ?? "Sorry, an unexpected error has occurred.");
      return;
    }
  };

  return (
    <EventManagementRegistrationPaymentForm
      data={data}
      onSubmit={onSubmit}
      onDelete={onDelete}
      error={error}
      breadcrumbs={
        <Breadcrumbs
          heading={
            t("Edit a payment") + ` - ${data?.registration?.pessoa?.nome}`
          }
          links={[
            {
              name: t("Events"),
              to: "/event-management",
            },
            {
              name: t("Registrations"),
              to: `/event-management/${eventId}/registrations`,
            },
            {
              name: t("Payments"),
              to: `/event-management/${eventId}/registrations/${registrationId}/payments`,
            },
            { name: t("Edit") },
          ]}
          sx={{ mb: { xs: 3, md: 5 } }}
        />
      }
    />
  );
}
