import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useKnownErrorMessage from "./useKnownErrorMessage";
import useSentryCaptureException from "./useSentryCaptureException";

export default function ErrorPage({ error }) {
  const { t } = useTranslation();
  const knownErrorMessage = useKnownErrorMessage(error);
  const sentryId = useSentryCaptureException(error);

  if (error == null || error === "") {
    return null;
  }

  return (
    <Stack
      spacing={2}
      justifyContent="center"
      alignItems="center"
      sx={styles.root}
    >
      <Typography variant="h1">{t("Oops!")}</Typography>
      {knownErrorMessage[0] != null ? (
        <>
          <Typography variant="body1">{knownErrorMessage[0]}</Typography>
          {knownErrorMessage[1] != null && (
            <Typography variant="body2">{knownErrorMessage[1]}</Typography>
          )}
        </>
      ) : (
        <>
          <Typography variant="subtitle1">
            {t("Sorry, an unexpected error has occurred.")}
          </Typography>

          <Box component="pre" sx={styles.code}>
            <b>{t("Technical Information")}</b>
            {"\n"}
            Sentry ID: {sentryId}
            {"\n"}
            {error.stack}
          </Box>
        </>
      )}
    </Stack>
  );
}

const styles = {
  root: {
    height: "100%",
    padding: "16px",
  },
  code: {
    bgcolor: "grey.100",
    border: 1,
    borderColor: "grey.200",
    fontSize: 11,
    maxWidth: "100%",
    padding: 1,
    whiteSpace: "pre-wrap",
  },
};
