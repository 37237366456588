import { useState } from "react";
import { useTranslation } from "react-i18next";
import { redirect, Link as RouterLink } from "react-router-dom";
import {
  ensureNextEventsQueryData,
  useNextEventsQuery,
} from "data/queries/events/queryNextEvents";
import { ensureLoggedUserQueryData } from "data/queries/queryLoggedUser";
import {
  CardContent,
  CardActions,
  Typography,
  Button,
  Box,
  Paper,
  Link,
  CardHeader,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { motion } from "framer-motion";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";

dayjs.extend(duration);

export async function loader() {
  const user = await ensureLoggedUserQueryData();

  const hasToFinishRegistration = user?.requiredActions?.some(
    (x) => x === "FINISH_REGISTRATION",
  );

  if (hasToFinishRegistration) {
    return redirect(`/?returnTo=next-event`);
  }

  return await ensureNextEventsQueryData();
}

export default function EventManagementIndexPage() {
  const { t } = useTranslation();
  const queryParams = {};
  const [page] = useState(0);
  const [filter] = useState("");
  const [additionalFilterValues] = useState({});

  const { data: queryData } = useNextEventsQuery({
    ...queryParams,
    page: page,
    filter,
    ...additionalFilterValues,
    keepPreviousData: true,
  });

  return (
    <Box sx={{ padding: 3 }}>
      {/* Page Header */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 3,
        }}
      >
        <Typography variant="h4">{t("Events")}</Typography>
      </Box>

      {/* Cards Grid */}
      <Grid container spacing={3}>
        {queryData?.data?.length === 0 && (
          <Typography variant="h6" sx={{ color: "text.secondary" }}>
            {t("No events availables")}
          </Typography>
        )}

        {queryData?.data?.map(
          ({ id, name, shortDescription, congregacao, startAt }) => (
            <Grid item xs={12} sm={6} md={4} key={id}>
              <motion.div
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                transition={{ duration: 0.2 }}
              >
                <Paper
                  elevation={3}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    minWidth: 345,
                    maxWidth: 345,
                    height: 320,
                    overflow: "hidden",
                    p: 2,
                    transition: "all 0.3s",
                    "&:hover": {
                      boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.1)",
                    },
                  }}
                >
                  <CardHeader
                    title={name}
                    subheader={
                      <>
                        <Typography variant="body2" color="text.secondary">
                          {congregacao.name} - {congregacao.regional.name}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {dayjs(startAt).format("DD MMM YYYY")}
                        </Typography>
                      </>
                    }
                  />

                  <CardContent sx={{ flexGrow: 1, overflow: "hidden" }}>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        WebkitLineClamp: 5,
                        WebkitBoxOrient: "vertical",
                      }}
                    >
                      {shortDescription}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Link component={RouterLink} to={`/next-event/${id}`}>
                      <Button size="small">{t("Learn More")}</Button>
                    </Link>
                  </CardActions>
                </Paper>
              </motion.div>
            </Grid>
          ),
        )}
      </Grid>
    </Box>
  );
}
