import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useActionData, Link } from "react-router-dom";
import Alert from "@mui/material/Alert";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import PastorSelect from "shared/components/PastorSelect/PastorSelect.react";
import CongregationSelect from "shared/components/CongregationSelect/CongregationSelect.react";
import FormCard from "shared/components/FormCard/FormCard.react";
import IconButton from "@mui/material/IconButton";
import SettingsIcon from "@mui/icons-material/Settings";
import Grid from "@mui/material/Grid2";

export default function RegionalForm({
  breadcrumbs,
  data,
  onSubmit: onSubmitProp,
  onDelete: onDeleteProp,
  error: errorProp,
}) {
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [name, setName] = useState(data?.name ?? "");
  const [pastorId, setPastorId] = useState(data?.pastorId ?? null);
  const [sedeId, setSedeId] = useState(data?.sede?.id ?? null);
  const actionData = useActionData();

  const onSubmit = (e) => {
    e.preventDefault();

    setIsSubmitting(true);

    onSubmitProp({
      name,
      pastorId: typeof pastorId === "object" ? pastorId.id : pastorId,
      sedeId: typeof sedeId === "object" ? sedeId.id : sedeId,
    }).finally(() => {
      setIsSubmitting(false);
    });
  };

  const onDelete = () => {
    if (!window.confirm(t("Do you confirm the deletion?"))) {
      return false;
    }

    setIsDeleting(true);

    onDeleteProp().finally(() => {
      setIsDeleting(false);
    });
  };

  return (
    <Container component="form" method="post" onSubmit={onSubmit}>
      <Grid container spacing={2}>
        <Grid size={8}>{breadcrumbs}</Grid>
        <Grid size={4}>
          {data?.regionalId && (
            <Link
              to={`/regional/${data.regionalId}/config`}
              style={{ float: "right" }}
            >
              <IconButton aria-label="settions">
                <SettingsIcon />
              </IconButton>
            </Link>
          )}
        </Grid>
      </Grid>

      <Stack spacing={3} sx={{ p: 3 }}>
        <FormCard>
          <TextField
            label={t("Name")}
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <PastorSelect
            value={pastorId}
            onChange={(e) => setPastorId(e.target.value)}
            required
          />
          <CongregationSelect
            initialValue={data?.sede}
            onChange={setSedeId}
            required
            label={t("Sede")}
          />
          {errorProp && <Alert severity="error">{t(errorProp)}</Alert>}
          {actionData && actionData.error && (
            <Alert severity="error">{t(actionData.error)}</Alert>
          )}
          <Stack direction="row" justifyContent="flex-end" spacing={1}>
            {data?.regionalId && onDeleteProp && (
              <Button
                startIcon={<AddIcon />}
                variant="outlined"
                color="error"
                size="large"
                disabled={isDeleting}
                onClick={onDelete}
              >
                {isDeleting ? t("Deleting...") : t("Delete")}
              </Button>
            )}
            <Button
              startIcon={<AddIcon />}
              variant="contained"
              color="success"
              size="large"
              disabled={isSubmitting}
              type="submit"
            >
              {isSubmitting ? t("Submitting...") : t("Submit")}
            </Button>
          </Stack>
        </FormCard>
      </Stack>
    </Container>
  );
}
