import { useState } from "react";
import Breadcrumbs from "shared/components/Breadcrumbs/Breadcrumbs.react";
import { useTranslation } from "react-i18next";
import { useLoaderData, useNavigate, useParams } from "react-router-dom";
import {
  fetchEventRegistration,
  invalidateEventRegistrationsQuery,
} from "data/queries/events/queryEventRegistrations";
import { invalidateEventRegistrationPaymentsQuery } from "data/queries/events/queryEventRegistrationPayments";
import EventManagementRegistrationPaymentForm from "./component/EventManagementRegistrationPaymentForm.react";
import { createEventRegistrationPayment } from "data/mutations/mutateEventRegistrationPayment";

export async function loader({ params: { id, registrationId } }) {
  const { data: registration } = await fetchEventRegistration(registrationId);

  return {
    eventId: id,
    registrationId,
    registration,
  };
}

export async function action() {
  // TODO move form submit to action
}

export default function EventManagementRegistrationPaymentNewPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const { id: eventId, registrationId } = useParams();
  const data = useLoaderData();

  const onSubmit = async (formData) => {
    try {
      const res = await createEventRegistrationPayment({
        registrationId: registrationId,
        ...formData,
      });

      if (!res) {
        throw new Error("Error submitting the form");
      }

      invalidateEventRegistrationPaymentsQuery();
      invalidateEventRegistrationsQuery();
      navigate(
        `/event-management/${eventId}/registrations/${registrationId}/payments`,
      );
    } catch (e) {
      setError(e.message ?? "Sorry, an unexpected error has occurred.");
      return;
    }
  };

  return (
    <EventManagementRegistrationPaymentForm
      data={data}
      onSubmit={onSubmit}
      error={error}
      breadcrumbs={
        <Breadcrumbs
          heading={
            t("Add a new payment") + ` - ${data?.registration?.pessoa?.nome}`
          }
          links={[
            {
              name: t("Events"),
              to: "/event-management",
            },
            {
              name: t("Registrations"),
              to: `/event-management/${eventId}/registrations`,
            },
            {
              name: t("Payments"),
              to: `/event-management/${eventId}/registrations/${registrationId}/payments`,
            },
            { name: t("New") },
          ]}
          sx={{ mb: { xs: 3, md: 5 } }}
        />
      }
    />
  );
}
