import { useCaringGroupAvailableMembers } from "data/queries/queryCaringGroupAvailableMembers";
import { useLoggedUserData } from "data/queries/queryLoggedUser";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { redirect, useActionData } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import Autocomplete from "@mui/material/Autocomplete";
import CongregationSelect from "shared/components/CongregationSelect/CongregationSelect.react";
import Button from "@mui/material/Button";
import CongregationLeadersSelect from "shared/components/CongregationLeadersSelect/CongregationLeadersSelect.react";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { mutateCaringGroup } from "data/mutations/mutateCaringGroup";
import DepartmentSelect from "shared/components/DepartmentSelect/DepartmentSelect.react";
import ErrorAlert from "shared/components/ErrorState/ErrorAlert.react";
import FormCard from "shared/components/FormCard/FormCard.react";
import isStringNullOrEmpty from "shared/utils/isStringNullOrEmpty";
import CheckIcon from "@mui/icons-material/Check";

export async function action({ request }) {
  const formData = await request.formData();
  const success = await mutateCaringGroup(
    formData.get("leaderid"),
    formData.get("memberids").split(","),
  );

  if (success) {
    return redirect("/caring_groups");
  }

  return {
    error: "Sorry, an unexpected error has occurred.",
  };
}

export default function CaringGroupsForm({
  breadcrumbs,
  data,
  onSubmit: onSubmitProp,
  onDelete: onDeleteProp,
  error: errorProp,
}) {
  const user = useLoggedUserData();
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const congregationName = data?.congregationName ?? user?.congregation_name;
  const [congregationId, setCongregationId] = useState(
    data?.congregationId ?? user?.congregation_id ?? null,
  );
  const [leaderQuery, setLeaderQuery] = useState(data?.leaderName ?? "");
  const [leaderId, setLeader] = useState(data?.leaderId ?? null);
  const [memberQuery, setMemberQuery] = useState("");
  const [selectedMembers, setSelectedMembers] = useState(data?.relations ?? []);
  const [errorMessage, setErrorMessage] = useState(null);
  const [department, setDepartment] = useState(data?.departamento ?? null);
  const actionData = useActionData();
  const congregationIsEmpty = isStringNullOrEmpty(congregationId);

  const onCongregationChange = (newCongregation) => {
    if (newCongregation?.id !== congregationId) {
      setLeaderQuery("");
      setLeader(null);
      setMemberQuery("");
      setSelectedMembers([]);
      setCongregationId(newCongregation?.id ?? null);
    }
  };

  const onMemberChange = (newMember) => {
    setMemberQuery("");
    setErrorMessage(null);

    if (selectedMembers.find((m) => m.memberId === newMember.id) == null) {
      setSelectedMembers([
        ...selectedMembers,
        {
          memberId: newMember.id,
          memberName: newMember.name,
        },
      ]);
    }
  };

  const onRemoveMember = (member) => {
    setErrorMessage(null);
    setSelectedMembers(
      selectedMembers.filter((m) => {
        if (m.memberId !== member.memberId) {
          return true;
        } else if (m.relationId) {
          m.deleted = true;
          return true;
        }

        return false;
      }),
    );
  };

  const onRestoreMember = (member) => {
    setErrorMessage(null);

    const record = selectedMembers.find((m) => m.memberId === member.memberId);
    record.deleted = false;

    // force refresh
    setSelectedMembers([...selectedMembers]);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setErrorMessage(null);

    if (leaderId == null) {
      setErrorMessage(t("Leader is required"));
      return;
    }

    if (
      selectedMembers.length === 0 ||
      selectedMembers.every((m) => m.deleted)
    ) {
      setErrorMessage(t("Select at least one member."));
      return;
    }

    setIsSubmitting(true);

    onSubmitProp({
      congregationId,
      leaderId: typeof leaderId === "object" ? leaderId.id : leaderId,
      selectedMembers,
      department,
    }).finally(() => {
      setIsSubmitting(false);
    });
  };

  const onDelete = () => {
    if (!window.confirm(t("Do you confirm the deletion?"))) {
      return false;
    }

    setIsDeleting(true);

    onDeleteProp({
      selectedMembers,
    }).finally(() => {
      setIsDeleting(false);
    });
  };

  // re-mount the form fields when initial state is ready
  const initialLoadKey = data != null ? "kDataReady" : "kNoData";

  return (
    <>
      <Container>{breadcrumbs}</Container>
      <Container
        component="form"
        key={initialLoadKey}
        maxWidth="sm"
        method="post"
        noValidate={true}
        onSubmit={onSubmit}
      >
        <Stack spacing={4}>
          <FormCard>
            {data?.congregationId ? (
              <TextField
                label={t("Congregation")}
                value={congregationName}
                disabled={true}
              />
            ) : (
              <CongregationSelect
                required={true}
                onChange={(value) => onCongregationChange(value)}
              />
            )}
            {data?.leaderId ? (
              <TextField
                label={t("Leader")}
                value={data.leaderName}
                disabled={true}
              />
            ) : (
              <CongregationLeadersSelect
                congregationId={congregationId}
                onChange={setLeader}
                onInputChange={setLeaderQuery}
                value={leaderId}
                inputValue={leaderQuery}
                disabled={congregationIsEmpty}
              />
            )}
            {data?.departamento ? (
              <TextField
                label={t("Department")}
                value={data.departamento}
                disabled={true}
              />
            ) : (
              <DepartmentSelect
                value={department}
                disabled={congregationIsEmpty}
                onChange={(e) =>
                  setDepartment((e.currentTarget ?? e.target).value)
                }
              />
            )}
            <AddMemberSelect
              congregationId={congregationId}
              onChange={onMemberChange}
              onInputChange={setMemberQuery}
              inputValue={memberQuery}
              disabled={congregationIsEmpty}
            />
            {selectedMembers.length > 0 && (
              <Stack spacing={1.5}>
                <Typography variant="subtitle2">
                  {t("Selected Members")}
                </Typography>
                <Box sx={{ display: "flex" }}>
                  <List sx={{ maxWidth: "100%" }}>
                    {selectedMembers.map((member) => (
                      <ListItem
                        key={member.memberId}
                        secondaryAction={
                          member.deleted ? (
                            <IconButton
                              edge="end"
                              aria-label="restore"
                              onClick={onRestoreMember.bind(null, member)}
                            >
                              <AddIcon />
                            </IconButton>
                          ) : (
                            <IconButton
                              edge="end"
                              aria-label="delete"
                              onClick={onRemoveMember.bind(null, member)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          )
                        }
                      >
                        <ListItemAvatar>
                          <Avatar>{member.memberName[0].toUpperCase()}</Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={member.memberName}
                          sx={{ flexGrow: 0 }}
                          primaryTypographyProps={{
                            sx: {
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              textDecoration: member.deleted
                                ? "line-through"
                                : null,
                            },
                          }}
                        />
                      </ListItem>
                    ))}
                  </List>
                </Box>
              </Stack>
            )}

            <Stack
              direction="row"
              justifyContent="flex-end"
              spacing={1}
            ></Stack>
          </FormCard>

          <ErrorAlert error={errorProp} />
          <ErrorAlert error={errorMessage} />
          <ErrorAlert error={actionData?.error} />

          <Stack direction="row" justifyContent="flex-end" spacing={1}>
            {data?.leaderId && onDeleteProp && (
              <Button
                startIcon={<DeleteIcon />}
                variant="outlined"
                color="error"
                size="large"
                disabled={isDeleting}
                onClick={onDelete}
              >
                {isDeleting ? t("Deleting...") : t("Delete")}
              </Button>
            )}
            <Button
              startIcon={data?.leaderId != null ? <CheckIcon /> : <AddIcon />}
              variant="contained"
              color="success"
              size="large"
              disabled={isSubmitting}
              type="submit"
            >
              {isSubmitting ? t("Submitting...") : t("Submit")}
            </Button>
          </Stack>
        </Stack>
      </Container>
    </>
  );
}

function AddMemberSelect({
  congregationId,
  onChange,
  onInputChange,
  required,
  label,
  value = null,
  inputValue = "",
  disabled,
}) {
  const { t } = useTranslation();
  const { data = [] } = useCaringGroupAvailableMembers(congregationId);

  return (
    <Autocomplete
      required={required}
      value={value}
      inputValue={inputValue}
      options={data}
      autoHighlight={true}
      getOptionLabel={(option) => option.name}
      disabled={disabled}
      onChange={(_event, newValue) => {
        onChange(newValue);
      }}
      onInputChange={(_event, newInputValue) => {
        onInputChange(newInputValue);
      }}
      renderInput={(params) => (
        <TextField {...params} label={label ?? t("Add a member")} />
      )}
    />
  );
}
