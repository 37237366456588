import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { FormHelperText } from "@mui/material";
import { forwardRef, useId, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import DepartmentSelectMulti from "shared/components/DepartmentSelect/DepartmentSelectMulti.react";
import FormCard from "shared/components/FormCard/FormCard.react";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import isStringNullOrEmpty from "shared/utils/isStringNullOrEmpty";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import TextField from "@mui/material/TextField";

const MemberFormCongregationalInfoCard = forwardRef(
  function MemberFormCongregationalInfoCard(
    {
      churchDepartment,
      creationStatus,
      extraFieldsVisible = false,
      formErrors,
      memberType,
      onChurchDepartmentChange,
      onCreationStatusChange,
      onOriginatingChurchChange,
      onSpiritBaptismAtChange,
      onWaterBaptismAtChange,
      originatingChurch,
      spiritBaptismAt,
      waterBaptismAt,
    },
    ref,
  ) {
    const { t } = useTranslation();
    const membershipMethodLabelId = useId();
    const requireMoreData =
      !isStringNullOrEmpty(memberType) &&
      !["CONVIDADO", "CONGREGADO"].includes(memberType);
    const requireSpiritBaptism = [
      "LIDER",
      "DIACONO",
      "PRESBITERO",
      "EVANGELISTA",
      "PASTOR",
    ].includes(memberType);

    useImperativeHandle(
      ref,
      () => ({
        validate(formErrors) {
          if (requireMoreData && isStringNullOrEmpty(waterBaptismAt)) {
            formErrors.waterBaptismAt = t("Baptism Date is required");
          }

          if (requireSpiritBaptism && isStringNullOrEmpty(spiritBaptismAt)) {
            formErrors.spiritBaptismAt = t(
              "Holy Spirit Baptism Date is required for this role.",
            );
          }

          if (extraFieldsVisible && requireMoreData) {
            if (isStringNullOrEmpty(churchDepartment)) {
              formErrors.churchDepartment = t("Department is required");
              return;
            }

            if (isStringNullOrEmpty(creationStatus)) {
              formErrors.creationStatus = t("Membership Method is required");
            }
          }
        },
      }),
      [
        churchDepartment,
        creationStatus,
        extraFieldsVisible,
        requireMoreData,
        requireSpiritBaptism,
        spiritBaptismAt,
        t,
        waterBaptismAt,
      ],
    );

    return (
      <FormCard title={t("Congregational Information")}>
        <DatePicker
          disableFuture={true}
          required={requireMoreData}
          label={t("Baptism Date")}
          value={waterBaptismAt}
          onChange={onWaterBaptismAtChange}
          slotProps={{
            textField: {
              InputLabelProps: { required: requireMoreData },
              error: !!formErrors.waterBaptismAt,
              helperText: formErrors.waterBaptismAt,
            },
          }}
        />
        <DatePicker
          disableFuture={true}
          label={t("Holy Spirit Baptism Date")}
          value={spiritBaptismAt}
          onChange={onSpiritBaptismAtChange}
          required={requireSpiritBaptism}
          slotProps={{
            textField: {
              InputLabelProps: { required: requireSpiritBaptism },
              error: !!formErrors.spiritBaptismAt,
              helperText: formErrors.spiritBaptismAt,
            },
          }}
        />
        {extraFieldsVisible && (
          <>
            <TextField
              label={t("Origin Church")}
              value={originatingChurch}
              onChange={changeHandler(onOriginatingChurchChange)}
            />
            <DepartmentSelectMulti
              label={t(
                "Which department do you participate or would like to participate in?",
              )}
              value={churchDepartment}
              onChange={onChurchDepartmentChange}
              required={requireMoreData}
              error={Boolean(formErrors.churchDepartment)}
              helperText={formErrors.churchDepartment}
            />
            <FormControl error={!!formErrors.creationStatus} variant="standard">
              <FormLabel id={membershipMethodLabelId}>
                {t("Membership Method")}
              </FormLabel>
              <RadioGroup
                required={requireMoreData}
                aria-labelledby={membershipMethodLabelId}
                value={creationStatus}
                onChange={changeHandler(onCreationStatusChange)}
              >
                <FormControlLabel
                  value="RECEIVED_BY_BAPTISM"
                  control={<Radio />}
                  label={t("Batismo")}
                />
                <FormControlLabel
                  value="RECEIVED_WITHOUT_LETTER"
                  control={<Radio />}
                  label={t("Recebimento - Sem Carta")}
                />
                <FormControlLabel
                  value="TRANSFERRED_WITH_LETTER_SAME_MINISTRY"
                  control={<Radio />}
                  label={t(
                    "Transferência -  Com Carta de Mudança - Mesmo Ministério",
                  )}
                />
                <FormControlLabel
                  value="TRANSFERRED_WITH_LETTER"
                  control={<Radio />}
                  label={t(
                    "Transferência -  Com Carta de Mudança - Ministério Diferente",
                  )}
                />
              </RadioGroup>
              {formErrors?.creationStatus != null && (
                <FormHelperText>{formErrors.creationStatus}</FormHelperText>
              )}
            </FormControl>
          </>
        )}
      </FormCard>
    );
  },
);

function changeHandler(setValue) {
  return (e) => {
    setValue((e.currentTarget ?? e.target).value);
  };
}

export default MemberFormCongregationalInfoCard;
