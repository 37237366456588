import fetch from "data/fetch";
import { invalidateRegionalsQuery } from "data/queries/regional/queryRegionals";
import { useLoaderData } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Breadcrumbs from "shared/components/Breadcrumbs/Breadcrumbs.react";
import RegionalForm from "./components/RegionalForm.react";
import { mutateRegional } from "data/mutations/mutateRegional";

export async function loader({ params: { id } }) {
  const { data: regional } = await fetch(`/v1/regional/${id}`);
  return {
    regionalId: id,
    name: regional.name,
    pastorId: regional.pastor?.id ?? null,
    pastor: regional.pastor ?? null,
    sedeId: regional.sede?.id ?? null,
    sede: regional.sede ?? null,
  };
}

export default function RegionalEditPage() {
  const data = useLoaderData();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [error, setError] = useState(null);

  const onSubmit = async (formData) => {
    try {
      const success = await mutateRegional({
        ...formData,
        regionalId: data.regionalId,
      });
      if (!success) {
        throw new Error("Error submitting the form");
      }
    } catch (e) {
      setError(e.message ?? "Sorry, an unexpected error has occurred.");
      return;
    }

    invalidateRegionalsQuery();
    navigate("/regional/list");
  };

  const onDelete = async () => {
    if (!window.confirm(t("Do you confirm the deletion?"))) {
      return false;
    }

    try {
      await fetch(`/v1/regional/${data.regionalId}`, {
        method: "DELETE",
      });
    } catch (e) {
      setError(e.message ?? "Sorry, an unexpected error has occurred.");
      return;
    }

    invalidateRegionalsQuery();
    navigate("/regional/list");
  };

  return (
    <RegionalForm
      data={data}
      onSubmit={onSubmit}
      onDelete={onDelete}
      error={error}
      breadcrumbs={
        <Breadcrumbs
          heading={t("Edit Regional")}
          links={[
            {
              name: t("Regionals"),
              to: "/regional/list",
            },
            { name: t("Edit") },
          ]}
          sx={{ mb: { xs: 3, md: 5 } }}
        />
      }
    />
  );
}
