import FormCard from "shared/components/FormCard/FormCard.react";
import React, { forwardRef, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";

import { CountrySelector } from "shared/components/CountrySelector/CountrySelector.react";
import PhotoUploadField from "shared/components/PhotoUploadField/PhotoUploadField";
import isStringNullOrEmpty from "shared/utils/isStringNullOrEmpty";

const MemberFormPersonalInfoCard = forwardRef(
  function MemberFormPersonalInfoCard(
    {
      addressCity,
      addressCountry,
      addressPostCode,
      addressState,
      addressStreet,
      birthDate,
      formErrors,
      memberType,
      onAddressCityChange,
      onAddressCountryChange,
      onAddressPostCodeChange,
      onAddressStateChange,
      onAddressStreetChange,
      onBirthDateChange,
      onPhotoChange,
      photo,
    },
    ref,
  ) {
    const { t } = useTranslation();
    const requireMoreData =
      !isStringNullOrEmpty(memberType) &&
      !["CONVIDADO", "CONGREGADO"].includes(memberType);

    useImperativeHandle(
      ref,
      () => ({
        validate(formErrors) {
          if (requireMoreData) {
            if (isStringNullOrEmpty(birthDate)) {
              formErrors.birthDate = t("Date of Birth is required");
            }

            if (isStringNullOrEmpty(addressStreet)) {
              formErrors.addressStreet = t("Street is required");
            }

            if (isStringNullOrEmpty(addressPostCode)) {
              formErrors.addressPostCode = t("Post Code is required");
            }

            if (isStringNullOrEmpty(addressCity)) {
              formErrors.addressCity = t("City is required");
            }

            if (isStringNullOrEmpty(addressState)) {
              formErrors.addressState = t("State is required");
            }

            if (isStringNullOrEmpty(addressCountry)) {
              formErrors.addressCountry = t("Country is required");
            }
          }

          const hasAnyAddress =
            !isStringNullOrEmpty(addressStreet) ||
            !isStringNullOrEmpty(addressPostCode) ||
            !isStringNullOrEmpty(addressCity) ||
            !isStringNullOrEmpty(addressState) ||
            !isStringNullOrEmpty(addressCountry);

          // either inform nothing or inform everything, there's no in between
          if (hasAnyAddress) {
            const errString = t("Address is incomplete");

            if (isStringNullOrEmpty(addressStreet)) {
              formErrors.addressStreet = errString;
            }
            if (isStringNullOrEmpty(addressPostCode)) {
              formErrors.addressPostCode = errString;
            }
            if (isStringNullOrEmpty(addressCity)) {
              formErrors.addressCity = errString;
            }
            if (isStringNullOrEmpty(addressState)) {
              formErrors.addressState = errString;
            }
            if (isStringNullOrEmpty(addressCountry)) {
              formErrors.addressCountry = errString;
            }
          }
        },
      }),
      [
        addressCity,
        addressCountry,
        addressPostCode,
        addressState,
        addressStreet,
        birthDate,
        requireMoreData,
        t,
      ],
    );

    return (
      <FormCard title={t("Personal Information")}>
        <DatePicker
          required={requireMoreData}
          label={t("Date of Birth")}
          value={birthDate}
          onChange={onBirthDateChange}
          disableFuture={true}
          slotProps={{
            textField: {
              InputLabelProps: { required: requireMoreData },
              error: !!formErrors.birthDate,
              helperText: formErrors.birthDate,
            },
          }}
        />
        <TextField
          required={requireMoreData}
          label={t("Address")}
          value={addressStreet}
          onChange={changeHandler(onAddressStreetChange)}
          error={!!formErrors.addressStreet}
          helperText={formErrors.addressStreet}
        />
        <TextField
          required={requireMoreData}
          label={t("City")}
          value={addressCity}
          onChange={changeHandler(onAddressCityChange)}
          error={!!formErrors.addressCity}
          helperText={formErrors.addressCity}
        />
        <TextField
          required={requireMoreData}
          label={t("State")}
          value={addressState}
          onChange={changeHandler(onAddressStateChange)}
          error={!!formErrors.addressState}
          helperText={formErrors.addressState}
        />
        <TextField
          required={requireMoreData}
          label={t("Postal Code")}
          value={addressPostCode}
          onChange={changeHandler(onAddressPostCodeChange)}
          error={!!formErrors.addressPostCode}
          helperText={formErrors.addressPostCode}
        />
        <FormControl
          error={!!formErrors.addressCountry}
          fullWidth
          variant="standard"
        >
          <CountrySelector
            required={requireMoreData}
            value={addressCountry}
            onChange={onAddressCountryChange}
            error={!!formErrors.addressCountry}
            helperText={formErrors.addressCountry}
          />
        </FormControl>
        <PhotoUploadField
          initialValue={photo}
          label={t("Personal Photo")}
          onChange={onPhotoChange}
          helperText={t(
            "Padrão de identidade/passaporte, recente, de frente, rosto e ombros enquadrados, olhar diretamente para câmera.",
          )}
        />
      </FormCard>
    );
  },
);

function changeHandler(setValue) {
  return (e) => {
    setValue((e.currentTarget ?? e.target).value);
  };
}

export default MemberFormPersonalInfoCard;
