import React, { useCallback, useState } from "react";
import { useForm, Controller, useFieldArray, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useActionData } from "react-router-dom";
import {
  Container,
  Card,
  Stack,
  TextField,
  Typography,
  Box,
  IconButton,
  FormControlLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Switch,
  Button,
  Chip,
  Autocomplete,
  InputAdornment,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import CongregationSelect from "shared/components/CongregationSelect/CongregationSelect.react";
import ErrorAlert from "shared/components/ErrorState/ErrorAlert.react";
import EventManagementDescriptionEditor from "./EventManagementDescriptionEditor.react";
import { isEmailValid } from "utils/EmailValidator";
import moment from "moment";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { formatCents } from "utils/NumberUtils";

export default function EventManagementForm({
  data,
  error,
  onSubmit,
  breadcrumbs,
}) {
  const { t } = useTranslation();
  const actionData = useActionData();

  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm({
    defaultValues: {
      eventId: data?.eventId || null,
      congregacao: data?.congregacao || null,
      name: data?.name || "",
      ticketPrice: Number(data?.ticketPrice) || 0,
      stripeUrl: data?.stripeUrl || "",
      shortDescription: data?.shortDescription || "",
      description: data?.description || "",
      startAt: data?.startAt || null,
      endAt: data?.endAt || null,
      promoteStartAt: data?.promoteStartAt || null,
      promoteEndAt: data?.promoteEndAt || null,
      enabled: data?.enabled || false,
      registrationEnabled: data?.registrationEnabled || false,
      notificationEmails: data?.notificationEmails || [],
      fields: data?.fields || [],
    },
  });

  const dateFields = [
    {
      name: "startAt",
      label: t("Start At"),
      description: t("The start date and time of the event"),
      dependencies: ["endAt"],
      errorMessage: t("Event start date must be before end date"),
    },
    {
      name: "endAt",
      label: t("End At"),
      description: t("The end date and time of the event"),
      dependencies: [],
    },
    {
      name: "promoteStartAt",
      label: t("Promote Start At"),
      description: t("The start date and time for promoting the event"),
      dependencies: ["promoteEndAt", "startAt"],
      errorMessage: [
        t("Promote start date must be before promote end date"),
        t("Promote start date must be before event start date"),
      ],
    },
    {
      name: "promoteEndAt",
      label: t("Promote End At"),
      description: t("The end date and time for promoting the event"),
      dependencies: ["endAt"],
      errorMessage: t("Promote end date must be before event end date"),
    },
  ];

  const validateDate = (name, value, getValues) => {
    const selectedDate = moment(value);
    for (const field of dateFields) {
      if (field.name === name) {
        for (let i = 0; i < field.dependencies.length; i++) {
          const dependentField = field.dependencies[i];
          const dependentDate = moment(getValues(dependentField));
          if (selectedDate.isAfter(dependentDate)) {
            return Array.isArray(field.errorMessage)
              ? field.errorMessage[i]
              : field.errorMessage;
          }
        }
      }
    }
    return true;
  };

  const {
    fields: regFields,
    append,
    remove,
    move,
  } = useFieldArray({
    control,
    name: "fields",
  });

  const handleAddField = useCallback(() => {
    append({
      name: "",
      label: "",
      description: "",
      type: "text",
      required: false,
      visible: true,
      options: [],
      defaultValue: "",
    });
  }, [append]);

  const onFormSubmit = async (formData) => {
    try {
      await onSubmit(formData);
    } catch (err) {
      console.error(err);
    }
  };

  const DatePickerField = ({ name, label, description, control }) => (
    <Box width={{ xs: "100%", sm: "48%" }} mb={2}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Controller
          name={name}
          control={control}
          rules={{
            required: `${label} ${t("is required")}`,
            validate: (value) => validateDate(name, value, getValues),
          }}
          render={({ field, fieldState: { error } }) => {
            return (
              <DateTimePicker
                label={label}
                value={field.value ? moment(field.value) : null}
                onChange={(date) => field.onChange(date)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    margin="normal"
                    helperText={error ? error.message : description}
                    error={!!error}
                  />
                )}
              />
            );
          }}
        />
      </LocalizationProvider>
    </Box>
  );

  return (
    <Container component="form" onSubmit={handleSubmit(onFormSubmit)}>
      {breadcrumbs}
      <Stack spacing={3} sx={{ p: 3 }}>
        <Card sx={{ p: 3 }}>
          {/* Congregation Input */}
          <Controller
            name="congregacao"
            control={control}
            rules={{ required: t("Congregation is required") }}
            render={({ field, fieldState: { error } }) => (
              <CongregationSelect
                required
                initialValue={field.value}
                value={field.value}
                onChange={(newVal) => field.onChange(newVal)}
                error={!!error}
                helperText={error ? error.message : ""}
                {...field}
              />
            )}
          />

          {/* Name Input */}
          <Controller
            name="name"
            control={control}
            rules={{ required: t("Name is required") }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                required
                label={t("Name")}
                fullWidth
                {...field}
                margin="normal"
                helperText={error ? error.message : t("Enter the event name.")}
                error={!!error}
              />
            )}
          />

          {/* Ticket Price Input */}
          <Controller
            name="ticketPrice"
            control={control}
            rules={{
              validate: (value) =>
                value >= 0 || t("Amount must be a positive value."),
            }}
            render={({ field, fieldState: { error, invalid } }) => (
              <TextField
                {...field}
                slotProps={{
                  inputLabel: { required: false },
                  input: {
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  },
                }}
                label={t("Ticket Price")}
                type="number"
                fullWidth
                onChange={(e) => {
                  field.onChange(formatCents(e.target.value));
                }}
                margin="normal"
                helperText={
                  error ? error.message : t("Enter the ticket price.")
                }
                error={invalid}
              />
            )}
          />

          {/* Stripe URL */}
          <Controller
            name="stripeUrl"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                slotProps={{
                  inputLabel: { required: false },
                }}
                label={t("Stripe URL")}
                fullWidth
                margin="normal"
                helperText={
                  error ? error.message : t("Enter the Stripe payment link.")
                }
              />
            )}
          />

          {/* Short Description Input */}
          <Controller
            name="shortDescription"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                required
                label={t("Short Description")}
                fullWidth
                {...field}
                margin="normal"
                helperText={
                  error
                    ? error.message
                    : t(
                        "Enter a brief description that will appear in listings.",
                      )
                }
                multiline
                rows={5}
              />
            )}
          />

          {/* Detailed Description Input */}
          <Box marginY={2}>
            <Typography variant="subtitle1">{t("Description")}</Typography>
            <Typography variant="caption" color="textSecondary">
              {t("Provide a detailed description with formatting options.")}
            </Typography>
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <EventManagementDescriptionEditor
                  initialValue={field.value}
                  onChange={field.onChange}
                  {...field}
                />
              )}
            />
          </Box>

          {/* Date Inputs */}
          <Box display="flex" flexWrap="wrap" justifyContent="space-between">
            {dateFields.map(({ name, label, description }) => (
              <DatePickerField
                key={name}
                name={name}
                label={label}
                description={description}
                control={control}
              />
            ))}
          </Box>

          {/* Notification Emails Input */}
          <NotificationEmailsInput control={control} />

          {/* Enabled Switch */}
          <Controller
            name="enabled"
            control={control}
            render={({ field }) => (
              <>
                <FormControlLabel
                  control={<Switch {...field} checked={field.value} />}
                  label={t("Enabled")}
                />
                <Typography variant="caption" color="textSecondary">
                  {t("Toggle whether the event is active.")}
                </Typography>
              </>
            )}
          />

          {/* Registration Enabled Switch */}
          <Controller
            name="registrationEnabled"
            control={control}
            render={({ field }) => (
              <>
                <FormControlLabel
                  control={<Switch {...field} checked={field.value} />}
                  label={t("Registration Enabled")}
                />
                <Typography variant="caption" color="textSecondary">
                  {t("Toggle to allow users to register for this event.")}
                </Typography>
              </>
            )}
          />

          {/* Dynamic Registration Fields (only if registration is enabled) */}
          <Controller
            name="registrationEnabled"
            control={control}
            render={({ field: regEnabledField }) =>
              regEnabledField.value && (
                <Box mt={3}>
                  <Typography variant="h6">
                    {t("Registration Fields")}
                  </Typography>
                  <Typography variant="caption" color="textSecondary">
                    {t(
                      "Add custom fields that will be displayed on the event registration form.",
                    )}
                  </Typography>
                  {regFields.length > 0 ? (
                    regFields.map((item, index) => (
                      <RegistrationField
                        key={item.id}
                        index={index}
                        control={control}
                        t={t}
                        remove={remove}
                        move={move}
                        totalFields={regFields.length}
                      />
                    ))
                  ) : (
                    <Typography>
                      {t("No registration fields added.")}
                    </Typography>
                  )}
                  <Button
                    variant="outlined"
                    startIcon={<AddIcon />}
                    onClick={handleAddField}
                  >
                    {t("Add Field")}
                  </Button>
                </Box>
              )
            }
          />

          {/* Display Form-level Errors */}
          <ErrorAlert error={error} />

          {/* Display Field-level Errors */}
          {Object.keys(errors).length > 0 &&
            Object.keys(errors).map((key) => (
              <ErrorAlert key={key} error={errors[key].message} />
            ))}

          {/* <ErrorAlert error={errors} /> */}
          <ErrorAlert error={actionData?.error} />

          {/* Submit Button */}
          <Box display="flex" justifyContent="flex-end" mt={3}>
            <Button
              variant="contained"
              color="success"
              size="large"
              type="submit"
            >
              {t("Submit")}
            </Button>
          </Box>
        </Card>
      </Stack>
    </Container>
  );
}

function NotificationEmailsInput({ control }) {
  const [inputValue, setInputValue] = useState("");
  const { t } = useTranslation();

  return (
    <Box mt={3}>
      <Typography variant="subtitle1">{t("Notification Emails")}</Typography>
      <Typography variant="caption" color="textSecondary">
        {t("Enter email addresses to notify about changes to this event.")}
      </Typography>
      <Controller
        name="notificationEmails"
        control={control}
        render={({ field }) => (
          <Autocomplete
            multiple
            freeSolo
            options={[]} // No predefined options
            value={field.value}
            inputValue={inputValue} // Controlled input text
            onInputChange={(event, newInputValue) =>
              setInputValue(newInputValue)
            }
            onChange={(event, newValue) => field.onChange(newValue)}
            onKeyDown={(event) => {
              if (event.key === " " || event.key === "Enter") {
                event.preventDefault(); // Prevent default behavior
                const email = inputValue.trim();

                if (email !== "" && isEmailValid(email)) {
                  field.onChange([...field.value, email]); // Add valid email
                  setInputValue(""); // Clear input field after adding
                }
              }
            }}
            renderTags={(value, getTagProps) =>
              value.map((option, idx) => (
                <Chip
                  key={idx}
                  variant="outlined"
                  label={option}
                  {...getTagProps({ index: idx })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("Notification Emails")}
                placeholder={t("Add email")}
                margin="normal"
                error={inputValue && !isEmailValid(inputValue)}
                helperText={
                  inputValue && !isEmailValid(inputValue)
                    ? t("Invalid email format")
                    : ""
                }
              />
            )}
          />
        )}
      />
    </Box>
  );
}

function RegistrationField({ index, control, remove, move, totalFields }) {
  const { t } = useTranslation();
  const fieldType = useWatch({
    control,
    name: `fields[${index}].type`,
  });

  return (
    <Card variant="outlined" sx={{ p: 2, mb: 2 }}>
      <Stack spacing={2}>
        {/* Field Name */}
        <Controller
          name={`fields[${index}].name`}
          control={control}
          rules={{
            required: t("Field Name is required"),
            pattern: {
              value: /^[a-z0-9]+(-[a-z0-9]+)*$/,
              message: t("Field name must be in kebab-case"),
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              label={t("Field Name")}
              fullWidth
              {...field}
              margin="normal"
              helperText={
                error
                  ? error.message
                  : t("Enter a unique identifier for the field in kebab-case.")
              }
              error={!!error}
            />
          )}
        />
        {/* Label */}
        <Controller
          name={`fields[${index}].label`}
          control={control}
          rules={{ required: t("Label is required") }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              label={t("Label")}
              fullWidth
              {...field}
              margin="normal"
              helperText={
                error
                  ? error.message
                  : t("This is the label that users will see.")
              }
              error={!!error}
            />
          )}
        />
        {/* Description */}
        <Controller
          name={`fields[${index}].description`}
          control={control}
          render={({ field }) => (
            <TextField
              label={t("Description")}
              fullWidth
              {...field}
              margin="normal"
              helperText={t(
                "Optional: Provide additional information about the field.",
              )}
            />
          )}
        />
        {/* Type Selection */}
        <Controller
          name={`fields[${index}].type`}
          control={control}
          render={({ field }) => (
            <FormControl fullWidth margin="normal">
              <InputLabel>{t("Type")}</InputLabel>
              <Select label={t("Type")} {...field}>
                <MenuItem value="text">{t("Text")}</MenuItem>
                <MenuItem value="textarea">{t("Textarea")}</MenuItem>
                <MenuItem value="select">{t("Select")}</MenuItem>
                <MenuItem value="radio">{t("Radio")}</MenuItem>
                <MenuItem value="checkbox">{t("Checkbox")}</MenuItem>
                <MenuItem value="date">{t("Date")}</MenuItem>
                <MenuItem value="time">{t("Time")}</MenuItem>
                <MenuItem value="datetime">{t("Datetime")}</MenuItem>
                <MenuItem value="file">{t("File")}</MenuItem>
              </Select>
              <Typography variant="caption" color="textSecondary">
                {t("Choose the input type for this field.")}
              </Typography>
            </FormControl>
          )}
        />
        {/* Required & Visible Toggles */}
        <Stack direction="row" spacing={2}>
          <Controller
            name={`fields[${index}].required`}
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={<Switch {...field} checked={field.value} />}
                label={t("Required")}
              />
            )}
          />
          <Controller
            name={`fields[${index}].visible`}
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={<Switch {...field} checked={field.value} />}
                label={t("Visible")}
              />
            )}
          />
        </Stack>
        {/* Options Input (for select, radio, checkbox) */}
        {["select", "radio", "checkbox"].includes(fieldType) && (
          <Controller
            name={`fields[${index}].options`}
            control={control}
            render={({ field }) => (
              <Autocomplete
                multiple
                freeSolo
                options={[]} // Ensure options prop is defined
                value={field.value || []}
                onChange={(event, newValue) => field.onChange(newValue)}
                renderTags={(value, getTagProps) =>
                  value.map((option, idx) => (
                    <Chip
                      key={idx}
                      variant="outlined"
                      label={option}
                      {...getTagProps({ index: idx })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("Options")}
                    placeholder={t("Add an option")}
                    margin="normal"
                  />
                )}
              />
            )}
          />
        )}
        {/* Default Value */}
        <Controller
          name={`fields[${index}].defaultValue`}
          control={control}
          render={({ field }) => (
            <TextField
              label={t("Default Value")}
              fullWidth
              {...field}
              margin="normal"
              helperText={t(
                "Optional: Specify a default value for this field.",
              )}
            />
          )}
        />
        {/* Action Buttons */}
        <Box display="flex" justifyContent="flex-end" gap={1}>
          {/* Remove Field Button */}
          <IconButton color="error" onClick={() => remove(index)}>
            <DeleteIcon />
          </IconButton>

          {/* Move Up Button */}
          <IconButton
            onClick={() => move(index, index - 1)}
            disabled={index === 0}
          >
            <ArrowUpwardIcon />
          </IconButton>

          {/* Move Down Button */}
          <IconButton
            onClick={() => move(index, index + 1)}
            disabled={index === totalFields - 1}
          >
            <ArrowDownwardIcon />
          </IconButton>
        </Box>
      </Stack>
    </Card>
  );
}
