import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  Box,
  List,
  ListItemButton,
  ListItemText,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { SimpleTreeView, TreeItem } from "@mui/x-tree-view";
import {
  useSystemRolesQuery,
  useRolePermissionsQuery,
  useUpdateRolePermissionsMutation,
  invalidateRolePermissionsQuery,
} from "data/queries/querySystemRoles";
import { useSystemPermissionsQuery } from "data/queries/querySystemPermissions";

const PermissionsIndexPage = () => {
  const { data: rolesData, isLoading: isLoadingRoles } = useSystemRolesQuery();
  const { data: permissionsData, isLoading: isLoadingPermissions } =
    useSystemPermissionsQuery();
  const [selectedRole, setSelectedRole] = useState(null);
  const { data: rolePermissionsData, isLoading: isLoadingRolePermissions } =
    useRolePermissionsQuery(selectedRole);
  const { mutate: updatePermissions, isLoading: isSaving } =
    useUpdateRolePermissionsMutation({
      onSuccess: () => {
        invalidateRolePermissionsQuery(selectedRole);
      },
    });
  const [selectedItems, setSelectedItems] = useState([]);
  const [expandedItems, setExpandedItems] = useState([]);

  const groupedPermissions = useMemo(() => {
    return permissionsData?.data?.reduce((acc, perm) => {
      if (!acc[perm.resource]) {
        acc[perm.resource] = [];
      }
      acc[perm.resource].push(perm);
      return acc;
    }, {});
  }, [permissionsData]);

  const handleSelectedItemsChange = useCallback(
    (event, ids) => {
      setSelectedItems((oldSelected) => {
        let newValues = [...ids];
        if (groupedPermissions) {
          Object.entries(groupedPermissions).forEach(([resource, perms]) => {
            const values = perms.map((perm) => perm.value);

            if (oldSelected.includes(resource) && !ids.includes(resource)) {
              values.forEach((value) => {
                newValues = newValues.filter((id) => id !== value);
              });
            } else if (
              !oldSelected.includes(resource) &&
              ids.includes(resource)
            ) {
              values
                .filter((value) => !ids.includes(value))
                .forEach((value) => {
                  newValues.push(value);
                });
            } else if (
              values.some((value) => !oldSelected.includes(value)) &&
              values.every((value) => ids.includes(value)) &&
              !ids.includes(resource)
            ) {
              newValues.push(resource);
            } else if (
              values.every((value) => oldSelected.includes(value)) &&
              values.some((value) => !ids.includes(value)) &&
              ids.includes(resource)
            ) {
              newValues = newValues.filter((id) => id !== resource);
            }
          });
        }
        return newValues;
      });
    },
    [groupedPermissions],
  );

  const handleExpandedItemsChange = useCallback((event, itemIds) => {
    setExpandedItems(itemIds);
  }, []);

  useEffect(() => {
    if (!selectedRole && rolesData?.data?.length > 0) {
      setSelectedRole(rolesData.data[0].role);
    }
  }, [rolesData, selectedRole]);

  useEffect(() => {
    if (rolePermissionsData && permissionsData) {
      const initialSelectedItems = [];
      const grouped = permissionsData.data?.reduce((acc, perm) => {
        if (!acc[perm.resource]) {
          acc[perm.resource] = [];
        }
        acc[perm.resource].push(perm);
        return acc;
      }, {});

      rolePermissionsData.data?.forEach((perm) => {
        initialSelectedItems.push(perm.value);
      });

      Object.entries(grouped).forEach(([resource, perms]) => {
        if (
          perms.every((perm) =>
            rolePermissionsData.data?.some(
              (rolePerm) => rolePerm.value === perm.value,
            ),
          )
        ) {
          initialSelectedItems.push(resource);
        }
      });

      setSelectedItems(initialSelectedItems);
    }
  }, [rolePermissionsData, permissionsData]);

  const handleRoleSelect = (role) => {
    setSelectedRole(role);
    setSelectedItems([]);
  };

  const handleToggleSelectAll = () => {
    setSelectedItems((oldSelected) =>
      oldSelected.length === 0
        ? groupedPermissions &&
          Object.entries(groupedPermissions).flatMap(([resource, perms]) => [
            ...perms.map((perm) => perm.value),
            resource,
          ])
        : [],
    );
  };

  const handleToggleExpand = () => {
    setExpandedItems((oldExpanded) =>
      oldExpanded.length === 0
        ? groupedPermissions &&
          Object.entries(groupedPermissions).flatMap(([resource, perms]) => [
            ...perms.map((perm) => perm.value),
            resource,
          ])
        : [],
    );
  };

  const handleSave = () => {
    const permissions = selectedItems.filter((id) => id.includes(":"));
    updatePermissions({ roleName: selectedRole, permissions });
  };

  if (isLoadingRoles || isLoadingPermissions) {
    return <CircularProgress />;
  }

  return (
    <Box sx={{ flexGrow: 1, padding: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={4} sx={{ maxHeight: "70vh", overflow: "auto" }}>
          <Typography variant="h6">Permissions</Typography>
          <List component="nav">
            {rolesData.data?.map((role) => (
              <ListItemButton
                key={role.role}
                selected={role.role === selectedRole}
                onClick={() => handleRoleSelect(role.role)}
              >
                <ListItemText primary={role.role} />
              </ListItemButton>
            ))}
          </List>
        </Grid>
        <Grid item xs={8} sx={{ maxHeight: "70vh", overflow: "auto" }}>
          <Typography variant="h6">{selectedRole}</Typography>
          {isLoadingRolePermissions ? (
            <CircularProgress />
          ) : (
            rolePermissionsData && (
              <Box>
                <Button onClick={handleToggleSelectAll}>
                  {selectedItems.length === 0 ? "Select all" : "Unselect all"}
                </Button>
                <Button onClick={handleToggleExpand}>
                  {expandedItems.length === 0 ? "Expand all" : "Collapse all"}
                </Button>
                <SimpleTreeView
                  multiSelect
                  checkboxSelection
                  selectedItems={selectedItems}
                  onSelectedItemsChange={handleSelectedItemsChange}
                  expandedItems={expandedItems}
                  onExpandedItemsChange={handleExpandedItemsChange}
                >
                  {groupedPermissions &&
                    Object.entries(groupedPermissions).map(
                      ([resource, perms]) => (
                        <TreeItem
                          label={resource}
                          key={resource}
                          itemId={resource}
                        >
                          {perms.map((perm) => (
                            <TreeItem
                              label={perm.action}
                              key={perm.value}
                              itemId={perm.value}
                            />
                          ))}
                        </TreeItem>
                      ),
                    )}
                </SimpleTreeView>
              </Box>
            )
          )}
        </Grid>
      </Grid>
      <Box sx={{ display: "flex", justifyContent: "flex-end", marginTop: 2 }}>
        <Button
          variant="contained"
          color="primary"
          sx={{ marginRight: 1 }}
          onClick={handleSave}
          disabled={isSaving}
        >
          {isSaving ? <CircularProgress size={24} /> : "Save"}
        </Button>
        <Button variant="outlined">Cancel</Button>
      </Box>
    </Box>
  );
};

export default PermissionsIndexPage;
