import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import ListSubheader from "@mui/material/ListSubheader";
import Stack from "@mui/material/Stack";
import InputAdornment from "@mui/material/InputAdornment";
import NearMeIcon from "@mui/icons-material/NearMe";

import { useTranslation } from "react-i18next";
import {
  useCallback,
  useEffect,
  useId,
  useMemo,
  useState,
  useRef,
} from "react";
import { IconButton } from "@mui/material";
import { useCongregationsQuery } from "data/queries/queryCongregations";

function CongregationSelect({
  onChange,
  disabled,
  required,
  initialValue,
  label,
  error,
  helperText,
  sx,
}) {
  const { t } = useTranslation();
  const data = useData();
  const id = useComponentId();
  const regionalMap = useRegionalMap(data);
  const [value, setValue] = useState(initialValue);
  const [inputValue, setInputValue] = useState(initialValue?.name);

  const [isFindingNearest, onFindNearestClick] = useFindNearestCallback(
    data,
    setValue,
    setInputValue,
  );

  useLocalStorageCachedValue(data, value, setValue, setInputValue);

  return (
    <Autocomplete
      disabled={disabled}
      required={true}
      value={value}
      inputValue={inputValue}
      id={id}
      options={data}
      autoHighlight={true}
      sx={sx}
      groupBy={(option) => option.regional.id}
      getOptionLabel={(option) => option.name}
      onChange={(_event, newValue) => {
        setValue(newValue);
        onChange(newValue);
      }}
      onInputChange={(_event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderOption={(props, option) => (
        <Stack direction="row" alignItems="center" component="li" {...props}>
          <div style={{ flexGrow: 1 }}>{option.name}</div>
        </Stack>
      )}
      slotProps={{
        clearIndicator: {
          style: {
            visibility: "visible",
          },
        },
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          error={error}
          helperText={helperText}
          label={(label || t("Congregation")) + (required ? " *" : "")}
          InputProps={
            disabled || navigator.geolocation == null
              ? {
                  ...params.InputProps,
                  required,
                }
              : {
                  ...params.InputProps,
                  required,
                  endAdornment: (
                    <>
                      <InputAdornment position="end">
                        <IconButton
                          aria-label={t("Find nearest congregation")}
                          title={t("Find nearest congregation")}
                          size="small"
                          onClick={onFindNearestClick}
                          color={isFindingNearest ? "primary" : "inherit"}
                        >
                          <NearMeIcon fontSize="inherit" />
                        </IconButton>
                      </InputAdornment>
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }
          }
        />
      )}
      renderGroup={(params) => {
        const regional = regionalMap.get(params.group);
        const country = regional.country.toLowerCase();

        return (
          <li key={params.key}>
            <ListSubheader component="div" disableSticky={true}>
              <Stack direction="row" spacing={1} alignItems="center">
                <img
                  alt="Country flag"
                  loading="lazy"
                  width="20"
                  height="auto"
                  src={`https://flagcdn.com/w20/${country}.png`}
                  srcSet={`https://flagcdn.com/w40/${country}.png 2x`}
                />
                <span>{regional.name}</span>
              </Stack>
            </ListSubheader>
            <ul style={{ paddingLeft: 0 }}>{params.children}</ul>
          </li>
        );
      }}
    />
  );
}

function useComponentId() {
  const id = useId();
  return `congregation-select-${id}`;
}

function useData() {
  const collator = new Intl.Collator();
  const { data } = useCongregationsQuery();

  return (data ?? []).sort((a, b) => {
    const reg = collator.compare(a.regional.name, b.regional.name);
    return reg === 0 ? collator.compare(a.name, b.name) : reg;
  });
}

function useRegionalMap(data) {
  return useMemo(() => {
    const regionalMap = new Map();
    data.forEach((element) => {
      const regionalId = element.regional.id;
      if (!regionalMap.has(regionalId)) {
        regionalMap.set(regionalId, {
          id: regionalId,
          name: element.regional.name,
          country: element.country,
        });
      }
    });
    return regionalMap;
  }, [data]);
}

function useFindNearestCallback(data, setValue, setInputValue) {
  const [isFindingNearest, setIsFindingNearest] = useState(false);
  const onFindNearestClick = useCallback(() => {
    if (navigator.geolocation == null || isFindingNearest) {
      return;
    }

    setIsFindingNearest(true);

    navigator.geolocation.getCurrentPosition(
      (pos) => {
        const crd = pos.coords;

        const distances = data
          .map((church) => ({
            church,
            distance: geolocationDistance(
              church.latitude,
              church.longitude,
              crd.latitude,
              crd.longitude,
            ),
          }))
          .sort((a, b) => a.distance - b.distance);

        const nearest = distances[0];

        setValue(nearest.church);
        setInputValue(nearest.church.name);
        setIsFindingNearest(false);
      },
      () => {
        setIsFindingNearest(false);
      },
      {
        timeout: 5000,
        maximumAge: 24 * 60 * 1000, // 1 day
      },
    );
  }, [isFindingNearest, data, setValue, setInputValue]);

  return [isFindingNearest, onFindNearestClick];
}

function geolocationDistance(lat1, lon1, lat2, lon2) {
  const earthRadius = 6371; // Radius of the Earth in kilometers
  const toRadians = (degrees) => (degrees * Math.PI) / 180;

  const dLat = toRadians(lat2 - lat1);
  const dLon = toRadians(lon2 - lon1);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRadians(lat1)) *
      Math.cos(toRadians(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = earthRadius * c;

  return distance;
}

function useLocalStorageCachedValue(data, value, setValue, setInputValue) {
  const hasTriedToRestoreValue = useRef(false);

  useEffect(() => {
    const key = "congregation-select-value";

    // if we have tried to restore a value already, start storing
    if (hasTriedToRestoreValue.current) {
      if (value != null) {
        localStorage.setItem(key, value.id);
      } else {
        localStorage.removeItem(key);
      }
      return;

      // if we have not tried to restore it yet, and there's data, and the user hasn't selected anything, restore it.
    } else if (data != null && value == null) {
      const cachedValue = parseInt(localStorage.getItem(key) ?? 0, 10);
      const church =
        cachedValue === 0 ? null : data.find((c) => c.id === cachedValue);

      hasTriedToRestoreValue.current = true;

      if (church) {
        setValue(church);
        setInputValue(church.name);
      }
    }
  }, [data, setInputValue, setValue, value]);
}

export default CongregationSelect;
