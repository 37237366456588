import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import IndexPageHeader from "shared/layouts/index/IndexPageHeader.react";
import IndexPageLayout from "shared/layouts/index/IndexPageLayout.react";
import IndexPageTable from "shared/layouts/index/IndexPageTable.react";
import Link from "@mui/material/Link";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import {
  useCongregationsQuery,
  ensureCongregationsQueryData,
} from "data/queries/congregation/queryCongregations";

export async function loader() {
  return await ensureCongregationsQueryData();
}

export default function CongregationListPage() {
  const { t } = useTranslation();

  return (
    <IndexPageLayout
      useQuery={useCongregationsQuery}
      defaultSorting="+name"
      hasSearch={true}
      header={
        <IndexPageHeader
          heading={t("Congregations")}
          routeIndex="/congregation/list"
          routeNew="/congregation/new"
        />
      }
      table={
        <IndexPageTable
          headers={[
            { columnId: "name", label: t("Name") },
            { columnId: "country", label: t("Country") },
            { columnId: "regional.name", label: t("Regional") },
            { columnId: "pastor.name", label: t("Pastor") },
          ]}
          renderRow={({ id, name, country, regional, pastor }) => (
            <TableRow hover key={id} tabIndex={-1}>
              <TableCell>
                <Typography variant="subtitle2" noWrap>
                  <Link component={RouterLink} to={`/congregation/${id}`}>
                    {name}
                  </Link>
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle2" noWrap>
                  {country}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle2" noWrap>
                  {regional.name}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle2" noWrap>
                  {pastor?.name}
                </Typography>
              </TableCell>
              <TableCell key="actions"></TableCell>
            </TableRow>
          )}
        />
      }
    />
  );
}
