/**
 * Mantenha sincronizado com traduções em setupTranslation.js
 */
export const DONATION_TYPES = {
  DIZIMO: "DIZIMO",
  OFERTA: "OFERTA",
  MISSOES: "MISSOES",
  VOTO: "VOTO",
  OUTROS: "OUTROS",
};
