import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useActionData } from "react-router-dom";
import Alert from "@mui/material/Alert";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import FormCard from "shared/components/FormCard/FormCard.react";

export default function ResourceConfigForm({
  breadcrumbs,
  data = [],
  onSubmit: onSubmitProp,
  error: errorProp,
}) {
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formValues, setFormValues] = useState(
    data.reduce((acc, item) => {
      acc[item.key] = item.value || ""; // Initialize with the value or an empty string
      return acc;
    }, {}),
  );
  const actionData = useActionData();

  const handleInputChange = (key) => (e) => {
    setFormValues({
      ...formValues,
      [key]: e.target.value,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    setIsSubmitting(true);

    onSubmitProp(formValues).finally(() => {
      setIsSubmitting(false);
    });
  };

  return (
    <Container component="form" method="post" onSubmit={onSubmit}>
      {breadcrumbs}
      <Stack spacing={3} sx={{ p: 3 }}>
        <FormCard title={t("Configuração")}>
          {data.map((item) => (
            <TextField
              key={item.key}
              label={t(item.displayName || item.key)}
              value={formValues[item.key]}
              onChange={handleInputChange(item.key)}
              helperText={item.description && t(item.description)}
            />
          ))}
          {errorProp && <Alert severity="error">{t(errorProp)}</Alert>}
          {actionData && actionData.error && (
            <Alert severity="error">{t(actionData.error)}</Alert>
          )}
          <Stack direction="row" justifyContent="flex-end" spacing={1}>
            <Button
              startIcon={<AddIcon />}
              variant="contained"
              color="success"
              size="large"
              disabled={isSubmitting}
              type="submit"
            >
              {isSubmitting ? t("Submitting...") : t("Submit")}
            </Button>
          </Stack>
        </FormCard>
      </Stack>
    </Container>
  );
}
