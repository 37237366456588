import { ensureCongregationQueryData } from "data/queries/queryCongregations";
import { ensurePersonQuery, usePersonQuery } from "data/queries/queryPerson";
import { useRef } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Breadcrumbs from "shared/components/Breadcrumbs/Breadcrumbs.react";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import MemberCredentialCard, {
  validate,
} from "./components/MemberCredentialCard.react";

export async function loader({ params: { id } }) {
  await ensurePersonQuery(id);
  await ensureCongregationQueryData();
  return null;
}

export default function MemberCredentialsPage() {
  const { t } = useTranslation();
  const { id } = useParams();
  const { data: queryData } = usePersonQuery({ id });
  const member = queryData?.data;
  const issues = validate(member, t);
  const canvasRef = useRef(null);

  return (
    <>
      <Container>
        <Breadcrumbs
          heading={t("Members")}
          links={[
            {
              name: t("Members"),
              to: "/members",
            },
            { name: member?.name ?? "...", to: `/members/${id}` },
            {
              name: t("Credentials"),
            },
          ]}
          sx={{ mb: { xs: 3, md: 5 } }}
        />
        {member == null ? null : issues.length > 0 ? (
          <Alert severity="error">
            <AlertTitle>{t("This record is incomplete")}</AlertTitle>
            <Stack
              direction="column"
              usFlexGap={true}
              spacing={1}
              component="ul"
            >
              {issues.map((issue, i) => (
                <li key={i}>{issue}</li>
              ))}
            </Stack>
          </Alert>
        ) : (
          <>
            <Stack direction="row" useFlexGap={true} spacing={1} sx={{ mb: 2 }}>
              <Button
                variant="contained"
                onClick={canvasRef.current?.downloadAsPNG}
              >
                Download PNG
              </Button>
              <Button
                variant="contained"
                onClick={canvasRef.current?.downloadAsPDF}
              >
                Download PDF
              </Button>
            </Stack>
            <MemberCredentialCard member={member} ref={canvasRef} />
          </>
        )}
      </Container>
    </>
  );
}
