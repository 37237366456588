import fetch from "data/fetch";
import queryClient from "data/queryClient";
import { useQuery } from "@tanstack/react-query";

function fetchEBDClasse(id) {
  return fetch(`/v1/ebd-classe/${id}`);
}

const getQueryKey = (id) => ["ebd-classe", id];

export function useEBDClasseQuery({ id, ...other }) {
  return useQuery({
    ...other,
    queryKey: getQueryKey(id),
    queryFn: () => fetchEBDClasse(id),
  });
}

export function ensureEBDClasseQueryData(id) {
  return queryClient.ensureQueryData({
    queryKey: getQueryKey(id),
    queryFn: () => fetchEBDClasse(id),
  });
}

export function invalidateEBDClasseQuery() {
  return queryClient.invalidateQueries(["ebd-classe"]);
}
