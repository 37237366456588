import React, { useState } from "react";
import CodeMirror from "@uiw/react-codemirror";
import { html } from "@codemirror/lang-html";

const CodeMirrorEditor = ({ initialValue, onChange }) => {
  const [value, setValue] = useState(initialValue);

  return (
    <CodeMirror
      value={value}
      height="200px"
      extensions={[html()]}
      onChange={(value, viewUpdate) => {
        setValue(value);
        if (onChange) onChange(value);
      }}
    />
  );
};

export default CodeMirrorEditor;
