import { ensureCongregationQueryData } from "data/queries/queryCongregations";
import EBDClasseForm from "./components/EBDClasseForm.react";
import Breadcrumbs from "shared/components/Breadcrumbs/Breadcrumbs.react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { invalidateEBDClasseQuery } from "data/queries/queryEBDClasseAll";
import fetch from "data/fetch";

export async function loader() {
  await ensureCongregationQueryData();
  return null;
}

export async function action() {
  // TODO move form submit to action
}

export default function EBDNewPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onSubmit = async ({
    congregation,
    teacher,
    classe,
    selectedMembers,
  }) => {
    try {
      if (!congregation?.id) {
        throw new Error(t("Congregation is required"));
      }
      if (!teacher?.id) {
        throw new Error(t("Teacher is required"));
      }
      if (!classe) {
        throw new Error(t("Class is required"));
      }

      await fetch(`/v1/ebd-classe/`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          professorId: teacher?.id,
          congregacaoId: congregation?.id,
          classe,
          membros: selectedMembers.map((member) => member.id),
        }),
      });

      invalidateEBDClasseQuery();
      navigate("/ebd");
    } catch (error) {
      throw error;
    }
  };

  return (
    <EBDClasseForm
      onSubmit={onSubmit}
      breadcrumbs={
        <Breadcrumbs
          heading={t("Create a new Sunday School Class")}
          links={[
            {
              name: t("Sunday School"),
              to: "/ebd",
            },
            { name: t("New") },
          ]}
          sx={{ mb: { xs: 3, md: 5 } }}
        />
      }
    />
  );
}
