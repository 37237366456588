import { useRoles } from "shared/hooks/usePermissions";
import { useTranslation } from "react-i18next";
import FormCard from "shared/components/FormCard/FormCard.react";
import RoleSelect from "shared/components/RoleSelect/RoleSelect.react";

function MemberFormPermissionsCard({ roles, onRolesChange }) {
  const { t } = useTranslation();
  const rolesAccess = useRoles();

  // For now, we only allow these roles to change permissions.
  if (
    !rolesAccess("PASTOR_LOCAL") &&
    !rolesAccess("PASTOR_REGIONAL") &&
    !rolesAccess("MINISTERIAL") &&
    !rolesAccess("SYSTEM_ADMIN")
  ) {
    return null;
  }

  return (
    <FormCard title={t("Permissions")}>
      <RoleSelect value={roles} onChange={onRolesChange} />
    </FormCard>
  );
}

export default MemberFormPermissionsCard;
