import fetch from "data/fetch";

export async function createEventRegistrationPayment({
  registrationId,
  amount,
  note,
}) {
  const body = JSON.stringify({
    amount,
    note,
  });

  try {
    await fetch(`/v1/event-registration/${registrationId}/payment`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body,
    });
    return true;
  } catch {
    return false;
  }
}

export async function createEventRegistrationPaymentIntent({ eventId }) {
  try {
    const res = await fetch(`/v1/next-event/${eventId}/payment-intent`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });

    return { success: true, data: res.data };
  } catch {
    return { success: false, data: {} };
  }
}

export async function updateEventRegistrationPayment({
  paymentId,
  amount,
  note,
}) {
  const body = JSON.stringify({
    amount,
    note,
  });

  try {
    await fetch(`/v1/event-registration-payment/${paymentId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body,
    });
    return true;
  } catch {
    return false;
  }
}

export async function deleteEventRegistrationPayment(id) {
  try {
    await fetch(`/v1/event-registration-payment/${id}`, {
      method: "DELETE",
    });
    return true;
  } catch {
    return false;
  }
}
