import queryClient from "data/queryClient";
import { useQuery } from "@tanstack/react-query";
import fetch from "data/fetch";

function fetchCongregations(page = 0, filter = "", sort = "+name") {
  return fetch(
    `/v1/congregacao?size=100&index=${page}&q=${encodeURIComponent(filter)}&s=${sort}`,
  );
}

const getQueryKey = (index = 0, name = "", sort = "+name") => [
  "congregations",
  index,
  name,
  sort,
];

export function useCongregationsQuery({
  page = 0,
  filter = "",
  sort = "+name",
  ...other
}) {
  return useQuery({
    ...other,
    queryKey: getQueryKey(page, filter, sort),
    queryFn: () => fetchCongregations(page, filter, sort),
  });
}

export function ensureCongregationsQueryData({
  page = 0,
  filter = "",
  sort = "+name",
} = {}) {
  return queryClient.ensureQueryData({
    queryKey: getQueryKey(page, filter, sort),
    queryFn: () => fetchCongregations(page, filter, sort),
  });
}

export function invalidateCongregationsQuery() {
  return queryClient.invalidateQueries(["congregations"]);
}
