import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import {
  useEventsQuery,
  ensureEventsQueryData,
} from "data/queries/events/queryEvents";
import IndexPageHeader from "shared/layouts/index/IndexPageHeader.react";
import IndexPageLayout from "shared/layouts/index/IndexPageLayout.react";
import IndexPageTable from "shared/layouts/index/IndexPageTable.react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import CircleIcon from "@mui/icons-material/Circle";
import Link from "@mui/material/Link";
import RecentActorsIcon from "@mui/icons-material/RecentActors";
import { IconButton } from "@mui/material";

export async function loader() {
  return await ensureEventsQueryData();
}

export default function EventManagementIndexPage() {
  const { t } = useTranslation();

  return (
    <IndexPageLayout
      useQuery={useEventsQuery}
      hasSearch={true}
      defaultSorting="-startAt"
      header={
        <IndexPageHeader
          heading={t("Events")}
          routeIndex="/event-management"
          routeNew="/event-management/new"
        />
      }
      table={
        <IndexPageTable
          headers={[
            { columnId: "name", label: t("Name") },
            { columnId: "congregacao.name", label: t("Congregation") },
            { columnId: "startAt", label: t("Start At") },
            { columnId: "endAt", label: t("End At") },
            { columnId: "enabled", label: t("Enabled") },
            { label: t("Actions") },
          ]}
          renderRow={({ id, name, congregacao, startAt, endAt, enabled }) => (
            <TableRow hover key={id} tabIndex={-1}>
              <TableCell>
                <Typography variant="subtitle2" noWrap>
                  <Link component={RouterLink} to={`/event-management/${id}`}>
                    {name}
                  </Link>
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle2" noWrap>
                  {congregacao.name}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle2" noWrap>
                  {startAt}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle2" noWrap>
                  {endAt}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle2" noWrap>
                  <CircleIcon style={{ color: enabled ? "green" : "red" }} />
                </Typography>
              </TableCell>
              <TableCell key="actions">
                <Typography variant="subtitle2" noWrap>
                  <Link
                    component={RouterLink}
                    to={`/event-management/${id}/registrations`}
                  >
                    <IconButton>
                      <RecentActorsIcon style={{ color: "blue" }} />
                    </IconButton>
                  </Link>
                </Typography>
              </TableCell>
            </TableRow>
          )}
        />
      }
    />
  );
}
