import { useEffect, useState } from "react";
import { captureException } from "@sentry/react";
import useKnownErrorMessage from "./useKnownErrorMessage";

export default function useSentryCaptureException(error /* mixed */) {
  const [sentryId, setSentryId] = useState(null);
  const knownErrorMessage = useKnownErrorMessage(error);

  useEffect(() => {
    if (error == null || error === "" || knownErrorMessage != null) {
      return;
    }

    console.error(error);

    // only logs instances of Error to Sentry. We consider string error messages
    // as handled by the system, not something worth logging remotely.
    if (typeof error === "object") {
      const sentryId = captureException(error, { level: "fatal" });
      setSentryId(sentryId);
    }
  }, [error, knownErrorMessage]);

  return sentryId;
}
