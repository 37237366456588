import { useMemo } from "react";

const { useTranslation } = require("react-i18next");

export default function useKnownErrorMessage(error) {
  const { t } = useTranslation();

  return useMemo(() => {
    let title, description;

    switch (error?.status) {
      case 403:
        title = t("Access forbidden");
        description = t(
          "Please request permissions from your system administrator.",
        );
        break;

      case 404:
        title = [t("Page not found")];
        break;
      case 502:
      case 503:
      case 504:
        title = t("Servers are restarting");
        description = t(
          "We are publishing an update. Please wait a few minutes.",
        );
        break;
      default:
    }

    return [title, description];
  }, [t, error]);
}
