import Box from "@mui/material/Box";
import adbelem_logo from "./adbelem_logo.png";
import adbelem_logomark from "./adbelem_logomark.png";
import adbelem_logo_2x from "./adbelem_logo@2x.png";
import adbelem_logomark_2x from "./adbelem_logomark@2x.png";
import confradeb_logo from "./confradeb_logo.png";
import confradeb_logomark from "./confradeb_logomark.png";
import config from "config/config";

let logo = [adbelem_logo, adbelem_logo_2x];
let logomarkAsset = [adbelem_logomark, adbelem_logomark_2x];

if (config.env === "confradeb-prod") {
  logo = [confradeb_logo, confradeb_logo];
  logomarkAsset = [confradeb_logomark, confradeb_logomark];
}

function LogoWordmark({ logomark, ...props }) {
  return (
    <Box
      as="img"
      {...props}
      src={logomark ? logomarkAsset[0] : logo[0]}
      srcSet={(logomark ? logomarkAsset[1] : logo[1]) + " 2x"}
    />
  );
}

export default LogoWordmark;
