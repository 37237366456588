import { useState } from "react";
import { redirect } from "react-router-dom";
import { fetchNextEvent } from "data/queries/events/queryNextEvents";
import { useLoaderData } from "react-router-dom";
import Breadcrumbs from "shared/components/Breadcrumbs/Breadcrumbs.react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { mutateEventRegistration } from "data/mutations/mutateNextEventRegistration";
import { mutateFileUpload } from "data/mutations/mutateFileUpload";
import EventView from "./component/EventView.react";
import { ensureLoggedUserQueryData } from "data/queries/queryLoggedUser";
import { createEventRegistrationPaymentIntent } from "data/mutations/mutateEventRegistrationPayment";

export async function loader({ params: { id } }) {
  const user = await ensureLoggedUserQueryData();

  const hasToFinishRegistration = user?.requiredActions?.some(
    (x) => x === "FINISH_REGISTRATION",
  );

  if (hasToFinishRegistration) {
    return redirect(`/?returnTo=next-event/${id}`);
  }

  const { data: event } = await fetchNextEvent(id);

  return {
    event: {
      eventId: id,
      ...event,
    },
    user,
  };
}

export async function action() {
  // TODO move form submit to action
}

export default function NextEventViewPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const { event: data, user } = useLoaderData();

  const onSubmit = async ({ form, onSuccess }) => {
    try {
      if (user?.birthDate === null) {
        throw new Error(
          t("Birth date is required, access your profile to update it"),
        );
      }

      if (user?.address?.street === null) {
        throw new Error(
          t("Address is required, access your profile to update it"),
        );
      }

      const formDataArray = Object.keys(form || {})
        .filter(
          (key) =>
            form[key] !== null && form[key] !== undefined && form[key] !== "",
        )
        .map((key) => ({
          name: key,
          value: form[key],
        }));

      const fileUploadPromises = formDataArray.map(async (item) => {
        return await Promise.all(
          data?.fields?.map(async (field) => {
            if (
              field.name === item.name &&
              field.type === "file" &&
              item.value
            ) {
              const res = await mutateFileUpload(item.value);

              if (!res.id) {
                throw new Error(t("File upload failed"));
              }

              item.value = res.id;
            }
          }),
        );
      });

      await Promise.all(fileUploadPromises);

      const res = await mutateEventRegistration({
        eventId: data.id,
        values: formDataArray,
      });

      if (!res) {
        throw new Error("Error submitting the form");
      }

      if (onSuccess) {
        onSuccess();
      }

      navigate("/next-event");
    } catch (e) {
      setError(e);
      return;
    }
  };

  const onPay = async () => {
    try {
      const res = await createEventRegistrationPaymentIntent({
        eventId: data.id,
      });

      if (!res.success) {
        throw new Error("Error submitting the form");
      }

      window.location.href = res.data.paymentLink;
    } catch (e) {
      setError(e);
      return;
    }
  };

  return (
    <EventView
      data={data}
      user={user}
      onSubmit={onSubmit}
      onPay={onPay}
      error={error}
      breadcrumbs={
        <Breadcrumbs
          heading={t("View Event")}
          links={[
            {
              name: t("Events"),
              to: "/next-event",
            },
            { name: t("View") },
          ]}
          sx={{ mb: { xs: 3, md: 5 } }}
        />
      }
    />
  );
}
