import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import de from "./locales/de/translation.json";
import en from "./locales/en/translation.json";
import es from "./locales/es/translation.json";
import pt_BR from "./locales/pt_BR/translation.json";

export default function setupTranslation() {
  i18n.use(initReactI18next).init({
    lng: "pt_BR",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
    resources: {
      pt_BR: {
        translation: pt_BR,
      },
      es: {
        translation: es,
      },
      en: {
        translation: en,
      },
      de: {
        translation: de,
      },
    },
  });
}
