import fetch from "data/fetch";
import { useQuery } from "@tanstack/react-query";
import isStringNullOrEmpty from "shared/utils/isStringNullOrEmpty";

function fetchPeopleByCongregation(congregationId, list, detailed = false) {
  const endpoint = detailed
    ? `/v1/congregacao/${congregationId}/pessoa-detailed`
    : `/v1/congregacao/${congregationId}/pessoa`;

  const params = new URLSearchParams({
    size: 1000, // TODO paginate this API and all callsites
    index: 0,
  });

  if (!isStringNullOrEmpty(list)) {
    params.append("list", list);
  }

  return fetch(`${endpoint}?${params.toString()}`).then(
    (payload) => payload.data,
  );
}

const getQueryKey = (id, list, detailed) => [
  "congregation",
  id,
  list,
  detailed,
];

function useCongregationPeopleInternal(congregationId, list, detailed) {
  const query = useQuery({
    queryKey: getQueryKey(congregationId, list, detailed),
    queryFn: () => fetchPeopleByCongregation(congregationId, list, detailed),
    enabled: congregationId != null,
  });

  return {
    ...query,
    isLoading: query.isLoading && query.fetchStatus !== "idle",
  };
}

export function useCongregationLeadersQuery(congregationId, detailed = false) {
  return useCongregationPeopleInternal(congregationId, "LIDER_ABOVE", detailed);
}

export function useCongregationCongregadoAboveQuery(
  congregationId,
  detailed = false,
) {
  return useCongregationPeopleInternal(
    congregationId,
    "CONGREGADO_ABOVE",
    detailed,
  );
}

export function useCongregationPeopleQuery(congregationId, detailed = false) {
  return useCongregationPeopleInternal(congregationId, "", detailed);
}
