import { ensureCongregationQueryData } from "data/queries/queryCongregations";
import { invalidateCaringGroupsQuery } from "data/queries/queryCaringGroups";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Breadcrumbs from "shared/components/Breadcrumbs/Breadcrumbs.react";
import CaringGroupsForm from "./components/CaringGroupsForm.react";
import fetch from "data/fetch";

export async function loader() {
  // preload congregations
  await ensureCongregationQueryData();
  return null;
}

export async function action() {
  // TODO move form submit to action
}

export default function CaringGroupsNewPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [error, setError] = useState(null);

  // TODO move to mutations
  const onSubmit = async ({
    congregationId,
    leaderId,
    selectedMembers,
    department,
  }) => {
    setError(null);

    try {
      await fetch(`/v1/caring-groups`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          liderId: leaderId,
          congregacaoId: congregationId,
          departamento: department,
          membros: selectedMembers.map((m) => m.memberId),
        }),
      });

      invalidateCaringGroupsQuery();
      navigate("/caring_groups");
    } catch (e) {
      setError(e);
    }
  };

  return (
    <CaringGroupsForm
      onSubmit={onSubmit}
      error={error}
      breadcrumbs={
        <Breadcrumbs
          heading={t("Create a new caring group")}
          links={[
            {
              name: t("Caring Groups"),
              to: "/caring_groups",
            },
            { name: t("New") },
          ]}
          sx={{ mb: { xs: 3, md: 5 } }}
        />
      }
    />
  );
}
