import queryClient from "data/queryClient";
import { useQuery } from "@tanstack/react-query";
import fetch from "data/fetch";

function fetchFriends4Ever(page = 0, sort = "-data") {
  return fetch(`/v1/friends-4ver?size=100&index=${page}&s=${sort}`);
}

const getQueryKey = (index = 0, sort = "-data") => [
  "friends-4ever",
  index,
  sort,
];

export function useFriends4EverQuery({ page = 0, sort = "-data", ...other }) {
  return useQuery({
    ...other,
    queryKey: getQueryKey(page, sort),
    queryFn: () => fetchFriends4Ever(page, sort),
  });
}

export function ensureFriends4EverQueryData({ page = 0, sort = "-data" } = {}) {
  return queryClient.ensureQueryData({
    queryKey: getQueryKey(page, sort),
    queryFn: () => fetchFriends4Ever(page, sort),
  });
}

export function invalidateFriends4EverQuery() {
  return queryClient.invalidateQueries(["friends-4ever"]);
}
