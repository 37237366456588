import { useState } from "react";
import {
  fetchEventRegistration,
  invalidateEventRegistrationsQuery,
} from "data/queries/events/queryEventRegistrations";
import { useLoaderData } from "react-router-dom";
import Breadcrumbs from "shared/components/Breadcrumbs/Breadcrumbs.react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  mutateEventRegistration,
  deleteEventRegistration,
} from "data/mutations/mutateEventRegistration";
import EventRegistrationForm from "./component/EventRegistrationForm.react";
import { mutateFileUpload } from "data/mutations/mutateFileUpload";

export async function loader({ params: { id, registrationId } }) {
  const { data: registration } = await fetchEventRegistration(registrationId);

  return {
    eventId: id,
    registrationId,
    ...registration,
  };
}

export async function action() {
  // TODO move form submit to action
}

export default function EventManagementRegistrationEditPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const data = useLoaderData();

  const onSubmit = async (formData) => {
    try {
      const formDataArray = Object.keys(formData || {})
        .filter(
          (key) =>
            formData[key] !== null &&
            formData[key] !== undefined &&
            formData[key] !== "",
        )
        .map((key) => ({
          name: key,
          value: formData[key],
        }));

      const fileUploadPromises = formDataArray.map(async (item) => {
        return await Promise.all(
          data?.event?.fields?.map(async (field) => {
            if (
              field.name === item.name &&
              field.type === "file" &&
              item.value &&
              item.value instanceof File
            ) {
              const res = await mutateFileUpload(item.value);

              if (!res.id) {
                throw new Error(t("File upload failed"));
              }

              item.value = res.id;
            }
          }),
        );
      });

      await Promise.all(fileUploadPromises);

      const res = await mutateEventRegistration(
        data.registrationId,
        formDataArray,
      );

      if (!res) {
        throw new Error("Error submitting the form");
      }

      invalidateEventRegistrationsQuery();
      navigate(`/event-management/${data.eventId}/registrations`);
    } catch (e) {
      setError(e);
      return;
    }
  };

  const onDelete = async () => {
    try {
      const res = await deleteEventRegistration(data.registrationId);

      if (!res) {
        throw new Error("Error deleting the registration");
      }

      invalidateEventRegistrationsQuery();
      navigate(`/event-management/${data.eventId}/registrations`);
    } catch (e) {
      setError(e);
      return;
    }
  };

  return (
    <EventRegistrationForm
      data={data}
      onDelete={onDelete}
      onSubmit={onSubmit}
      error={error}
      showInvisibleInputs={true}
      breadcrumbs={
        <Breadcrumbs
          heading={t("Edit an Event Registration")}
          links={[
            {
              name: t("Registrations"),
              to: `/event-management/${data.eventId}/registrations`,
            },
            { name: t("Edit") },
          ]}
          sx={{ mb: { xs: 3, md: 5 } }}
        />
      }
    />
  );
}
