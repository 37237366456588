import { useTranslation } from "react-i18next";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Box from "@mui/material/Box";
import useKnownErrorMessage from "./useKnownErrorMessage";
import useSentryCaptureException from "./useSentryCaptureException";

/**
 * Unify way to display errors in an alert box. Useful for forms.
 *
 *  - [error=null]: no error, renders nothing
 *  - [error=string]: a crafted message, consider the error as handled
 *  - [error=Error]: an instance of Error class is considered unhandled error.
 *
 */
export default function ErrorAlert({ error }) {
  const { t } = useTranslation();
  const knownErrorMessage = useKnownErrorMessage(error);
  const sentryId = useSentryCaptureException(error);
  const isHandledErrorMessage = error !== "" && typeof error === "string";

  if (error == null || error === "") {
    return null;
  }

  // known error
  if (knownErrorMessage[0] != null) {
    return knownErrorMessage[1] != null ? (
      <Alert severity="error">
        <AlertTitle>{knownErrorMessage[0]}</AlertTitle>
        {knownErrorMessage[1]}
      </Alert>
    ) : (
      <Alert severity="error">{knownErrorMessage[0]}</Alert>
    );
  }

  // handled error, show the message
  if (isHandledErrorMessage) {
    return <Alert severity="error">{error}</Alert>;
  }

  // unhandled error, needs logging
  return (
    <Alert severity="error">
      <AlertTitle>{t("Sorry, an unexpected error has occurred.")}</AlertTitle>
      <Box component="pre" sx={styles.code}>
        <b>{t("Technical Information")}</b>
        {"\n"}
        Sentry ID: {sentryId}
        {error.apiMessage != null && (
          <>
            {"\n"}
            {error.apiMessage}
          </>
        )}
        {"\n"}
        {error.stack}
      </Box>
    </Alert>
  );
}

const styles = {
  code: {
    // bgcolor: "grey.100",
    // border: 1,
    // borderColor: "grey.200",
    fontSize: 11,
    // maxWidth: "100%",
    // padding: 1,
    whiteSpace: "pre-wrap",
  },
};
