import React, { useState } from "react";
import { createRoot } from "react-dom/client";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import BlotFormatter from "quill-blot-formatter";
import { useTranslation } from "react-i18next";
import { html as beautify_html } from "js-beautify";
import CodeMirrorEditor from "./CodeMirrorEditor.react";

class SourceEditor {
  constructor(quill, options) {
    this.quill = quill;
    this.options = options;
    this.isSourceMode = false;
    this.currentSource = "";

    this.button = document.createElement("button");
    this.button.setAttribute("type", "button");
    this.button.innerHTML = options.buttonHTML || "&lt;&gt;";
    this.button.title = options.buttonTitle || "Edit Source";
    this.button.style.marginLeft = "8px";
    this.button.addEventListener("click", (e) => {
      e.preventDefault();
      this.toggleSource();
    });

    const toolbar = quill.getModule("toolbar");
    if (toolbar && toolbar.container) {
      toolbar.container.appendChild(this.button);
    }

    this.reactContainer = document.createElement("div");
    this.reactContainer.style.display = "none";
    this.quill.container.parentNode.insertBefore(
      this.reactContainer,
      this.quill.container.nextSibling,
    );
  }

  toggleSource() {
    if (this.isSourceMode) {
      this.exitSourceMode();
    } else {
      this.enterSourceMode();
    }
  }

  enterSourceMode() {
    let htmlContent = this.quill.root.innerHTML;
    htmlContent = htmlContent.replace(/<p>\s*<\/p>/g, "");
    const prettyHTML = beautify_html(htmlContent, {
      indent_size: 2,
      preserve_newlines: false,
    });
    this.currentSource = prettyHTML;
    this.reactContainer.innerHTML = "";

    this.reactRoot = createRoot(this.reactContainer);
    this.reactRoot.render(
      <CodeMirrorEditor
        initialValue={prettyHTML}
        onChange={(value) => {
          this.currentSource = value;
        }}
      />,
    );

    this.quill.container.style.display = "none";
    this.reactContainer.style.display = "";
    this.isSourceMode = true;
  }

  exitSourceMode() {
    let html = beautify_html(this.currentSource.trim(), {
      indent_size: 2,
      preserve_newlines: false,
    });

    html = html.replace(/<p>\s*<\/p>/g, "");

    this.quill.root.innerHTML = html;

    if (this.reactRoot) {
      this.reactRoot.unmount();
    }

    this.reactContainer.style.display = "none";
    this.quill.container.style.display = "";
    this.isSourceMode = false;
  }
}

Quill.register("modules/blotFormatter", BlotFormatter);
Quill.register("modules/sourceEditor", SourceEditor);

const EventManagementDescriptionEditor = ({ initialValue = "", onChange }) => {
  const [content, setContent] = useState(initialValue);
  const { t } = useTranslation();

  const handleChange = (html) => {
    setContent(html);
    if (onChange) {
      onChange(html);
    }
  };

  return (
    <div>
      <ReactQuill
        value={content}
        onChange={handleChange}
        modules={EventManagementDescriptionEditor.modules}
        formats={EventManagementDescriptionEditor.formats}
        placeholder={t("Enter your event description here...")}
      />
    </div>
  );
};

EventManagementDescriptionEditor.modules = {
  toolbar: {
    container: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ size: ["small", false, "large", "huge"] }],
      [{ color: [] }, { background: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "video"],
      ["clean"],
      [{ align: [] }],
    ],
  },
  blotFormatter: {},
  sourceEditor: {},
};

EventManagementDescriptionEditor.formats = [
  "header",
  "size",
  "color",
  "background",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "indent",
  "link",
  "image",
  "video",
  "align",
];

export default EventManagementDescriptionEditor;
