import fetch from "data/fetch";

export async function mutateEventRegistration(id, values) {
  try {
    const body = JSON.stringify({
      values: values || [],
    });

    await fetch(`/v1/event-registration/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body,
    });
    return true;
  } catch {
    return false;
  }
}

export async function deleteEventRegistration(id) {
  try {
    await fetch(`/v1/event-registration/${id}`, {
      method: "DELETE",
    });
    return true;
  } catch {
    return false;
  }
}
