import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import ErrorPanel from "shared/components/ErrorState/ErrorPanel.react";

export default function ErrorTableRow({ error }) {
  return (
    <TableRow>
      <TableCell colSpan={1000}>
        <ErrorPanel error={error} />
      </TableCell>
    </TableRow>
  );
}
