import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import fetch from "data/fetch";

const fetchPastors = async () => {
  const response = await fetch("/v1/pessoa?list=LIDER_ABOVE");
  return response.data;
};

export default function PastorSelect({
  required,
  label: labelProp,
  value,
  onChange,
}) {
  const { t } = useTranslation();
  const [pastors, setPastors] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadPastors = async () => {
      try {
        const data = await fetchPastors();
        setPastors(data);
      } catch (error) {
        console.error("Error fetching pastors:", error);
      } finally {
        setLoading(false);
      }
    };

    loadPastors();
  }, []);

  useEffect(() => {}, [value]);

  const label = (labelProp ?? t("Pastor")) + (required ? " *" : "");

  return (
    <FormControl fullWidth>
      <InputLabel>{label}</InputLabel>
      <Select
        value={value}
        onChange={(e) => {
          onChange(e);
        }}
        label={label}
        required={required}
        disabled={loading}
      >
        {pastors.map((pastor) => (
          <MenuItem key={pastor.id} value={pastor.id}>
            {pastor.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
