import queryClient from "data/queryClient";
import { useQuery } from "@tanstack/react-query";
import fetch from "data/fetch";

function fetchCaringGroups(page = 0, filter = "", sort = "+congregacao.name") {
  return fetch(
    `/v1/caring-groups?size=100&index=${page}&q=${encodeURIComponent(filter)}&s=${sort}`,
  );
}
const getQueryKey = (index = 0, name = "", sort = "+congregacao.name") => [
  "caring-groups",
  index,
  name,
  sort,
];

export function useCaringGroupsQuery({
  page = 0,
  filter = "",
  sort = "+congregacao.name",
  ...other
}) {
  return useQuery({
    ...other,
    queryKey: getQueryKey(page, filter, sort),
    queryFn: () => fetchCaringGroups(page, filter, sort),
  });
}

export function ensureCaringGroupsQueryData({
  page = 0,
  filter = "",
  sort = "+congregacao.name",
} = {}) {
  return queryClient.ensureQueryData({
    queryKey: getQueryKey(page, filter, sort),
    queryFn: () => fetchCaringGroups(page, filter, sort),
  });
}

export function invalidateCaringGroupsQuery() {
  return queryClient.invalidateQueries(["caring-groups"]);
}
