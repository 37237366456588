import fetch from "data/fetch";

export async function mutateEventRegistration({
  registrationId,
  eventId,
  values,
}) {
  const url = `/v1/next-event/${eventId}/registration`;
  const method = registrationId ? "PUT" : "POST";

  const body = JSON.stringify({
    values: values || [],
  });

  try {
    await fetch(url, {
      method,
      headers: {
        "Content-Type": "application/json",
      },
      body,
    });
    return true;
  } catch {
    return false;
  }
}
