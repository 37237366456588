import fetch from "data/fetch";
import queryClient from "data/queryClient";
import { useQuery } from "@tanstack/react-query";

function fetchCaringGroupReports({ id, page = 0, from = "", to = "" }) {
  return fetch(
    `/v1/caring-groups/${id}/relatorios?size=100&index=${page}` +
      (from ? `&from=${from.format("YYYY-MM-DD")}` : "") +
      (to ? `&to=${to.format("YYYY-MM-DD")}` : ""),
  );
}

const getQueryKey = ({ id, page = 0, from = "", to = "" }) => [
  "caring-groups",
  id,
  "reports",
  page,
  from,
  to,
];

export function useCaringGroupReportsQuery({
  id,
  page = 0,
  from = "",
  to = "",
  ...other
}) {
  return useQuery({
    ...other,
    queryKey: getQueryKey({ id, page, from, to }),
    queryFn: () => fetchCaringGroupReports({ id, page, from, to }),
  });
}

export function ensureCaringGroupReportsQueryData({
  id,
  page = 0,
  from = "",
  to = "",
}) {
  return queryClient.ensureQueryData({
    queryKey: getQueryKey({ id, page, from, to }),
    queryFn: () => fetchCaringGroupReports({ id, page, from, to }),
  });
}

export function invalidateCaringGroupReportsQuery(id) {
  return queryClient.invalidateQueries(["caring-groups", id, "reports"]);
}
