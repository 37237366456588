import { Link as RouterLink } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useState } from "react";

import {
  ensureEBDClasseQueryData,
  useEBDClasseQuery,
} from "data/queries/queryEBDClasse";
import {
  ensureEBDClasseMembersQueryData,
  useEBDClasseMembersQuery,
} from "data/queries/queryEBDClasseMembers";
import {
  ensureEBDClasseReportsQueryData,
  useEBDClasseReportsQuery,
} from "data/queries/queryEBDClasseReports";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Container from "@mui/material/Container";
import DetailsPageHeader from "shared/layouts/details/DetailsPageHeader.react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import AddIcon from "@mui/icons-material/Add";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

export async function loader({ params: { id } }) {
  return await Promise.all([
    ensureEBDClasseQueryData(id),
    ensureEBDClasseMembersQueryData(id),
    ensureEBDClasseReportsQueryData({ id }),
  ]);
}

export default function EBDViewPage() {
  const { t } = useTranslation();
  const { id } = useParams();
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);

  const { data: ebdClasse } = useEBDClasseQuery({ id });
  const { data: members } = useEBDClasseMembersQuery({ id });
  const { data: reports } = useEBDClasseReportsQuery({ id, from, to });

  return (
    <Container maxWidth="lg">
      <DetailsPageHeader
        routeIndex={`/ebd`}
        routeUpdate={`/ebd/${id}`}
        heading={t("Sunday School Class")}
        links={[
          {
            name: t("Sunday School"),
            to: "/ebd",
          },
          { name: ebdClasse?.professor?.name ?? "..." },
        ]}
        sx={{ mb: { xs: 3, md: 5 } }}
      />

      <Stack spacing={3}>
        <ClassDetails ebdClasse={ebdClasse?.data} />
        <MembersList members={members?.data} />
        <ReportsList
          reports={reports}
          from={from}
          setFrom={setFrom}
          to={to}
          setTo={setTo}
          id={id}
        />
      </Stack>
    </Container>
  );
}

function ClassDetails({ ebdClasse }) {
  const { t } = useTranslation();

  return (
    <Card>
      <CardHeader title={t("Details")} />
      <CardContent>
        <Stack spacing={2}>
          <Typography>
            <strong>{t("Teacher")}:</strong> {ebdClasse?.professor?.name}
          </Typography>
          <Typography>
            <strong>{t("Congregation")}:</strong> {ebdClasse?.congregacao?.name}
          </Typography>
          <Typography>
            <strong>{t("Class")}:</strong> {t(ebdClasse?.classe)}
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  );
}

function MembersList({ members = [] }) {
  const { t } = useTranslation();

  return (
    <Card>
      <CardHeader title={t("Students")} />
      <CardContent>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t("Name")}</TableCell>
                <TableCell>{t("Email")}</TableCell>
                <TableCell>{t("Phone")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {members.map((member) => (
                <TableRow key={member.id}>
                  <TableCell>{member.name}</TableCell>
                  <TableCell>{member.email}</TableCell>
                  <TableCell>{member.phone}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
}

function ReportsList({ reports, from, setFrom, to, setTo, id }) {
  const { t } = useTranslation();

  return (
    <Card>
      <CardHeader
        title={t("Reports")}
        action={
          <Button
            component={RouterLink}
            to={`/ebd/reports/${id}/new`}
            startIcon={<AddIcon />}
          >
            {t("New Report")}
          </Button>
        }
      />
      <Toolbar sx={{ px: { sm: 2 } }}>
        <Stack direction="row" spacing={2} sx={{ width: "100%" }}>
          <DatePicker
            required={true}
            label={t("From")}
            value={from}
            onChange={setFrom}
          />
          <DatePicker
            required={true}
            label={t("To")}
            value={to}
            onChange={setTo}
          />
        </Stack>
      </Toolbar>
      <CardContent>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t("Date")}</TableCell>
                <TableCell>{t("Number of Guests")}</TableCell>
                <TableCell>{t("Class Offering")}</TableCell>
                <TableCell>{t("Student Name")}</TableCell>
                <TableCell>{t("Status")}</TableCell>
                <TableCell>{t("Comment")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {reports?.data?.map((report) => {
                const { data, numeroConvidados, ofertaGeral } = report;
                return report.relatorioRelations.map((relation, index) => (
                  <TableRow key={relation.id}>
                    {index === 0 && (
                      <>
                        <TableCell rowSpan={report.relatorioRelations.length}>
                          {data}
                        </TableCell>
                        <TableCell rowSpan={report.relatorioRelations.length}>
                          {numeroConvidados}
                        </TableCell>
                        <TableCell rowSpan={report.relatorioRelations.length}>
                          {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                          }).format(ofertaGeral || 0)}
                        </TableCell>
                      </>
                    )}
                    <TableCell>
                      {relation.ebdClasseRelation.pessoa.name}
                    </TableCell>
                    <TableCell>{t(relation.situacao)}</TableCell>
                    <TableCell>{relation.comentario}</TableCell>
                  </TableRow>
                ));
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
}
