import React, { useRef, useState, forwardRef } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  Button,
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

/*
<Controller
          key={field.name}
          name={field.name}
          control={control}
          defaultValue={field.defaultValue || ""}
          rules={{ required: {
            value: field.required,
            message: field.description,
          }, }}
          render={({ field: controllerField, fieldState: { error, invalid } }) => (
            <TextField
              {...controllerField}
              fullWidth
              margin="normal"
              label={field.label}
              helperText={error ? error.message : field.description}
              error={invalid}
              slotProps={{
                inputLabel: { required: field.required },
              }}
            />
          )}
        />
*/

export default function FileInput({
  field: { name, label, description, required },
  accept = "image/*,application/pdf",
  control,
}) {
  const { t } = useTranslation();
  return (
    <Controller
      key={name}
      name={name}
      control={control}
      defaultValue={null}
      rules={{
        required: {
          value: required,
          message: t(description),
        },
      }}
      render={({ field: controllerField, fieldState: { error, invalid } }) => (
        <FormControl error={invalid} fullWidth>
          {label && (
            <InputLabel disableAnimation shrink required={required}>
              {t(label)}
            </InputLabel>
          )}
          <OutlinedInput
            label={t(label)}
            fullWidth
            notched
            inputComponent={FileInputComponent}
            inputProps={{
              accept,
              initialValue: controllerField.value,
              onChangeFile: controllerField.onChange,
            }}
          />
          {(description || error?.message) && (
            <FormHelperText>{error?.message || t(description)}</FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
}

const FileInputComponent = forwardRef(function FileInput(props, ref) {
  const { accept, initialValue, onChangeFile } = props;
  const { t } = useTranslation();
  const [file, setFile] = useState(initialValue || null);
  const inputRef = useRef(null);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      if (onChangeFile) {
        onChangeFile(selectedFile);
      }
    }
  };

  const handleClearFile = () => {
    if (inputRef.current) {
      inputRef.current.value = "";
    }
    setFile(null);
    if (onChangeFile) {
      onChangeFile(null);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          minWidth: 0,
        }}
      >
        {!file && (
          <>
            <FileButton
              component="label"
              role={undefined}
              startIcon={<UploadFileIcon />}
            >
              {t("Select a file")}
              <input
                type="file"
                accept={accept}
                hidden
                ref={inputRef}
                onChange={handleFileChange}
              />
            </FileButton>
          </>
        )}
        {file && (
          <>
            <FileButton
              startIcon={<RemoveCircleOutlineIcon />}
              color="secondary"
              onClick={handleClearFile}
            >
              {t("Remove file")}
            </FileButton>
          </>
        )}
      </Box>

      {/* Show preview for images */}
      {file && file.type && file.type.startsWith("image/") && (
        <Box
          sx={{
            my: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            component="img"
            src={URL.createObjectURL(file)}
            sx={{ width: 160, height: 160, objectFit: "cover", mb: 1 }}
          />
          <Typography variant="body2">
            {file.name} ({(file.size / 1024).toFixed(2)} KB)
          </Typography>
        </Box>
      )}

      {/* Show PDF icon for PDFs */}
      {file && file.type && file.type === "application/pdf" && (
        <Box sx={{ my: 2, display: "flex", alignItems: "center" }}>
          <PictureAsPdfIcon sx={{ fontSize: 48, color: "red" }} />
          <Typography variant="body2" sx={{ ml: 1 }}>
            {file.name} ({(file.size / 1024).toFixed(2)} KB)
          </Typography>
        </Box>
      )}

      {file && !file.type && (
        <Box sx={{ my: 2, display: "flex", alignItems: "center" }}>
          <Typography variant="body2" sx={{ mr: 1 }}>
            {file.name}
          </Typography>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              const link = document.createElement("a");
              link.href = `/api/v1/file/${file}`;
              link.click();
            }}
          >
            {t("Download")}
          </Button>
        </Box>
      )}
    </Box>
  );
});

const FileButton = forwardRef(function FileButton(props, ref) {
  return (
    <Button
      ref={ref}
      role={undefined}
      variant="text"
      fullWidth
      disableElevation
      disableFocusRipple
      disableRipple
      sx={{ padding: "16.5px 14px", justifyContent: "flex-start" }}
      {...props}
    />
  );
});
