import React from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Button,
  Alert,
  LinearProgress,
  Stack,
  Container,
  Skeleton,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useTranslation } from "react-i18next";
import { useLoggedUserQuery } from "data/queries/queryLoggedUser";
import { Link as RouterLink } from "react-router-dom";
import config from "config/config";
import usePermissions from "shared/hooks/usePermissions";
import isStringNullOrEmpty from "shared/utils/isStringNullOrEmpty";

export default function HomePage() {
  const { t } = useTranslation();
  const { data: loggedUser, isLoading } = useLoggedUserQuery();
  const hasPermission = usePermissions();

  // Profile completion tracking
  const profileFields = [
    { name: t("Name"), isComplete: !isStringNullOrEmpty(loggedUser?.name) },
    { name: t("Email"), isComplete: !isStringNullOrEmpty(loggedUser?.email) },
    { name: t("Phone"), isComplete: !isStringNullOrEmpty(loggedUser?.phone) },
    {
      name: t("Address"),
      isComplete: !isStringNullOrEmpty(loggedUser?.address),
    },
    {
      name: t("Profile Picture"),
      isComplete: !isStringNullOrEmpty(loggedUser?.photoId),
    },
  ];
  const completedFields = profileFields.filter(
    (field) => field.isComplete,
  ).length;
  const totalXP = profileFields.length * 15;
  const earnedXP = completedFields * 15;
  const progress = (earnedXP / totalXP) * 100;

  // Define shortcut cards
  const shortcuts = [
    hasPermission("read", "familia-crista")
      ? {
          title: t("Família Cristã"),
          link: "/familia-crista/list",
          description: t("Access Família Cristã resources."),
          bgColor: "#eae7f3",
        }
      : null,
    hasPermission("read", "caring-groups")
      ? {
          title: t("Caring Groups"),
          description: t("Manage and join caring groups."),
          bgColor: "#d9e3eb",
          link: "/caring_groups",
          actions: hasPermission("create", "caring-groups-relatorio")
            ? [
                {
                  label: t("New Report"),
                  link: "caring_groups_reports/new",
                },
              ]
            : null,
        }
      : null,
    hasPermission("read", "familia-crista")
      ? {
          title: t("File of the Week"),
          link: "/familia-crista/files",
          description: t("Download the file of the week."),
          bgColor: "#e5ede6",
        }
      : null,
    {
      title: t("Profile"),
      link: "/profile",
      description: t("Complete your profile."),
      bgColor: "#f9f6eb",
    },
  ].filter(Boolean);

  if (config.env === "confradeb-prod") {
    return null;
  }

  // invalid state: not loading but also no user data
  if (!isLoading && loggedUser == null) {
    return null;
  }

  return (
    <Container>
      <Box sx={styles.welcomeBox}>
        <Typography variant="h4">
          {isLoading ? (
            <Skeleton width={200} />
          ) : (
            t("Hello {{user}}!", { user: loggedUser.name })
          )}
        </Typography>
        <Typography variant="subtitle1">
          {isLoading ? (
            <Skeleton width={300} />
          ) : (
            t("Here are your quick actions and reminders.")
          )}
        </Typography>
      </Box>

      {progress < 100 && (
        <Box sx={styles.reminderBox}>
          <Alert severity="info" sx={{ mb: 2 }}>
            {t("Don't forget to finish setting up your profile!")}
          </Alert>
          <Typography variant="body1" sx={{ mb: 1 }}>
            {t("Profile Completion:")} {earnedXP}/{totalXP} XP
          </Typography>
          <LinearProgress
            variant="determinate"
            value={progress}
            sx={styles.progressBar}
          />
          <Button
            component={RouterLink}
            to="/profile"
            variant="outlined"
            sx={{ mt: 1, textTransform: "none" }}
          >
            {t("Go to Profile")}
          </Button>
        </Box>
      )}

      <Grid container spacing={2} sx={{ mt: 2 }}>
        {shortcuts.map((shortcut, index) => (
          <Grid key={index} size={{ xs: 12, sm: 6, md: 3 }}>
            <Card sx={{ ...styles.card, backgroundColor: shortcut.bgColor }}>
              <CardContent sx={styles.cardContent}>
                <Typography variant="h6">{shortcut.title}</Typography>
                <Typography sx={{ flexGrow: 1, mb: 2 }} variant="body2">
                  {shortcut.description}
                </Typography>
                <Stack spacing={1}>
                  {shortcut.actions &&
                    shortcut.actions.map((action, id) => (
                      <Button
                        key={id}
                        variant="contained"
                        color="primary"
                        component={RouterLink}
                        to={action.link}
                        size="small"
                      >
                        {action.label}
                      </Button>
                    ))}
                  {shortcut.link && (
                    <Button
                      variant="contained"
                      color="primary"
                      component={RouterLink}
                      to={shortcut.link}
                      size="small"
                    >
                      {t("Go")}
                    </Button>
                  )}
                </Stack>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

const styles = {
  welcomeBox: {
    marginBottom: 4,
  },
  reminderBox: {
    marginBottom: 4,
  },
  progressBar: {
    height: 10,
    borderRadius: 5,
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    boxShadow: 3,
    borderRadius: 2,
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
};
